import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import { IWord, Permission } from "@/definitions";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { CalendarIcon, FolderIcon, PhoneIcon } from "@heroicons/vue/solid";
import { UiButton } from "@/components";
import { PencilAltIcon } from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordListItem',
  props: {
  modelValue: {
    type: Object as PropType<IWord>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

const __returned__ = { props, lastModifiedBy, get Permission() { return Permission }, get formatDateAsString() { return formatDateAsString }, get CalendarIcon() { return CalendarIcon }, get FolderIcon() { return FolderIcon }, get PhoneIcon() { return PhoneIcon }, get UiButton() { return UiButton }, get PencilAltIcon() { return PencilAltIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})