import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { IntegrationService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiTextInput,
} from "@/components";
import {
  FormAction,
  IDefaultServiceResult,
  IIntegrationServiceCreateResult,
  IntegrationType,
  IOpenAiIntegration,
  IOpenAiIntegrationFormState,
  Permission,
  UiActions,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'OpenAiIntegrationFormPage',
  props: {
  action: {
    type: String,
    default: FormAction.CREATE,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<IOpenAiIntegrationFormState>({
  id: 0,
  levelId: 0,
  type: IntegrationType.OpenAi,
  token: "",
  model: "",
  isActive: true,
});

const rules = computed<ValidationArgs<IOpenAiIntegrationFormState>>(() => ({
  id: {
    required,
  },
  levelId: {
    required,
  },
  type: {
    required,
  },
  token: {
    required,
    maxLength: maxLength(250),
  },
  model: {
    required,
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<IOpenAiIntegrationFormState>>> =
  useVuelidate(rules, formState);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getIntegration = (id: number) => {
  setLoading(true);
  IntegrationService.get<IOpenAiIntegration>(id)
    .then((response: AxiosResponse<IOpenAiIntegration>) => {
      const { id, token, model, isActive } = response.data;
      formState.id = id;
      formState.token = token;
      formState.model = model;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getIntegration(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ListIntegration",
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    IntegrationService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    IntegrationService.create<IIntegrationServiceCreateResult>(formState)
      .then(
        (response: AxiosResponse<IIntegrationServiceCreateResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

const __returned__ = { route, props, formState, rules, v$, setLoading, getIntegration, onSubmit, get goBack() { return goBack }, get UiButton() { return UiButton }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get UiTextInput() { return UiTextInput }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})