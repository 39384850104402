import { defineComponent as _defineComponent } from 'vue'
import {
  onMounted,
  ref,
  Ref,
  computed,
  WritableComputedRef,
  PropType,
  ComputedRef,
} from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IFlowNode,
  IMultiSelectOption,
  IUserExtensions,
} from "@/definitions";
import store from "@/store";
import { ReplyIcon } from "@heroicons/vue/outline";
import {
  UiNode,
  UiButton,
  UiMultiselect,
  UiRightPanel,
  UiCheckboxInput,
  UiTextArea,
} from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

interface IExtensionFormState {
  extension: null | number;
  userInput: null | boolean;
  whisper: null | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferToExtension',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const extensionsOptions: ComputedRef<IUserExtensions[]> = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.EXTENSION_OPTIONS],
);
const formState: Ref<IExtensionFormState> = ref({
  extension: null,
  userInput: null,
  whisper: null,
});
const textValidation = t("connectFlow.node.transferToExtension.validation");
const atLeastOneFilled = helpers.withMessage(textValidation, (value, state) => {
  return !!state.extension || !!state.userInput;
});
const rules = computed<ValidationArgs<IExtensionFormState>>(() => {
  const r: ValidationArgs<IExtensionFormState> = {
    extension: {
      atLeastOneFilled,
    },
    userInput: {
      atLeastOneFilled,
    },
    whisper: {},
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IExtensionFormState>>> = useVuelidate(
  rules,
  formState,
);

const nodeText = computed(() => {
  let text = t("connectFlow.node.transferToExtension.empty");
  if (nodeWritable.value.data.related) {
    text = t("connectFlow.node.transferToExtension.extension", {
      extension: nodeWritable.value.data.related?.value,
    });
  } else if (nodeWritable.value.data.userInput) {
    text = t("connectFlow.node.transferToExtension.userInput");
  }
  return text;
});

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    nodeWritable.value.data.related = formState.value.userInput
      ? null
      : ({
          value: formState.value.extension,
          label: formState.value.extension?.toString(),
        } as IMultiSelectOption);
    nodeWritable.value.data.userInput = formState.value.userInput ?? undefined;
    nodeWritable.value.data.whisper = formState.value.whisper ?? undefined;

    showForm.value = false;
    if (nodeWritable.value.data.related?.label !== undefined) {
      formState.value.extension = parseInt(
        nodeWritable.value.data.related?.label,
      );
    }
    formState.value.userInput = nodeWritable.value.data.userInput ?? null;
  }
  nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  v$.value.$reset();
}

onMounted(() => {
  if (nodeWritable.value.data.related?.label !== undefined) {
    formState.value.extension = parseInt(
      nodeWritable.value.data.related?.label,
    );
  }
  if (nodeWritable.value.data.userInput) {
    formState.value.userInput = nodeWritable.value.data.userInput;
  }

  if (nodeWritable.value.data.whisper !== undefined) {
    formState.value.whisper = nodeWritable.value.data.whisper;
  }

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

const __returned__ = { t, props, showForm, nodeWritable, extensionsOptions, formState, textValidation, atLeastOneFilled, rules, v$, nodeText, isFormCorrect, removeNode, onSubmit, get ReplyIcon() { return ReplyIcon }, get UiNode() { return UiNode }, get UiButton() { return UiButton }, get UiMultiselect() { return UiMultiselect }, get UiRightPanel() { return UiRightPanel }, get UiCheckboxInput() { return UiCheckboxInput }, get UiTextArea() { return UiTextArea } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})