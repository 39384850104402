import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import router from "@/router";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { UiButton } from "@/components";
import { CalendarIcon, FolderIcon } from "@heroicons/vue/solid";
import { PencilAltIcon } from "@heroicons/vue/outline";
import { INumber, Permission } from "@/definitions";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'SpamNumberListItem',
  props: {
  modelValue: {
    type: Object as PropType<INumber>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

const handleEditClick = () => {
  router.push({
    name: "EditSpamNumberById",
    params: {
      id: props.modelValue.id,
    },
  });
};

const __returned__ = { props, lastModifiedBy, handleEditClick, get formatDateAsString() { return formatDateAsString }, get UiButton() { return UiButton }, get CalendarIcon() { return CalendarIcon }, get FolderIcon() { return FolderIcon }, get PencilAltIcon() { return PencilAltIcon }, get Permission() { return Permission }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})