import { defineComponent as _defineComponent } from 'vue'
import { PropType } from "vue";
import { ConnectFlowModelingActions, IFlowNode } from "@/definitions";
import store from "@/store";
import { PhoneMissedCallIcon } from "@heroicons/vue/solid";
import { UiNode } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'HangUp',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: props.node.id,
    parentId: props.node.parentId,
  });
}

const __returned__ = { props, removeNode, get PhoneMissedCallIcon() { return PhoneMissedCallIcon }, get UiNode() { return UiNode } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})