import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, onMounted, WritableComputedRef } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { AxiosResponse } from "axios";
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import CallService from "@/services/CallService";
import { toastServiceError } from "@/utils/notification";
import {
  AuthGetters,
  CallPlayerGetters,
  CallPlayerMutations,
  ICall,
  ICallTimelineItem,
  IUserIntegration,
  IWordsByPersona,
  UiActions,
  UiGetters,
} from "@/definitions";
import CallDetails from "@/views/pages/call/components/CallDetails.vue";
import CallPlayer from "@/views/pages/call/components/CallPlayer.vue";
import CallTags from "@/views/pages/call/components/CallTags.vue";
import CallWordsInsight from "@/views/pages/call/components/CallWordsInsight.vue";
import CallSurveyAnswers from "@/views/pages/call/components/CallSurveyAnswers.vue";
import CallChatBot from "@/views/pages/call/components/CallChatBot.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};
const isLoading = computed(() => store.getters[UiGetters.IS_LOADING]);

const chatBotIntegration: ComputedRef<IUserIntegration> =
  computed<IUserIntegration>(
    () => store.getters[AuthGetters.CHAT_BOT_INTEGRATION],
  );

const call: WritableComputedRef<ICall> = computed({
  get: () => store.getters[CallPlayerGetters.CALL],
  set: (value: ICall) => {
    store.commit(CallPlayerMutations.SET_CALL, value);
  },
});

const getCall = (id: number) => {
  setLoading(true);
  CallService.get<ICall>(id)
    .then((response: AxiosResponse<ICall>) => {
      call.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const timeline: WritableComputedRef<ICallTimelineItem[]> = computed({
  get: () => store.getters[CallPlayerGetters.TIMELINE],
  set: (value: ICallTimelineItem[]) => {
    store.commit(CallPlayerMutations.SET_TIMELINE, value);
  },
});
const getTimeline = (id: number) => {
  CallService.getTimeline<ICallTimelineItem[]>(id).then(
    (response: AxiosResponse<ICallTimelineItem[]>) => {
      timeline.value = response.data;
    },
    toastServiceError,
  );
};

const words: WritableComputedRef<IWordsByPersona> = computed({
  get: () => store.getters[CallPlayerGetters.WORDS],
  set: (value: IWordsByPersona) => {
    store.commit(CallPlayerMutations.SET_WORDS, value);
  },
});
const getWords = (id: number) => {
  CallService.getWords<IWordsByPersona>(id).then(
    (response: AxiosResponse<IWordsByPersona>) => {
      words.value = response.data;
    },
    toastServiceError,
  );
};

onMounted(() => {
  getCall(parseInt(route.params.id as string));
  getTimeline(parseInt(route.params.id as string));
  getWords(parseInt(route.params.id as string));
});

const __returned__ = { route, setLoading, isLoading, chatBotIntegration, call, getCall, timeline, getTimeline, words, getWords, get ChevronLeftIcon() { return ChevronLeftIcon }, CallDetails, CallPlayer, CallTags, CallWordsInsight, CallSurveyAnswers, CallChatBot }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})