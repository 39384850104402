import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import ConnectFlowService from "@/services/ConnectFlowService";
import {
  UiPageHeader,
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiMultiselect,
  UiPanel,
} from "@/components";
import {
  IConnectFlow,
  Permission,
  IDefaultServiceResult,
  IConnectFlowServiceCreateResult,
  UiActions,
  IConnectFlowFormState,
  FormAction,
  ILevel,
  FilterGetters,
} from "@/definitions";
import LevelService from "@/services/LevelService";

import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<IConnectFlowFormState>({
  levelId: undefined,
  name: undefined,
  isActive: true,
});

const rules = computed<ValidationArgs<IConnectFlowFormState>>(() => {
  const r: ValidationArgs<IConnectFlowFormState> = {
    levelId: {
      required,
    },
    name: {
      required,
      maxLength: maxLength(500),
    },
    isActive: {
      required,
    },
  };

  return r;
});

const v$: Ref<Validation<ValidationArgs<IConnectFlowFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

if (props.action == FormAction.EDIT) {
  const id = parseInt(route.params.id as string);

  setLoading(true);
  ConnectFlowService.get<IConnectFlow>(id)
    .then((response: AxiosResponse<IConnectFlow>) => {
      const { levelId, name, isActive } = response.data;
      formState.id = id;
      formState.levelId = levelId;
      formState.name = name;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => setLoading(false));
}

const onSubmit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewConnectFlowById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    ConnectFlowService.update<IDefaultServiceResult, IConnectFlowFormState>(
      id,
      formState,
    )
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    ConnectFlowService.create<
      IConnectFlowServiceCreateResult,
      IConnectFlowFormState
    >(formState)
      .then(
        (response: AxiosResponse<IConnectFlowServiceCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
};

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 0,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

const __returned__ = { route, props, formState, rules, v$, setLoading, onSubmit, levelFilter, levels, getLevels, get UiPageHeader() { return UiPageHeader }, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiMultiselect() { return UiMultiselect }, get UiPanel() { return UiPanel }, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})