import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import { useI18n } from "vue-i18n";
import {
  SpeakerInsights,
  TranscriptSection,
} from "@/views/pages/meeting/components";
import { IMeeting, UiActions } from "@/definitions";
import MeetingService from "@/services/MeetingService";
import { toastServiceError } from "@/utils/notification";
import { UiGetters } from "@/definitions";
import {
  calculateSpeechPercentage,
  formatMessagesWithElapsedTime,
  formatDateTime,
} from "@/utils/meeting";
import { ChevronLeftIcon } from "@heroicons/vue/outline"; // Importa o ícone ChevronLeftIcon


export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();
const route = useRoute();
const meeting = ref<IMeeting | null>(null);
const isLoading = computed(() => store.getters[UiGetters.IS_LOADING]);
const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getMeeting = (id: number) => {
  setLoading(true);
  MeetingService.get<IMeeting>(id)
    .then((response) => {
      meeting.value = response.data;

      if (meeting.value?.participants) {
        meeting.value.participants = calculateSpeechPercentage(
          meeting.value.participants,
        );
      }

      if (meeting.value?.messages) {
        meeting.value.messages = formatMessagesWithElapsedTime(
          meeting.value.messages,
        );
      }
    })
    .catch(toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const formattedCreatedAt = computed(() =>
  formatDateTime(meeting.value?.createdAt),
);

onMounted(() => {
  const meetingId = parseInt(route.params.id as string);
  getMeeting(meetingId);
});

const __returned__ = { t, route, meeting, isLoading, setLoading, getMeeting, formattedCreatedAt, get SpeakerInsights() { return SpeakerInsights }, get TranscriptSection() { return TranscriptSection }, get ChevronLeftIcon() { return ChevronLeftIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})