import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { IntegrationService } from "@/services";
import { goBack } from "@/utils/navigation";
import { toastServiceError } from "@/utils/notification";
import {
  UiBadge,
  UiButton,
  UiListSearch,
  UiPageHeader,
  UiPagination,
  UiSortMenuBadge,
} from "@/components";

import {
  IntegrationLogListFilters,
  IntegrationLogListItem,
  IntegrationLogListSort,
} from "@/views/pages/integration/components";
import {
  FilterGetters,
  IFilterItem,
  IIntegrationLog,
  IIntegrationLogServiceGetAllParams,
  ILevel,
  IntegrationLogActions,
  IntegrationLogGetters,
  ISortMenuItem,
  UiActions,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { LinkIcon } from "@heroicons/vue/solid";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationLogPage',
  props: {
  type: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const props = __props;

const integrationId = parseInt(route.params.id as string);

const cols = [
  "callId",
  "callCreatedAt",
  "callType",
  "callStatus",
  "callSource",
  "callDestination",
  "createdAt",
  "status",
  "externalId",
  "observation",
];

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const integrationLogs: Ref<IIntegrationLog[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[IntegrationLogGetters.SEARCH],
  set: (search: string) =>
    store.dispatch(IntegrationLogActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[IntegrationLogGetters.SORT],
  set: (sort: ISortMenuItem[]) =>
    store.dispatch(IntegrationLogActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[IntegrationLogGetters.PAGE],
  set: (page: number) => store.dispatch(IntegrationLogActions.SET_PAGE, page),
});

const count = ref(0);
search.value = "";
sortMenuSelected.value = [];
currentPage.value = 1;
router.replace({ query: {} });

const filter = computed(() => store.getters[IntegrationLogGetters.FILTER]);

const getIntegrationLogs = () => {
  const params: IIntegrationLogServiceGetAllParams = {
    page: currentPage.value,
    status: filter.value.status?.value,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
  };

  setLoading(true);
  IntegrationService.getLogs<Array<IIntegrationLog>>(integrationId, {
    params,
  })
    .then((response: AxiosResponse<IIntegrationLog[]>) => {
      integrationLogs.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch(
  [level, search, filter, orderBy],
  () => {
    if (currentPage.value === 1) getIntegrationLogs();
    else currentPage.value = 1;
  },
  { deep: true },
);

watch([currentPage], () => {
  getIntegrationLogs();
});

getIntegrationLogs();

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[IntegrationLogGetters.ACTIVE_FILTERS],
);

function handleRemoveFilter(filter: string) {
  store.dispatch(IntegrationLogActions.CLEAR_FILTER, filter);
}

const __returned__ = { route, router, props, integrationId, cols, setLoading, integrationLogs, search, sortMenuSelected, level, orderBy, currentPage, count, perPage, filter, getIntegrationLogs, handleRemoveSortClick, activeFilters, handleRemoveFilter, get goBack() { return goBack }, get UiBadge() { return UiBadge }, get UiButton() { return UiButton }, get UiListSearch() { return UiListSearch }, get UiPageHeader() { return UiPageHeader }, get UiPagination() { return UiPagination }, get UiSortMenuBadge() { return UiSortMenuBadge }, get IntegrationLogListFilters() { return IntegrationLogListFilters }, get IntegrationLogListItem() { return IntegrationLogListItem }, get IntegrationLogListSort() { return IntegrationLogListSort }, get LinkIcon() { return LinkIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})