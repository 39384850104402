import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IMultiSelectOption,
  ICallReportFilter,
  CallReportGetters,
  CallReportActions,
  CallServiceTimeRange,
  CallStatus,
  CallType,
  IUser,
  ILevel,
  FilterGetters,
  INumber,
  IUserLabel,
} from "@/definitions";
import { UserLabelService, UserService, NumberService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallReportFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filterOpened = ref(false);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const defaultFilter = computed(
  () => store.getters[CallReportGetters.DEFAULT_FILTER],
);
const filter: Ref<ICallReportFilter> = ref({
  type: defaultFilter.value.type,
  status: defaultFilter.value.status,
  user: defaultFilter.value.user,
  number: defaultFilter.value.number,
  userLabel: defaultFilter.value.userLabel,
  serviceTimeRange: defaultFilter.value.serviceTimeRange,
});
const storeFilter = computed(() => store.getters[CallReportGetters.FILTER]);

const typeOptions: Ref<IMultiSelectOption[]> = ref([
  {
    value: CallType.INBOUND,
    label: t("core.options.call.type.inbound"),
  },
  {
    value: CallType.OUTBOUND,
    label: t("core.options.call.type.outbound"),
  },
  {
    value: CallType.INTERNAL,
    label: t("core.options.call.type.internal"),
  },
]);

const statusOptions: Ref<IMultiSelectOption[]> = ref([
  {
    value: CallStatus.ANSWERED,
    label: t("core.options.call.status.answered"),
  },
  {
    value: CallStatus.NO_ANSWER,
    label: t("core.options.call.status.no_answer"),
  },
  {
    value: CallStatus.BUSY,
    label: t("core.options.call.status.busy"),
  },
  {
    value: CallStatus.DITCHED,
    label: t("core.options.call.status.ditched"),
  },
]);

const userOptions: Ref<IUser[]> = ref([]);
const getUsers = () => {
  if (level.value)
    UserService.getAll<IUser[]>({
      params: {
        isAgent: true,
        page: 1,
        per_page: 100,
        level: level.value.id,
      },
    }).then((response) => (userOptions.value = response.data));
};

const userLabelOptions: Ref<IUserLabel[]> = ref([]);
const getUserLabels = () => {
  if (level.value)
    UserLabelService.getAll<IUserLabel[]>({
      params: {
        level: level.value.id,
      },
    }).then((response) => (userLabelOptions.value = response.data));
};

const numberOptions: Ref<INumber[]> = ref([]);
const getNumbers = () => {
  if (level.value)
    NumberService.getAll<INumber[]>({
      params: {
        level: level.value.id,
      },
    }).then((response) => (numberOptions.value = response.data));
};

const serviceTimeRangeOptions: Ref<IMultiSelectOption[]> = ref(
  [
    CallServiceTimeRange.BELOW_30S,
    CallServiceTimeRange.BETWEEN_30S_AND_60S,
    CallServiceTimeRange.BETWEEN_61_AND_180S,
    CallServiceTimeRange.BETWEEN_181S_AND_300S,
    CallServiceTimeRange.BETWEEN_301S_AND_480S,
    CallServiceTimeRange.BETWEEN_481S_AND_600S,
    CallServiceTimeRange.ABOVE_600,
  ].map((callServiceTimeRange) => {
    return {
      value: callServiceTimeRange,
      label: t("core.options.call.serviceTimeRange." + callServiceTimeRange),
    };
  }),
);

function applyFilters() {
  store.dispatch(CallReportActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(CallReportActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.type = storeFilter.value.type;
  filter.value.status = storeFilter.value.status;
  filter.value.user = storeFilter.value.user;
  filter.value.userLabel = storeFilter.value.userLabel;
  filter.value.number = storeFilter.value.number;
  filter.value.serviceTimeRange = storeFilter.value.serviceTimeRange;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

watch(level, () => {
  store.dispatch(CallReportActions.CLEAR_FILTER, "user");
  getUsers();
  store.dispatch(CallReportActions.CLEAR_FILTER, "number");
  getNumbers();
  store.dispatch(CallReportActions.CLEAR_FILTER, "userLabel");
  getUserLabels();
});

onMounted(() => {
  getUsers();
  getNumbers();
  getUserLabels();
});

const __returned__ = { t, filterOpened, level, defaultFilter, filter, storeFilter, typeOptions, statusOptions, userOptions, getUsers, userLabelOptions, getUserLabels, numberOptions, getNumbers, serviceTimeRangeOptions, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiRightPanel() { return UiRightPanel }, get UiMultiselect() { return UiMultiselect }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})