import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute, RouterLink } from "vue-router";
import { SurveyService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { ISurvey, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiPanel } from "@/components";
import { AxiosResponse } from "axios";
import { VaultService } from "@/services";
import { getAudioFormat } from "@/utils/audio";


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const survey: Ref<ISurvey | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getSurvey = (id: number) => {
  setLoading(true);
  SurveyService.get<ISurvey>(id)
    .then((response: AxiosResponse<ISurvey>) => {
      survey.value = response.data;
      getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const audioData = ref();
const getAudioFile = async () => {
  if (survey.value?.audioFilePath) {
    const { data } = await VaultService.getAudioAsset(
      survey.value?.audioFilePath,
    );
    const blob = new Blob([data], {
      type: getAudioFormat(survey.value?.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

getSurvey(parseInt(route.params.id as string));

const __returned__ = { route, survey, setLoading, getSurvey, audioData, getAudioFile, get RouterLink() { return RouterLink }, get goBack() { return goBack }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})