import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute, RouterLink } from "vue-router";
import { QueueService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { IQueue, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiGravatar, UiPanel } from "@/components";
import { MailIcon } from "@heroicons/vue/solid";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const queue: Ref<IQueue | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getQueue = (id: number) => {
  setLoading(true);
  QueueService.get<IQueue>(id)
    .then((response: AxiosResponse<IQueue>) => {
      queue.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getQueue(parseInt(route.params.id as string));

const __returned__ = { route, queue, setLoading, getQueue, get RouterLink() { return RouterLink }, get goBack() { return goBack }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiGravatar() { return UiGravatar }, get UiPanel() { return UiPanel }, get MailIcon() { return MailIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})