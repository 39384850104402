import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, onMounted, Ref } from "vue";
import store from "@/store";
import router from "@/router";
import { RouterView } from "vue-router";
import {
  TemplateHeader,
  TemplateSidebar,
  TemplateNavigation,
  TemplateProfile,
} from "@/views/templates/components";
import { UiButton, UiMultiselect, UiLoadingDialog } from "@/components";
import { CogIcon, BellIcon, LogoutIcon } from "@heroicons/vue/outline";
import {
  ILevel,
  FilterGetters,
  FilterActions,
  NavigationGetters,
  UiGetters,
  UiActions,
  AuthGetters,
} from "@/definitions";
import LevelService from "@/services/LevelService";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTemplate',
  setup(__props, { expose: __expose }) {
  __expose();

const logoPath = computed(() => {
  const company = store.getters[AuthGetters.USER_COMPANY];
  if (company.templateLogo) {
    return company.templateLogo;
  }
  return "/img/logo-mob.png";
});

const loading = computed(() => store.getters[UiGetters.IS_LOADING]);

const levels: Ref<ILevel[]> = ref([]);

const level = computed<ILevel>({
  get: () => store.getters[FilterGetters.LEVEL],
  set: (level: ILevel) => store.dispatch(FilterActions.SET_LEVEL, level),
});

const sideNavigationItems = computed(
  () => store.getters[NavigationGetters.SIDE_NAVIGATION_ITEMS],
);

onMounted(() => {
  LevelService.getAllGlobalSelect<ILevel[]>().then((response) => {
    levels.value = response.data;
  });
});

const handleConfigClick = () => {
  router.push("/config");
};

const handleLogoutClick = () => {
  router.push("/logout");
};

const handleLocaleChange = (event: Event) => {
  const selectedLocale = (event.target as HTMLInputElement).value;
  store.dispatch(UiActions.SET_LOCALE, selectedLocale);
};

const showLogout = computed(() => {
  return location.host !== "embed.mobcall.com";
});

const headerClass = computed(() => {
  if (process.env.NODE_ENV === "production") {
    return "bg-slate-100";
  }

  if (process.env.NODE_ENV === "development") {
    return "bg-teal-700";
  }

  return "bg-gray-700";
});

const __returned__ = { logoPath, loading, levels, level, sideNavigationItems, handleConfigClick, handleLogoutClick, handleLocaleChange, showLogout, headerClass, get RouterView() { return RouterView }, get TemplateHeader() { return TemplateHeader }, get TemplateSidebar() { return TemplateSidebar }, get TemplateNavigation() { return TemplateNavigation }, get TemplateProfile() { return TemplateProfile }, get UiButton() { return UiButton }, get UiMultiselect() { return UiMultiselect }, get UiLoadingDialog() { return UiLoadingDialog }, get CogIcon() { return CogIcon }, get BellIcon() { return BellIcon }, get LogoutIcon() { return LogoutIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})