import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { SpamNumberService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import router from "@/router";
import {
  UiButton,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiTextInput,
} from "@/components";
import {
  UiActions,
  FormAction,
  ISpamNumber,
  IDefaultServiceResult,
  ISpamNumberFormState,
  ISpamNumberServiceCreateResult,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { AxiosResponse } from "axios";
import { goBack } from "@/utils/navigation";
import Analytics from "@/utils/analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'SpamNumberFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<ISpamNumberFormState>({
  id: 0,
  number: null,
});

const rules = computed(() => ({
  id: {},
  number: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<ISpamNumberFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getSpamNumber = (id: number) => {
  setLoading(true);
  SpamNumberService.get<ISpamNumber>(id)
    .then((response: AxiosResponse<ISpamNumber>) => {
      formState.id = id;
      formState.number = response.data.number;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
if (props.action == FormAction.EDIT) {
  getSpamNumber(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const id = formState.id || 0;
  if (props.action == FormAction.EDIT) {
    SpamNumberService.update<IDefaultServiceResult>(id, formState)
      .then((response: AxiosResponse<IDefaultServiceResult>) => {
        toast.success(response.data.message);
        v$.value.$reset();
        router.push({
          name: "ListSpamNumber",
        });
      }, toastServiceError)
      .finally(() => setLoading(false));
  } else {
    SpamNumberService.create<ISpamNumberServiceCreateResult>(formState)
      .then((response: AxiosResponse<ISpamNumberServiceCreateResult>) => {
        v$.value.$reset();
        toast.success(response.data.message);
        Analytics.trackingAction(`NewSpanNumber`).then();
        goBack({
          name: "ListSpamNumber",
        });
      }, toastServiceError)
      .finally(() => {
        setLoading(false);
      });
  }
}

const __returned__ = { route, props, formState, rules, v$, setLoading, getSpamNumber, onSubmit, get UiButton() { return UiButton }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get UiTextInput() { return UiTextInput } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})