import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { toastServiceError } from "@/utils/notification";
import {
  ContactCenterPerDayReportActions,
  ContactCenterPerDayReportGetters,
  FilterActions,
  FilterGetters,
  IQueueTimeStats,
  IContactCenterPerDayReportFilter,
  IQueueTimeReportServiceGetInfoParams,
  IDataItem,
  IFilterItem,
  ILevel,
  UiActions,
  IQueueTimeReportServiceGetInfoResult,
} from "@/definitions";
import { ContactCenterPerDayReportFilters } from "@/views/pages/reports/contactCenterPerDay/components/";
import { UiPageHeader, UiDatePicker, UiBadge, BarChart } from "@/components";
import moment from "moment";
import { QueueTimeReportService } from "@/services";
import { formatSecToTime } from "@/utils/datetimeCommon";

interface ChartData {
  category: Array<string>;
  series: Array<IDataItem<number[]>>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueTimeReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const reportInfo: Ref<IQueueTimeReportServiceGetInfoResult | null> = ref(null);

const headerStats = computed<IDataItem<string>[]>(() => {
  const stats: IQueueTimeStats | undefined = reportInfo.value?.stats;

  if (stats == undefined) return [];

  const result: IDataItem<string>[] = [
    {
      name: "totalCalls",
      value: stats.totalCalls.toString(),
    },
    {
      name: "inboundCalls",
      value: stats.inboundCalls.toString(),
    },
    {
      name: "costumersContacted",
      value: stats.costumersContacted.toString(),
    },
    {
      name: "nonTalkPercentage",
      value: stats.nonTalkPercentage.toString() + "%",
    },
    {
      name: "tma",
      value: stats.tma,
    },
    {
      name: "averageTimeQueue",
      value: stats.averageTimeQueue,
    },
  ];

  return result;
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});
const filter = computed<IContactCenterPerDayReportFilter>(
  () => store.getters[ContactCenterPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[ContactCenterPerDayReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: IQueueTimeReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    type: filter.value.type?.value || null,
    status: filter.value.status?.value || null,
    user: filter.value.user?.id || null,
    number: filter.value.number?.number || null,
    serviceTimeRange: filter.value.serviceTimeRange?.value || null,
    userLabel: filter.value.userLabel?.id || null,
  };

  setLoading(true);

  QueueTimeReportService.getInfo({
    params,
  })
    .then((response) => {
      reportInfo.value = response.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

const chartDataAverageTimePerDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t(`report.queueTime.averageTimePerDay.averageTime`),
      color: "#BFDBFE",
    },
  ];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }

  for (let i = 0; i < reportInfo.value?.queuePerDay.length; i++) {
    const day = reportInfo.value?.queuePerDay[i];

    category.push(day.date);
    series[0].value.push(day.averageDuration);
  }

  return {
    category,
    series,
  };
});

const chartDataTimeRangeByDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }
  for (let i = 0; i < reportInfo.value?.queuePerDay.length; i++) {
    const day = reportInfo.value?.queuePerDay[i];

    category.push(day.date);
    for (let y = 0; y < day.ranges.length; y++) {
      if (series[y] === undefined) {
        series[y] = {
          value: [],
          name: "",
          color: "",
        };
      }

      series[y].value.push(day.timeRange[y]);
      series[y].name = t(`report.queueTime.range.${day.ranges[y]}`);
      series[y].color = day.rangeColor[y];
    }
  }

  return {
    category,
    series,
  };
});

const chartDataAverageTimePerWeekDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t(`report.queueTime.averageTimePerWeekDay.averageTime`),
      color: "#BFDBFE",
    },
  ];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }

  for (let i = 0; i < reportInfo.value?.queuePerWeekDay.length; i++) {
    const day = reportInfo.value?.queuePerWeekDay[i];

    category.push(t(`core.days.${day?.day}`));
    series[0].value.push(day?.averageDuration);
  }

  return {
    category,
    series,
  };
});

const chartDataTimeRangeByWeekDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }

  for (let i = 0; i < reportInfo.value?.queuePerWeekDay.length; i++) {
    const day = reportInfo.value?.queuePerWeekDay[i];
    category.push(t(`core.days.${day?.day}`));
    for (let y = 0; y < day.ranges.length; y++) {
      if (series[y] === undefined) {
        series[y] = {
          value: [],
          name: "",
          color: "",
        };
      }

      series[y].value.push(day.timeRange[y]);
      series[y].name = t(`report.queueTime.range.${day.ranges[y]}`);
      series[y].color = day.rangeColor[y];
    }
  }

  return {
    category,
    series,
  };
});

const chartDataAverageTimePerHourOfDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t(`report.queueTime.averageTimeByHourOfDay.averageTime`),
      color: "#BFDBFE",
    },
  ];

  if (reportInfo.value?.queuePerHour === undefined) {
    return {
      category,
      series,
    };
  }

  for (let h = 0; h < 24; h++) {
    category.push(h.toString().padStart(2, "0"));
    series[0].value.push(reportInfo.value?.queuePerHour[h]);
  }

  return {
    category,
    series,
  };
});

const chartDataTimeRangePerHourDay = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [];

  if (reportInfo.value?.queuePerDay === undefined) {
    return {
      category,
      series,
    };
  }

  for (let h = 0; h < 24; h++) {
    category.push(h.toString().padStart(2, "0"));
  }
  for (
    let y = 0;
    y < reportInfo.value?.queueRangePorHourOfDay.ranges.length;
    y++
  ) {
    if (series[y] === undefined) {
      series[y] = {
        value: [],
        name: t(
          `report.queueTime.range.${reportInfo.value?.queueRangePorHourOfDay.ranges[y]}`,
        ),
        color: reportInfo.value?.queueRangePorHourOfDay.rangeColor[y],
      };
    }
    series[y].value = reportInfo.value?.queueRangePorHourOfDay.data[y];
  }

  return {
    category,
    series,
  };
});
const axisLabelDateFormatter = (value: number | string) => {
  return moment.utc(value).format("DD/MM");
};
const axisLabelSecToTimeFormatter = (value: number) => {
  return formatSecToTime(value);
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(ContactCenterPerDayReportActions.CLEAR_FILTER, filter.field);
}

watch([level, dateRange, activeFilters], () => {
  getReportData();
});

getReportData();

const __returned__ = { t, reportInfo, headerStats, level, dateRange, filter, activeFilters, setLoading, getReportData, chartDataAverageTimePerDay, chartDataTimeRangeByDay, chartDataAverageTimePerWeekDay, chartDataTimeRangeByWeekDay, chartDataAverageTimePerHourOfDay, chartDataTimeRangePerHourDay, axisLabelDateFormatter, axisLabelSecToTimeFormatter, handleRemoveFilter, get ContactCenterPerDayReportFilters() { return ContactCenterPerDayReportFilters }, get UiPageHeader() { return UiPageHeader }, get UiDatePicker() { return UiDatePicker }, get UiBadge() { return UiBadge }, get BarChart() { return BarChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})