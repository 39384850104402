import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import store from "@/store";
import { UiGravatar } from "@/components";
import { AuthGetters, IUser } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateProfile',
  setup(__props, { expose: __expose }) {
  __expose();

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);

const __returned__ = { user, get UiGravatar() { return UiGravatar } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})