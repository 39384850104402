import { defineComponent as _defineComponent } from 'vue'
import {
  computed,
  ComputedRef,
  ref,
  Ref,
  watch,
  WritableComputedRef,
} from "vue";
import {
  UiButton,
  UiCheckboxInput,
  UiRightPanel,
  UiTextInput,
} from "@/components";
import {
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  EdgeType,
  IFlowConnection,
  IFlowNode,
  NodeType,
} from "@/definitions";
import store from "@/store";
import { helpers } from "@vuelidate/validators";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { useI18n } from "vue-i18n";

interface IEdgeValidationFormState {
  waitUser: boolean | null;
  option: number | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormClienteInfoEdge',
  props: {
  edgeId: {
    type: String,
  },
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();

const props = __props;

watch(
  () => props.modelValue,
  (show) => {
    internalShow.value = show;
    if (show) {
      if (edge.value?.data !== undefined) {
        formState.value.waitUser = edge.value.data?.waitUser;
        formState.value.option = edge.value.data?.uraOption;
      }
    }
  },
);

const emit = __emit;

const internalShow = ref(props.modelValue);
const handleToggle = (state: boolean) => {
  emit("update:modelValue", state);
};
watch(internalShow, () => {
  handleToggle(internalShow.value);
});

const edge: WritableComputedRef<IFlowConnection> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.edgeId,
    ),
  set: (value: IFlowConnection) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const formState: Ref<IEdgeValidationFormState> = ref({
  waitUser: null,
  option: null,
});
const validationFill = t("connectFlow.edge.validation.fill");

const checkFill = helpers.withMessage(validationFill, (value, state) => {
  return state.waitUser || !!state.option;
});

const connections: ComputedRef<IFlowConnection[]> = computed(
  () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENTS_CONNECTIONS],
);

const nodeTarget: ComputedRef<IFlowNode | undefined> = computed(() => {
  const nodes: IFlowNode[] =
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENTS_NODES];
  return nodes.find((n) => n.id === edge.value.target);
});

const validationUsed = t("connectFlow.edge.validation.used");
const isUsed = helpers.withMessage(validationUsed, (value, state) => {
  const siblings = connections.value.filter(
    (c) => c.source === edge.value.source,
  );
  let used = null;

  if (state.option >= 0) {
    used = siblings.find(
      (s) =>
        s.data?.component === EdgeType.CLIENT_INFO_EDGE &&
        s.data?.uraOption === state.option &&
        s.id !== edge.value.id,
    );
  } else if (state.waitUser != null) {
    used = siblings.find(
      (s) =>
        s.data?.component === EdgeType.CLIENT_INFO_EDGE &&
        s.data?.waitUser === state.waitUser &&
        s.id !== edge.value.id,
    );
  }
  return used === undefined;
});
const rules = computed<ValidationArgs<IEdgeValidationFormState>>(() => {
  const r: ValidationArgs<IEdgeValidationFormState> = {
    waitUser: {
      checkFill: checkFill,
      isUsed: isUsed,
    },
    option: {
      checkFill: checkFill,
      isUsed: isUsed,
    },
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IEdgeValidationFormState>>> =
  useVuelidate(rules, formState);

const isFormCorrect = ref(true);

async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();

  if (isFormCorrect.value && edge.value.data != undefined) {
    edge.value.data.uraOption = formState.value.waitUser
      ? null
      : formState.value.option;
    edge.value.data.waitUser = formState.value.waitUser ?? false;
    edge.value.data.hasErrors = false;

    if (formState.value.waitUser) {
      edge.value.label = t("connectFlow.edge.waitUser");
    } else {
      edge.value.label = t("connectFlow.edge.option", {
        number: formState.value.option,
      });
    }
    internalShow.value = false;
  }
  if (edge.value.data !== undefined) {
    edge.value.data.hasErrors = !isFormCorrect.value;
  }

  v$.value.$reset();
}

const __returned__ = { t, props, emit, internalShow, handleToggle, edge, formState, validationFill, checkFill, connections, nodeTarget, validationUsed, isUsed, rules, v$, isFormCorrect, onSubmit, get UiButton() { return UiButton }, get UiCheckboxInput() { return UiCheckboxInput }, get UiRightPanel() { return UiRightPanel }, get UiTextInput() { return UiTextInput }, get NodeType() { return NodeType } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})