import { createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pt-4 px-4" }
const _hoisted_2 = { class: "flex h-9 bg-gray-100 rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_3 = { class: "relative py-8 px-4" }
const _hoisted_4 = { class: "flex flex-row" }
const _hoisted_5 = { class: "flex flex-col grow-0 w-auto items-center pr-3" }
const _hoisted_6 = { class: "grow flex items-end" }
const _hoisted_7 = { class: "grow" }
const _hoisted_8 = { class: "w-full text-center" }
const _hoisted_9 = { class: "flex-1 relative" }
const _hoisted_10 = { class: "top-0 left-0.5 absolute w-full flex items-center z-20 h-5" }
const _hoisted_11 = { class: "top-3 left-0.5 absolute w-full flex items-center z-20 h-5" }
const _hoisted_12 = { class: "top-7 left-0.5 absolute w-full flex items-center z-20 h-5" }
const _hoisted_13 = { class: "bottom-0 left-0.5 absolute w-full flex items-center h-5 z-20" }
const _hoisted_14 = { class: "bottom-3 left-0.5 absolute w-full flex items-center h-5 z-20" }
const _hoisted_15 = { class: "bottom-6 left-0.5 absolute w-full flex items-center h-5 z-20" }
const _hoisted_16 = {
  class: "cursor-pointer group",
  ref: "audioElement"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  id: "progress-bar",
  class: "top-0 left-0 absolute h-full w-full flex items-center"
}
const _hoisted_19 = {
  id: "progress-time",
  class: "absolute text-gray-500 p-0.5 -bottom-9 right-0 text-sm cursor-default"
}
const _hoisted_20 = { class: "flex flex-shrink flex-col sm:w-auto" }
const _hoisted_21 = { class: "flex grow items-center" }
const _hoisted_22 = { class: "transform rotate-90 text-gray-500 w-full text-center" }
const _hoisted_23 = { class: "flex grow items-center" }
const _hoisted_24 = { class: "transform rotate-90 text-gray-500 w-full text-center" }
const _hoisted_25 = { class: "text-lg text-gray-700 text-center" }
const _hoisted_26 = { class: "text-lg text-center text-red-900" }
const _hoisted_27 = {
  key: 0,
  class: "flex w-full gap-4 mt-4 pl-14"
}
const _hoisted_28 = { class: "flex items-center text-xs text-blue-500" }
const _hoisted_29 = { class: "flex items-center text-xs text-yellow-500" }
const _hoisted_30 = { class: "flex items-center text-xs text-orange-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiPanel"], {
    class: "overflow-visible",
    "no-padding": ""
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["PlaySearchWord"], {
            modelValue: $setup.searchWord,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchWord) = $event)),
            placeholder: "Pesquisar palavra"
          }, null, 8 /* PROPS */, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createCommentVNode(" Commands buttons "),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode($setup["UiButton"], {
                class: "shadow w-10 h-10 mb-2",
                size: "",
                variant: "primary",
                "text-variant": "white",
                shape: "rounded-full",
                themed: "",
                onClick: $setup.playPause
              }, {
                default: _withCtx(() => [
                  (!$setup.isPlaying)
                    ? (_openBlock(), _createBlock($setup["PlayIcon"], {
                        key: 0,
                        class: "mx-auto text-white font-semibold text-lg",
                        "aria-hidden": "true"
                      }))
                    : _createCommentVNode("v-if", true),
                  ($setup.isPlaying)
                    ? (_openBlock(), _createBlock($setup["PauseIcon"], {
                        key: 1,
                        class: "mx-auto text-white font-semibold text-lg",
                        "aria-hidden": "true"
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["UiButton"], {
                class: "shadow w-7 h-7 text-center",
                size: "xs",
                variant: "primary",
                "text-variant": "white",
                shape: "rounded-full",
                themed: "",
                onClick: $setup.changeSpeed
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_8, _toDisplayString($setup.getPlaybackRate) + "x", 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode($setup["CallPlayerBullets"], {
                timeline: $setup.agentWordsBullets,
                type: "words"
              }, null, 8 /* PROPS */, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode($setup["CallPlayerBullets"], {
                timeline: $setup.agentInsightsBullets,
                type: "insights"
              }, null, 8 /* PROPS */, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode($setup["CallPlayerBullets"], {
                timeline: $setup.agentSearchWordsBullets,
                type: "search"
              }, null, 8 /* PROPS */, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode($setup["CallPlayerBullets"], {
                timeline: $setup.clientWordsBullets,
                type: "words"
              }, null, 8 /* PROPS */, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode($setup["CallPlayerBullets"], {
                timeline: $setup.clientInsightsBullets,
                type: "insights"
              }, null, 8 /* PROPS */, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode($setup["CallPlayerBullets"], {
                timeline: $setup.clientSearchWordsBullets,
                type: "search"
              }, null, 8 /* PROPS */, ["timeline"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              ($setup.playerReady)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", {
                        id: "full-line",
                        class: "w-full bg-gray-300 block h-1"
                      }, null, -1 /* HOISTED */)),
                      _createElementVNode("div", {
                        class: "theme-bg-color absolute h-0.5",
                        style: _normalizeStyle({ width: $setup.playedPercentage })
                      }, _cache[1] || (_cache[1] = [
                        _createElementVNode("span", { class: "absolute player-progress-bar-pointer bg-blue-300 rounded-full h-3 w-3 block -top-1 -right-1.5 z-20 transition duration-150 opacity-0 group-hover:opacity-100" }, null, -1 /* HOISTED */)
                      ]), 4 /* STYLE */),
                      _createElementVNode("div", _hoisted_19, _toDisplayString($setup.formatSecToTime($setup.secondsCurrent)) + " / " + _toDisplayString($setup.formatSecToTime($setup.secondsDuration)), 1 /* TEXT */)
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ], 512 /* NEED_PATCH */)
          ]),
          _createCommentVNode(" Labels personas "),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("call.view.participant.agent")), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("call.view.participant.client")), 1 /* TEXT */)
            ])
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute w-1/3 top-1/4 left-1/3", 
            !$setup.playerReady && $setup.playerError === null ? 'visible' : 'invisible'
          ])
        }, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("p", null, _toDisplayString($setup.loadingPercentage) + "%", 1 /* TEXT */),
            _createElementVNode("p", null, [
              _createVNode($setup["UiSpinner"], { class: "h-6 w-6 mr-3 inline" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("call.audioPlayer.loadingFile")), 1 /* TEXT */)
            ])
          ])
        ], 2 /* CLASS */),
        _createElementVNode("div", {
          class: _normalizeClass(["absolute w-1/3 top-1/4 left-1/3", $setup.playerError !== null ? 'visible' : 'invisible'])
        }, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("p", null, [
              _createVNode($setup["ExclamationCircleIcon"], { class: "h-6 w-6 mr-3 inline" }),
              _createTextVNode(" " + _toDisplayString($setup.playerError), 1 /* TEXT */)
            ])
          ])
        ], 2 /* CLASS */),
        ($setup.timeline?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "rounded-full group mr-1 h-3 w-3 p-0 bg-blue-500" }, null, -1 /* HOISTED */)),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t("call.audioPlayer.label.word")), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_29, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "rounded-full group mr-1 h-3 w-3 p-0 bg-yellow-500" }, null, -1 /* HOISTED */)),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t("call.audioPlayer.label.insight")), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_30, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "rounded-full group mr-1 h-3 w-3 p-0 bg-orange-500" }, null, -1 /* HOISTED */)),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t("call.audioPlayer.label.wordFilter")), 1 /* TEXT */)
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}