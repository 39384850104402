import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { IntegrationService } from "@/services";
import { UiPageHeader } from "@/components";
import { IntegrationWidget } from "./components";
import {
  UiActions,
  ILevel,
  FilterGetters,
  IIntegration,
  IntegrationType,
} from "@/definitions";
import { toastServiceError } from "@/utils/notification";
import { LinkIcon } from "@heroicons/vue/solid";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationPage',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const { t } = useI18n();

const integrationInfo = {
  [IntegrationType.Pipedrive]: {
    logoPath: "/img/integration/pipedrive.png",
    description: t("integration.integrations.pipedrive.description"),
  },
  [IntegrationType.OpenAi]: {
    logoPath: "/img/integration/openai.png",
    description: t("integration.integrations.openai.description"),
  },
};

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const integrations: Ref<IIntegration[]> = ref([]);
const configuredIntegrations = computed<IIntegration[]>(() =>
  integrations.value.filter((integration) => integrationInfo[integration.type]),
);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const getIntegrations = () => {
  setLoading(true);
  IntegrationService.getAll<Array<IIntegration>>()
    .then((response: AxiosResponse<IIntegration[]>) => {
      integrations.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([level], getIntegrations, { deep: true });

getIntegrations();

const handleConfigClick = (integration: IIntegration) => {
  router.push({
    name: "EditIntegrationById",
    params: {
      type: integration.type,
      id: integration.id,
    },
  });
};

const handleViewLogsClick = (integration: IIntegration) => {
  router.push({
    name: "ViewIntegrationLogs",
    params: {
      type: integration.type,
      id: integration.id,
    },
  });
};

const handleActivateClick = (integration: IIntegration) => {
  router.push({
    name: "CreateIntegration",
    params: {
      type: integration.type,
    },
  });
};

const __returned__ = { router, t, integrationInfo, setLoading, integrations, configuredIntegrations, level, getIntegrations, handleConfigClick, handleViewLogsClick, handleActivateClick, get UiPageHeader() { return UiPageHeader }, get IntegrationWidget() { return IntegrationWidget }, get LinkIcon() { return LinkIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})