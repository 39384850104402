import { defineComponent as _defineComponent } from 'vue'
import { PropType } from "vue";
import { UiSpinner } from "@/components";
import { IDataTotalize } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'DataTotalize',
  props: {
  data: {
    type: Object as PropType<IDataTotalize>,
    required: true,
  },
  loading: {
    type: Boolean,
    require: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props, get UiSpinner() { return UiSpinner } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})