import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import NumberService from "@/services/NumberService";
import { toastServiceError } from "@/utils/notification";
import {
  UiPagination,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
  UiBadge,
} from "@/components";
import NumberListItem from "@/views/pages/number/components/NumberListItem.vue";
import NumberListSort from "@/views/pages/number/components/NumberListSort.vue";
import NumberListFilters from "@/views/pages/number/components/NumberListFilters.vue";
import {
  INumber,
  NumberGetters,
  NumberActions,
  INumberServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IFilterItem,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { PhoneIcon } from "@heroicons/vue/solid";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const numbers: Ref<INumber[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[NumberGetters.SEARCH],
  set: (search: string) => store.dispatch(NumberActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[NumberGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(NumberActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[NumberGetters.PAGE],
  set: (page: number) => store.dispatch(NumberActions.SET_PAGE, page),
});

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const hasConnectFlow = computed(
  () => store.getters[NumberGetters.FILTER].hasConnectFlow,
);
const filterState = computed(() => store.getters[NumberGetters.FILTER].state);
const filterCity = computed(() => store.getters[NumberGetters.FILTER].city);

const getNumbers = () => {
  const params: INumberServiceGetAllParams = {
    page: currentPage.value,
    hasConnectFlow:
      hasConnectFlow.value !== null ? hasConnectFlow.value.value : null,
    state: filterState.value ? filterState.value.value : undefined,
    city: filterCity.value ? filterCity.value.value : undefined,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
  };

  setLoading(true);
  NumberService.getAll<Array<INumber>>({
    params,
  })
    .then((response: AxiosResponse<INumber[]>) => {
      numbers.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([level, search, hasConnectFlow, filterState, filterCity], () => {
  if (currentPage.value === 1) getNumbers();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getNumbers();
});

getNumbers();
const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[NumberGetters.ACTIVE_FILTERS],
);
function handleRemoveFilter(filter: string) {
  store.dispatch(NumberActions.CLEAR_FILTER, filter);
}

const __returned__ = { route, router, setLoading, numbers, search, sortMenuSelected, level, orderBy, currentPage, count, perPage, hasConnectFlow, filterState, filterCity, getNumbers, handleRemoveSortClick, activeFilters, handleRemoveFilter, get UiPagination() { return UiPagination }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get UiBadge() { return UiBadge }, NumberListItem, NumberListSort, NumberListFilters, get PhoneIcon() { return PhoneIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})