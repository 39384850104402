import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8 sm:pt-4 lg:pt-4" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-4" }
const _hoisted_4 = { class: "mt-5 grid grid-cols-1 sm:grid-cols-3 gap-4" }
const _hoisted_5 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_6 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_7 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_8 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_9 = { class: "truncate text-sm font-medium text-gray-500 flex items-center" }
const _hoisted_10 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_11 = { class: "bg-white my-2 items-center rounded ring-1 ring-black ring-opacity-5 pb-2 overflow-auto" }
const _hoisted_12 = { class: "relative h-full w-full rounded text-sm" }
const _hoisted_13 = { class: "min-w-full border-separate border-spacing-0 rounded" }
const _hoisted_14 = {
  scope: "col",
  class: "sticky top-0 z-10 rounded-tl border-b border-gray-300 bg-gray-200 whitespace-nowrap px-3 py-1.5 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_15 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 text-left"
}
const _hoisted_16 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_17 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 text-center md:whitespace-nowrap"
}
const _hoisted_18 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_19 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_20 = {
  scope: "col",
  class: "sticky top-0 z-10 border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_21 = {
  scope: "col",
  class: "sticky top-0 z-10 rounded-tr border-b border-gray-300 bg-gray-200 px-3 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell max-h-4 md:whitespace-nowrap"
}
const _hoisted_22 = { class: "px-3 py-1.5 w-full flex items-center" }
const _hoisted_23 = { class: "whitespace-nowrap" }
const _hoisted_24 = { class: "px-3 py-1.5 text-right" }
const _hoisted_25 = { class: "px-3 py-1.5 text-right" }
const _hoisted_26 = { class: "px-3 py-1.5 text-right" }
const _hoisted_27 = { class: "px-3 py-1.5 text-right" }
const _hoisted_28 = { class: "px-3 py-1.5 flex flex-col items-center" }
const _hoisted_29 = { class: "flex" }
const _hoisted_30 = { class: "px-3 py-1.5 text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.title")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createVNode($setup["UiDatePicker"], {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("dl", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.totalTimePaused")), 1 /* TEXT */),
          _createElementVNode("dd", _hoisted_7, _toDisplayString($setup.formatSecToTime($setup.totalTimePaused)), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("dt", _hoisted_9, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.totalTimeCall")), 1 /* TEXT */),
          _createElementVNode("dd", _hoisted_10, _toDisplayString($setup.formatSecToTime($setup.totalTimeCall)), 1 /* TEXT */)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("table", _hoisted_13, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.userName")), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.extension")), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.totalTimeOnline")), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.totalTimePaused")), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.totalTimeInCall")), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_19, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.lastStatus")), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.queues")), 1 /* TEXT */),
              _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t("report.agentDeviceStatusHistory.details.queueStatus")), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.agentsDevices, (device, idx) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: `queueDetailItem${idx}`,
                class: "even:bg-gray-100"
              }, [
                _createElementVNode("td", _hoisted_22, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getDeviceIcon(device)), { class: "h-4 w-4 mr-1" })),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(device.userName), 1 /* TEXT */)
                ]),
                _createElementVNode("td", _hoisted_24, _toDisplayString(device.extension), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_25, _toDisplayString($setup.formatSecToTime(device.totalTime.CONNECTED, true)), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_26, _toDisplayString(device.queues.length
                    ? $setup.formatSecToTime(device.queues[0].pauseDuration, true)
                    : "-"), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_27, _toDisplayString($setup.formatSecToTime(device.totalTime.IN_CALL, true)), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_28, [
                  (device.lastStatus !== null)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_29, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.getDeviceIcon(device)), { class: "h-4 w-4 mr-1" })),
                          _createElementVNode("span", {
                            class: _normalizeClass($setup.getStatusColor(device.lastStatus))
                          }, _toDisplayString(_ctx.$t(
                          `report.agentDeviceStatusHistory.deviceStatus.${device.lastStatus}`,
                        )), 3 /* TEXT, CLASS */)
                        ]),
                        _createElementVNode("span", {
                          class: _normalizeClass(["text-xs whitespace-nowrap", $setup.getStatusColor(device.lastStatus)])
                        }, _toDisplayString($setup.moment(device.lastStatusAt).fromNow()), 3 /* TEXT, CLASS */)
                      ], 64 /* STABLE_FRAGMENT */))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode("-")
                      ], 64 /* STABLE_FRAGMENT */))
                ]),
                _createElementVNode("td", _hoisted_30, _toDisplayString(device.queues.map((q) => q.name).join(", ")), 1 /* TEXT */),
                _createElementVNode("td", {
                  class: _normalizeClass(["px-3 py-1.5 text-center text-xs flex flex-col", $setup.getQueueColorStatus(device.queueStatus)])
                }, [
                  (device.queueStatus)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t(
                        `report.agentDeviceStatusHistory.queueStatus.${device.queueStatus}`,
                      )), 1 /* TEXT */),
                        _createElementVNode("span", null, _toDisplayString($setup.moment(device.queueStatusAt).fromNow()), 1 /* TEXT */)
                      ], 64 /* STABLE_FRAGMENT */))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ])
    ])
  ]))
}