import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute, RouterLink } from "vue-router";
import LevelService from "@/services/LevelService";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { ILevel, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiGravatar } from "@/components";
import { MailIcon } from "@heroicons/vue/solid";
import { AxiosResponse } from "axios";
import UiPanel from "@/components/UiPanel.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const level: Ref<ILevel | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getLevel = (id: number) => {
  setLoading(true);
  LevelService.get<ILevel>(id)
    .then((response: AxiosResponse<ILevel>) => {
      level.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getLevel(parseInt(route.params.id as string));

const __returned__ = { route, level, setLoading, getLevel, get RouterLink() { return RouterLink }, get goBack() { return goBack }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiGravatar() { return UiGravatar }, get MailIcon() { return MailIcon }, UiPanel }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})