import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef, PropType, WritableComputedRef } from "vue";
import { SpeakerphoneIcon, AnnotationIcon } from "@heroicons/vue/outline";
import {
  CallPlayerGetters,
  CallPlayerMutations,
  ICallInsight,
  ICallWord,
  IInsightByPersona,
  IWordsByPersona,
  ParticipantType,
} from "@/definitions";
import { WordsBlock, InsightBlock } from "@/views/pages/call/components";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallWordsInsight',
  props: {
  insight: {
    type: Object as PropType<IInsightByPersona>,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const words: WritableComputedRef<IWordsByPersona> = computed({
  get: () => store.getters[CallPlayerGetters.WORDS],
  set: (value: IWordsByPersona) => {
    store.commit(CallPlayerMutations.SET_WORDS, {
      value,
    });
  },
});

const agentWords: ComputedRef<ICallWord[]> = computed(() => {
  if (words.value) {
    return words.value?.agent.filter((w) => w.counter > 1);
  }
  return [];
});

const clientWords: ComputedRef<ICallWord[]> = computed(() => {
  if (words.value) {
    return words.value?.client.filter((w) => w.counter > 1);
  }
  return [];
});

const agentInsight: ComputedRef<ICallInsight[]> = computed(() => {
  if (props.insight) {
    return props.insight?.agent;
  }
  return [];
});

const clientInsight: ComputedRef<ICallInsight[]> = computed(() => {
  if (props.insight) {
    return props.insight?.client;
  }
  return [];
});

const __returned__ = { props, words, agentWords, clientWords, agentInsight, clientInsight, get SpeakerphoneIcon() { return SpeakerphoneIcon }, get AnnotationIcon() { return AnnotationIcon }, get ParticipantType() { return ParticipantType }, get WordsBlock() { return WordsBlock }, get InsightBlock() { return InsightBlock } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})