import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import moment from "moment";
import { DocumentDownloadIcon } from "@heroicons/vue/solid";
import { useRoute, useRouter } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import { useI18n } from "vue-i18n";
import {
  UiPageHeader,
  UiDatePicker,
  UiBadge,
  UiListSearch,
  UiPagination,
  UiSortMenuBadge,
  UiButton,
} from "@/components";
import CallService from "@/services/CallService";
import {
  IFilterItem,
  ILevel,
  ICall,
  ISortMenuItem,
  CallType,
  CallStatus,
  CallActions,
  CallGetters,
  FilterGetters,
  FilterActions,
  UiActions,
  Permission,
} from "@/definitions";
import {
  CallListFilters,
  CallListItem,
  CallListSort,
} from "@/views/pages/call/components";
import {
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
} from "@heroicons/vue/outline";
import Analytics from "@/utils/analytics";

const perPage = 20;

export default /*@__PURE__*/_defineComponent({
  __name: 'CallPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

// Pagination
const count = ref(0);
const currentPage = computed<number>({
  get: () => store.getters[CallGetters.PAGE],
  set: (page: number) => store.dispatch(CallActions.SET_PAGE, page),
});

//Filters
const search = computed<string>({
  get: () => store.getters[CallGetters.SEARCH],
  set: (search: string) => store.dispatch(CallActions.SET_SEARCH, search),
});
const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[CallGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(CallActions.SET_SORT, sort),
});
const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);
const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});

const type = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].type !== null &&
  store.getters[CallGetters.FILTER].type.value !== null
    ? store.getters[CallGetters.FILTER].type.value.toString()
    : null,
);
const status = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].status !== null &&
  store.getters[CallGetters.FILTER].status.value !== null
    ? store.getters[CallGetters.FILTER].status.value.toString()
    : null,
);
const userLabel = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].userLabel !== null &&
  store.getters[CallGetters.FILTER].userLabel.id !== null
    ? store.getters[CallGetters.FILTER].userLabel.id.toString()
    : null,
);
const media = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].media !== null &&
  store.getters[CallGetters.FILTER].media.value !== null
    ? store.getters[CallGetters.FILTER].media.value.toString()
    : null,
);
const numberClient = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].numberClient !== null &&
  store.getters[CallGetters.FILTER].numberClient.value !== null
    ? store.getters[CallGetters.FILTER].numberClient.value.toString()
    : null,
);
const word = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].word !== null
    ? store.getters[CallGetters.FILTER].word
    : null,
);

const user = computed<string | null>(() =>
  store.getters[CallGetters.FILTER].user !== null &&
  store.getters[CallGetters.FILTER].user !== null
    ? store.getters[CallGetters.FILTER].user.id.toString()
    : null,
);

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[CallGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

if (route.query.clearStateOnSetup) {
  currentPage.value = 1;
  router.replace({ query: {} });
}
const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};
function handleRemoveFilter(field: string) {
  store.dispatch(CallActions.CLEAR_FILTER, field);
}

const tabFilter = computed((): IFilterItem | null => {
  if (route.params.tab !== undefined) {
    const filter = route.params.tab.toString().toUpperCase() as CallStatus;
    if (Object.keys(CallType).includes(filter)) {
      return {
        field: "type",
        label: "",
        value: filter,
      };
    }

    if (Object.values(CallStatus).includes(filter)) {
      return {
        field: "status",
        label: "",
        value: filter,
      };
    }
  }

  return null;
});

const calls: Ref<ICall[]> = ref([]);
const prepareCallParams = () => {
  if (!level.value || dateRange.value.length < 2) return null;

  const params = {
    search: search.value,
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    type: type.value,
    status: status.value,
    userLabel: userLabel.value,
    media: media.value,
    numberClient: numberClient.value,
    word: word.value,
    user: user.value,
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
  };

  if (tabFilter.value !== null) {
    if (tabFilter.value?.field === "type") {
      params.type = tabFilter.value.value as string;
    }
    if (tabFilter.value?.field === "status") {
      params.status = tabFilter.value.value as string;
    }
  }

  return params;
};

const getCalls = () => {
  const params = prepareCallParams();
  if (!params) return;

  params.page = currentPage.value;
  params.per_page = perPage;

  setLoading(true);

  CallService.getAll({
    params,
  })
    .then((response) => {
      calls.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

const formatFilename = (startDate: string, endDate: string) => {
  const formattedStartDate = moment(startDate).format("DD/MM/YYYY");
  const formattedEndDate = moment(endDate).format("DD/MM/YYYY");
  return `${t("dashboard.report.call")} - ${formattedStartDate} - ${formattedEndDate}.csv`;
};

const getCsv = async () => {
  const params = prepareCallParams();
  if (!params) return;

  setLoading(true);
  Analytics.trackingAction(`CallCSVDownload`).then();
  CallService.getCsvReport({ params })
    .then((response) => {
      const filename = formatFilename(params.startDate, params.endDate);

      const csvString =
        typeof response.data === "string"
          ? response.data
          : JSON.stringify(response.data);
      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8;",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

getCalls();

function applyTabFilter(value: string) {
  router.push({
    name: "CallList",
    params: {
      tab: value.toLowerCase(),
    },
  });
}

function showAllCalls() {
  router.push({ name: "CallList" });
}

function bulletCallStatusColor(status: string) {
  switch (status) {
    case CallStatus.ANSWERED:
      return "bg-green-600";
    case CallStatus.BUSY:
      return "bg-yellow-500";
    case CallStatus.NO_ANSWER:
      return "bg-red-500";
    default:
      return "bg-gray-600";
  }
}

watch(
  [
    search,
    level,
    dateRange,
    orderBy,
    status,
    userLabel,
    type,
    media,
    numberClient,
    user,
    word,
    tabFilter,
  ],
  () => {
    if (currentPage.value === 1) getCalls();
    else currentPage.value = 1;
  },
);

watch([currentPage], () => {
  getCalls();
});

const __returned__ = { route, router, t, count, perPage, currentPage, search, sortMenuSelected, orderBy, level, dateRange, type, status, userLabel, media, numberClient, word, user, activeFilters, setLoading, handleRemoveSortClick, handleRemoveFilter, tabFilter, calls, prepareCallParams, getCalls, formatFilename, getCsv, applyTabFilter, showAllCalls, bulletCallStatusColor, get DocumentDownloadIcon() { return DocumentDownloadIcon }, get UiPageHeader() { return UiPageHeader }, get UiDatePicker() { return UiDatePicker }, get UiBadge() { return UiBadge }, get UiListSearch() { return UiListSearch }, get UiPagination() { return UiPagination }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiButton() { return UiButton }, get CallType() { return CallType }, get CallStatus() { return CallStatus }, get Permission() { return Permission }, get CallListFilters() { return CallListFilters }, get CallListItem() { return CallListItem }, get CallListSort() { return CallListSort }, get PhoneIcon() { return PhoneIcon }, get PhoneIncomingIcon() { return PhoneIncomingIcon }, get PhoneOutgoingIcon() { return PhoneOutgoingIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})