import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import orderBy from "lodash.orderby";
import { toastServiceError } from "@/utils/notification";
import {
  AgentProductivityReportActions,
  AgentProductivityReportGetters,
  CallType,
  FilterActions,
  FilterGetters,
  IAgentProductivityReportFilter,
  IAgentProductivityReportServiceGetInfoParams,
  IAgentProductivityReportServiceGetInfoResult,
  ICallsStats,
  IDataItem,
  IFilterItem,
  ILevel,
  IUserAgentCallsStats,
  UiActions,
} from "@/definitions";
import { AgentProductivityPerAgentReportFilters } from "@/views/pages/reports/agentProductivityPerAgent/components/";
import {
  BarChart,
  CallTypePerUserBarChart,
  CallStatusPerUserBarChart,
  UiBadge,
  UiDatePicker,
  UiPageHeader,
} from "@/components";
import moment from "moment";
import { AgentProductivityPerAgentReportService } from "@/services";
import { IBarChart } from "@/definitions/chart-typings/IBarChart";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentProductivityPerAgentReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const reportInfo: Ref<IAgentProductivityReportServiceGetInfoResult | null> =
  ref(null);

const headerStats = computed<IDataItem<string>[]>(() => {
  const callsStats: ICallsStats | undefined = reportInfo.value?.stats;

  if (callsStats == undefined) return [];

  const result: IDataItem<string>[] = [
    {
      name: "totalCalls",
      value: callsStats.total.toString(),
    },
    {
      name: "inboundCalls",
      value: callsStats.type[CallType.INBOUND].toString(),
    },
    {
      name: "outboundCalls",
      value: callsStats.type[CallType.OUTBOUND].toString(),
    },
    {
      name: "internalCalls",
      value: callsStats.type[CallType.INTERNAL].toString(),
    },
    {
      name: "uniqueNumbers",
      value: callsStats.uniqueNumbers.toString(),
    },
    // {
    //   name: "averageSilencePercentage",
    //   value: callsStats.averageSilencePercentage + "%",
    // },
    {
      name: "averageServiceTime",
      value: moment
        .utc((callsStats.averageServiceTime || 0) * 1000)
        .format("HH:mm:ss"),
    },
  ];

  return result;
});

const getChart = (
  title: string,
  prop: keyof IUserAgentCallsStats,
  color = "#BFDBFE",
  xAxisLabelFormatter: (value: number | string) => string,
) => {
  const topUsers = orderBy(
    reportInfo.value?.userAgentCallsStats,
    prop,
    "desc",
  ).slice(0, 10);

  const category: string[] = [];
  const serie: IDataItem<number[]> = {
    name: title,
    value: [],
    color,
  };

  for (let i = topUsers.length - 1; i >= 0; i--) {
    const user = topUsers[i];
    category.push(user.name);
    serie.value.push(user[prop] as number);
  }

  const barChart: IBarChart = {
    title,
    category,
    series: [serie],
    xAxisLabelFormatter,
  };

  return barChart;
};

const xAxisLabelFormatterAsTime = (value: number | string) => {
  return moment.utc((value as number) * 1000).format("HH:mm:ss");
};

const charts = computed<IBarChart[]>(() => {
  return [
    getChart(
      t("report.agentProductivityPerAgent.serviceTimePerUserBarChart.title"),
      "averageServiceTime",
      "#BFDBFE",
      xAxisLabelFormatterAsTime,
    ),
    // getChart(
    //   t("report.agentProductivityPerAgent.silencePerUserBarChart.title"),
    //   "averageSilencePercentage",
    //   "#E9D5FF",
    //   (value: number | string) => {
    //     return value + "%";
    //   }
    // ),
    getChart(
      t("report.agentProductivityPerAgent.timeAvailablePerUserBarChart.title"),
      "averageTimeAvailable",
      "#FBCFE8",
      xAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerAgent.durationPerUserBarChart.title"),
      "averageDuration",
      "#A5F3FC",
      xAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerAgent.totalIdleTimePerUserBarChart.title"),
      "averageTotalIdleTime",
      "#BAE6FD",
      xAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerAgent.idleTimePerUserBarChart.title"),
      "averageIdleTime",
      "#F5D0FE",
      xAxisLabelFormatterAsTime,
    ),
  ];
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});
const filter = computed<IAgentProductivityReportFilter>(
  () => store.getters[AgentProductivityReportGetters.FILTER],
);
const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[AgentProductivityReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: IAgentProductivityReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    user: filter.value.user?.id || null,
    userLabel: filter.value.userLabel?.id || null,
  };

  setLoading(true);

  AgentProductivityPerAgentReportService.getInfo({
    params,
  })
    .then((response) => {
      reportInfo.value = response.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(AgentProductivityReportActions.CLEAR_FILTER, filter.field);
}

watch([level, dateRange, activeFilters], () => {
  getReportData();
});

getReportData();

const __returned__ = { t, reportInfo, headerStats, getChart, xAxisLabelFormatterAsTime, charts, level, dateRange, filter, activeFilters, setLoading, getReportData, handleRemoveFilter, get AgentProductivityPerAgentReportFilters() { return AgentProductivityPerAgentReportFilters }, get BarChart() { return BarChart }, get CallTypePerUserBarChart() { return CallTypePerUserBarChart }, get CallStatusPerUserBarChart() { return CallStatusPerUserBarChart }, get UiBadge() { return UiBadge }, get UiDatePicker() { return UiDatePicker }, get UiPageHeader() { return UiPageHeader } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})