import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiMultiselect, UiRightPanel } from "@/components";
import {
  IMultiSelectOption,
  MeetingGetters,
  MeetingActions,
  IMeetingFilter,
} from "@/definitions";
import { FilterIcon } from "@heroicons/vue/solid";


export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filtersOpened = ref(false);

const filter: Ref<IMeetingFilter> = ref({
  platform: null,
});

const storeFilters = computed<IMeetingFilter>(
  () => store.getters[MeetingGetters.FILTER],
);

const platformFilterOptions = [
  {
    label: t("meeting.filters.platform.google"),
    value: "GOOGLE_MEET",
  },
] as IMultiSelectOption[];

function applyFilters() {
  store.dispatch(MeetingActions.SET_FILTER, {
    platform: filter.value.platform,
  });
  console.log("Filtros aplicados:", storeFilters.value.platform);
}

function clearFilters() {
  store.dispatch(MeetingActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  console.log("storeFilters.value.platform:", storeFilters.value.platform);
  filter.value.platform = storeFilters.value.platform;
}

watch(filtersOpened, () => {
  refreshLocalFilter();
});

const __returned__ = { t, filtersOpened, filter, storeFilters, platformFilterOptions, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiMultiselect() { return UiMultiselect }, get UiRightPanel() { return UiRightPanel }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})