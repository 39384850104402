import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { toast, toastServiceError } from "@/utils/notification";
import ConnectFlowService from "@/services/ConnectFlowService";
import {
  UiPageHeader,
  UiListSearch,
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
} from "@/components";
import {
  ConnectFlowListItem,
  ConnectFlowListSort,
  ConnectFlowListFilters,
} from "@/views/pages/connect-flow/components";
import {
  IConnectFlow,
  Permission,
  IConnectFlowServiceGetAllParams,
  ConnectFlowGetters,
  ConnectFlowActions,
  IConnectFlowFilter,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
  IFilterItem,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";

const perPage = 30;


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const connectFlows: Ref<IConnectFlow[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[ConnectFlowGetters.SEARCH],
  set: (search: string) =>
    store.dispatch(ConnectFlowActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[ConnectFlowGetters.SORT],
  set: (sort: ISortMenuItem[]) =>
    store.dispatch(ConnectFlowActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[ConnectFlowGetters.PAGE],
  set: (page: number) => store.dispatch(ConnectFlowActions.SET_PAGE, page),
});

const filter: IConnectFlowFilter = store.getters[ConnectFlowGetters.FILTER];

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[ConnectFlowGetters.ACTIVE_FILTERS],
);

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getConnectFlows = () => {
  const params: IConnectFlowServiceGetAllParams = {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    level: filter.level?.id || null,
    is_active: filter.isActive ? filter.isActive.value : null,
    search: search.value,
  };

  setLoading(true);
  ConnectFlowService.getAll<Array<IConnectFlow>>({
    params,
  })
    .then((response: AxiosResponse<IConnectFlow[]>) => {
      connectFlows.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => setLoading(false));
};

watch([level, search, filter, orderBy], () => {
  if (currentPage.value === 1) getConnectFlows();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getConnectFlows();
});

getConnectFlows();

const setIsActive = async (item: IConnectFlow, isActive: boolean) => {
  await setLoading(true);
  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getConnectFlows();
  };

  (isActive ? ConnectFlowService.activate : ConnectFlowService.inactivate)(
    item.id,
  )
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) =>
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);

const handleRemoveStatusFilterClick = (field: string) => {
  store.dispatch(ConnectFlowActions.CLEAR_FILTER, field);
};

const handleActivateClick = (item: IConnectFlow) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: IConnectFlow) => {
  setIsActive(item, false);
};

const __returned__ = { route, router, setLoading, connectFlows, search, sortMenuSelected, level, orderBy, currentPage, filter, activeFilters, count, perPage, getConnectFlows, setIsActive, handleRemoveSortClick, handleRemoveStatusFilterClick, handleActivateClick, handleInactivateClick, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get UiButton() { return UiButton }, get UiPagination() { return UiPagination }, get UiBadge() { return UiBadge }, get UiSortMenuBadge() { return UiSortMenuBadge }, get ConnectFlowListItem() { return ConnectFlowListItem }, get ConnectFlowListSort() { return ConnectFlowListSort }, get ConnectFlowListFilters() { return ConnectFlowListFilters }, get Permission() { return Permission }, get ConnectFlowIcon() { return ConnectFlowIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})