import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "order-1 w-2/12 sm:w-2/12 flex sm:justify-center" }
const _hoisted_2 = { class: "mb-3 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mb-8 flex justify-items-start sm:justify-between items-top w-full", 
      $props.item.participant === $setup.ParticipantType.Agent ? 'sm:flex-row-reverse' : ''
    ])
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "order-1 w-0/12 sm:w-5/12" }, null, -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["UiButton"], {
        class: _normalizeClass(["z-20 mt-7 shadow w-10 h-10 p-0 rounded-full", 
          $setup.playerHasError ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer'
        ]),
        size: "",
        variant: $setup.playerHasError ? 'neutral' : 'primary',
        "text-variant": "white",
        shape: "rounded-full",
        themed: ""
      }, {
        default: _withCtx(() => [
          (
            $setup.isPlaying &&
            $setup.secondsCurrent >= $props.item.startedAt &&
            $setup.secondsCurrent <= $props.item.endedAt
          )
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "mx-auto text-white font-semibold text-lg w-full h-full",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.pause()))
              }, [
                _createVNode($setup["PauseIcon"])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "mx-auto text-white font-semibold text-lg w-full h-full",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.playSnippet($props.item.startedAt, $props.item.endedAt)))
              }, [
                (!$setup.playerHasError)
                  ? (_openBlock(), _createBlock($setup["PlayIcon"], { key: 0 }))
                  : _createCommentVNode("v-if", true),
                ($setup.playerHasError)
                  ? (_openBlock(), _createBlock($setup["XCircleIcon"], { key: 1 }))
                  : _createCommentVNode("v-if", true)
              ]))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class", "variant"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["order-1 bg-white shadow w-10/12 sm:grow-0 sm:w-5/12 px-6 py-4 relative", 
        $props.item.participant === $setup.ParticipantType.Agent
          ? 'rounded-lg sm:rounded-r-none border-l-4 attendant-border-color sm:right-arrow-box'
          : 'rounded-lg sm:rounded-l-none border-r-4 client-border-color sm:left-arrow-box'
      ])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", {
          class: _normalizeClass(["font-bold text-xl", 
            $props.item.participant === $setup.ParticipantType.Agent
              ? 'attendant-color'
              : 'client-color'
          ])
        }, _toDisplayString($props.item.participant === $setup.ParticipantType.Agent
              ? _ctx.$t("call.view.participant.agent")
              : _ctx.$t("call.view.participant.client")), 3 /* TEXT, CLASS */),
        _createCommentVNode("        <span"),
        _createCommentVNode("          v-if=\"item.sentiment !== null && item.sentiment !== ''\""),
        _createCommentVNode("          class=\"right-0 top-1 inline-flex items-center absolute text-sm font-normal\""),
        _createCommentVNode("          :class=\"getSentimentColor(item.sentiment)\""),
        _createCommentVNode("        >"),
        _createCommentVNode("          <svg class=\"mr-1 h-2 w-2\" fill=\"currentColor\" viewBox=\"0 0 12 12\">"),
        _createCommentVNode("            <circle cx=\"6\" cy=\"6\" r=\"6\" />"),
        _createCommentVNode("          </svg>"),
        _createCommentVNode("          {{ $t(`intelligence.sentiment.${item.sentiment.toLowerCase()}`) }}"),
        _createCommentVNode("        </span>")
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["UiBadge"], {
          class: "rounded-lg mb-2",
          textSize: "text-xs"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.formatSecToTime($props.item.startedAt)) + " - " + _toDisplayString($setup.formatSecToTime($props.item.endedAt)), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-row flex-wrap text-sm font-medium gap-2", 
            $props.item.participant === $setup.ParticipantType.Agent
              ? 'justify-start sm:justify-end'
              : 'sm:justify-start'
          ])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.words, (word) => {
            return (_openBlock(), _createBlock($setup["UiBadge"], {
              key: `timeline${$props.item.id}-word-${word.id}`,
              variant: "blue",
              class: "rounded-lg"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(word.value), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 128 /* KEYED_FRAGMENT */)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.item.insights, (insight) => {
            return (_openBlock(), _createBlock($setup["UiBadge"], {
              key: `timeline${$props.item.id}-word-${insight.id}`,
              variant: "yellow",
              class: "rounded-lg py-2"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(insight.value), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */),
        _createCommentVNode(" <emotion-badge :emotion=\"item.emotion\" />")
      ])
    ], 2 /* CLASS */)
  ], 2 /* CLASS */))
}