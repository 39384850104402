import { defineComponent as _defineComponent } from 'vue'
import { onMounted, Ref, ref, computed } from "vue";
import { useRoute, RouterLink } from "vue-router";
import { can } from "@/utils/auth";
import { INavigationItem } from "@/definitions";
import { ChevronRightIcon } from "@heroicons/vue/outline";
import { ILevel, FilterGetters } from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateNavigation',
  props: {
  items: {
    type: Array<INavigationItem>,
    default: [],
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const filteredNavigationItems: Ref<INavigationItem[]> = ref([]);
const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const isActive = (item: INavigationItem) => {
  if (item.name == "Home") {
    return route.name === item.name;
  } else {
    return route.matched.some(
      ({ name }) => name === item.name || item.activateBy?.indexOf(name) >= 0,
    );
  }
};

const hasActiveChildren = (item: INavigationItem) => {
  return (
    (item.children || []).filter((i: INavigationItem) => isActive(i)).length > 0
  );
};

function toggleVisibility(item: INavigationItem) {
  item.isOpen = !item.isOpen;
}

onMounted(() => {
  filteredNavigationItems.value = props.items
    .map((item) => {
      return !item.children?.length
        ? item
        : {
            ...item,
            children: item.children.filter(
              (child) => !child.permission || can(child.permission),
            ),
          };
    })
    .filter((item, index) => {
      if (
        item.localeKey === "sideNavigation.meet.group" &&
        !level.value.meeting
      ) {
        return false;
      }
      return props.items[index].children?.length === undefined
        ? !item.permission || can(item.permission)
        : item.children?.length;
    });
});

const __returned__ = { route, props, filteredNavigationItems, level, isActive, hasActiveChildren, toggleVisibility, get RouterLink() { return RouterLink }, get ChevronRightIcon() { return ChevronRightIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})