import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import LevelService from "@/services/LevelService";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
} from "@/components";
import {
  LevelListItem,
  LevelListSort,
  LevelListFilters,
} from "@/views/pages/level/components";
import {
  ILevel,
  Permission,
  LevelGetters,
  LevelActions,
  ILevelFilter,
  ILevelServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { OfficeBuildingIcon } from "@heroicons/vue/solid";

const perPage = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const levels: Ref<ILevel[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[LevelGetters.SEARCH],
  set: (search: string) => store.dispatch(LevelActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[LevelGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(LevelActions.SET_SORT, sort),
});

const globalLevelFilter = computed<ILevel>(
  () => store.getters[FilterGetters.LEVEL],
);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[LevelGetters.PAGE],
  set: (page: number) => store.dispatch(LevelActions.SET_PAGE, page),
});

const filter: ILevelFilter = store.getters[LevelGetters.FILTER];

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getLevels = () => {
  const params: ILevelServiceGetAllParams = {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active:
      filter.isActive !== null ? (filter.isActive as boolean) : undefined,
    search: search.value,
  };

  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params,
  })
    .then((response: AxiosResponse<ILevel[]>) => {
      levels.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([globalLevelFilter, search, filter, orderBy], () => {
  if (currentPage.value === 1) getLevels();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getLevels();
});

getLevels();

const setIsActive = async (item: ILevel, isActive: boolean) => {
  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getLevels();
  };

  (isActive ? LevelService.activate : LevelService.inactivate)(item.id)
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveStatusFilterClick = () => {
  store.dispatch(LevelActions.CLEAR_FILTER, "isActive");
};

const handleActivateClick = (item: ILevel) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: ILevel) => {
  setIsActive(item, false);
};

const __returned__ = { route, router, setLoading, levels, search, sortMenuSelected, globalLevelFilter, orderBy, currentPage, filter, count, perPage, getLevels, setIsActive, handleRemoveSortClick, handleRemoveStatusFilterClick, handleActivateClick, handleInactivateClick, get UiButton() { return UiButton }, get UiPagination() { return UiPagination }, get UiBadge() { return UiBadge }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get LevelListItem() { return LevelListItem }, get LevelListSort() { return LevelListSort }, get LevelListFilters() { return LevelListFilters }, get Permission() { return Permission }, get OfficeBuildingIcon() { return OfficeBuildingIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})