import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "flex items-center space-x-4" }
const _hoisted_5 = { class: "space-y-6" }
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 pb-6 border-b border-gray-200" }
const _hoisted_7 = { class: "font-semibold mb-1" }
const _hoisted_8 = { class: "font-semibold mb-1" }
const _hoisted_9 = { class: "font-semibold mb-1" }
const _hoisted_10 = { class: "font-semibold mb-1" }
const _hoisted_11 = {
  key: 0,
  controls: "",
  class: "min-w-[120px] mt-6"
}
const _hoisted_12 = ["src", "type"]
const _hoisted_13 = { class: "text-base font-semibold leading-6 text-gray-900 mb-6" }
const _hoisted_14 = { class: "space-y-4" }
const _hoisted_15 = { class: "font-semibold mt-10" }
const _hoisted_16 = {
  key: 0,
  class: "mt=10"
}
const _hoisted_17 = {
  key: 1,
  controls: "",
  class: "mt=10"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 2,
  class: "text-sm text-gray-500 mt=10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("survey.view.title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("survey.view.description")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'ListSurvey' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.$can($setup.Permission.EDIT_SURVEY))
            ? (_openBlock(), _createBlock($setup["RouterLink"], {
                key: 0,
                to: {
              name: 'EditSurveyById',
              params: {
                id: $setup.route.params.id,
              },
            },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["UiButton"], {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("survey.labels.name")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.survey?.name), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("survey.labels.text")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.survey?.textToAudio), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("survey.labels.status")), 1 /* TEXT */),
              _createElementVNode("p", {
                class: _normalizeClass([[
                  $setup.survey?.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800',
                ], "inline-flex rounded-full px-2 text-sm font-semibold"])
              }, _toDisplayString($setup.survey?.isActive
                    ? _ctx.$t("core.options.Active")
                    : _ctx.$t("core.options.Inactive")), 3 /* TEXT, CLASS */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("survey.labels.audio")), 1 /* TEXT */),
              ($setup.audioData)
                ? (_openBlock(), _createElementBlock("audio", _hoisted_11, [
                    _createElementVNode("source", {
                      src: $setup.audioData,
                      type: $setup.getAudioFormat($setup.survey.audioFilePath || '')
                    }, null, 8 /* PROPS */, _hoisted_12)
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t("survey.view.section.questions.title")), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.survey?.questions, (question) => {
              return (_openBlock(), _createElementBlock("div", {
                key: question.id
              }, [
                _createElementVNode("h4", _hoisted_15, _toDisplayString(question.label), 1 /* TEXT */),
                (question.textToAudio)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(question.textToAudio), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true),
                (question.audioFilePath)
                  ? (_openBlock(), _createElementBlock("audio", _hoisted_17, [
                      _createElementVNode("source", {
                        src: question.audioFilePath,
                        type: "audio/mpeg"
                      }, null, 8 /* PROPS */, _hoisted_18),
                      _cache[1] || (_cache[1] = _createTextVNode(" Seu navegador não suporta o elemento de áudio. "))
                    ]))
                  : _createCommentVNode("v-if", true),
                (question.rangeAnswer)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.$t("survey.view.section.questions.scoreRange")) + ": " + _toDisplayString(question.rangeAnswer), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}