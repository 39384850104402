import { defineComponent as _defineComponent } from 'vue'
import { Ref, ref, computed, watch, reactive } from "vue";
import {
  UiButton,
  UiTextInput,
  UiTextArea,
  UiAudioUpload,
  UiMultiselect,
} from "@/components";
import { TrashIcon } from "@heroicons/vue/outline";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requiredIf } from "@/utils/validators";
import { IQuestionForm, IMultiSelectOption } from "@/definitions";
import { VaultService } from "@/services";
import { getAudioFormat } from "@/utils/audio";


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyFormQuestion',
  props: {
  modelValue: {
    type: Object as () => IQuestionForm,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  showRemove: {
    type: Boolean,
    required: true,
  },
},
  emits: ["update:modelValue", "remove"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const formState = reactive<IQuestionForm>(props.modelValue);

const scores = [
  {
    label: "0-5",
    value: "0-5",
  },
  {
    label: "0-9",
    value: "0-9",
  },
  {
    label: "0-10",
    value: "0-10",
  },
  {
    label: "1-2",
    value: "1-2",
  },
  {
    label: "1-5",
    value: "1-5",
  },
  {
    label: "1-9",
    value: "1-9",
  },
  {
    label: "1-10",
    value: "1-10",
  },
] as IMultiSelectOption[];

const emit = __emit;

const rules = computed<ValidationArgs<IQuestionForm>>(() => {
  const r: ValidationArgs<IQuestionForm> = {
    id: {},
    label: { required },
    textToAudio: {
      required: requiredIf(() => {
        return !formState.audio && !formState.audioFilePath;
      }),
    },
    audio: {
      required: requiredIf(() => {
        return (
          (formState.textToAudio?.length ?? 0) < 1 && !formState.audioFilePath
        );
      }),
    },
    rangeAnswer: { required },
  };

  return r;
});

const v$: Ref<Validation<ValidationArgs<IQuestionForm>>> = useVuelidate(
  rules,
  formState,
);

const isTextAreaEmpty = computed(
  () => (formState.textToAudio?.length ?? 0) > 0,
);
const isAudioEmpty = computed(() => !!formState.audio);

const handleFileUpdate = (file: File) => {
  if (file) {
    formState.audio = file;
  } else {
    formState.audio = null;
  }
};

const audioData = ref();

const getAudioFile = async () => {
  if (formState.audioFilePath) {
    const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
    const blob = new Blob([data], {
      type: getAudioFormat(formState.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

if (formState.audioFilePath) {
  getAudioFile();
}

watch(
  formState,
  () => {
    emit("update:modelValue", formState);
  },
  {
    deep: true,
  },
);

const removeQuestion = () => {
  emit("remove", formState.uuid);
};

const __returned__ = { props, formState, scores, emit, rules, v$, isTextAreaEmpty, isAudioEmpty, handleFileUpdate, audioData, getAudioFile, removeQuestion, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiTextArea() { return UiTextArea }, get UiAudioUpload() { return UiAudioUpload }, get UiMultiselect() { return UiMultiselect }, get TrashIcon() { return TrashIcon }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})