import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import { useRoute } from "vue-router";
import { IntegrationType } from "@/definitions";
import {
  PipedriveIntegrationFormPage,
  OpenAiIntegrationFormPage,
} from "@/views/pages/integration";

// Configuration object for integration types

export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationDynamicForm',
  setup(__props, { expose: __expose }) {
  __expose();

const integrationConfig = {
  [IntegrationType.Pipedrive]: PipedriveIntegrationFormPage,
  [IntegrationType.OpenAi]: OpenAiIntegrationFormPage,
};

// Access the current route
const route = useRoute();

// Resolve the component based on the route parameter
const resolvedComponent = computed(() => {
  const type = route.params.type as IntegrationType;
  return integrationConfig[type];
});

// Combine route props with passed props
const routeProps = computed(() => ({
  ...route.params,
}));

const __returned__ = { integrationConfig, route, resolvedComponent, routeProps }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})