import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 px-4 sm:px-0 sm:grid-cols-9 gap-4 sm:gap-0" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "flex flex-col justify-center" }
const _hoisted_5 = { class: "truncate text-sm text-gray-500" }
const _hoisted_6 = { class: "truncate text-sm text-gray-500" }
const _hoisted_7 = { class: "flex items-center text-sm" }
const _hoisted_8 = { class: "text-md" }
const _hoisted_9 = { class: "self-center" }
const _hoisted_10 = { class: "flex gap-1.5 flex-row text-gray-600 text-sm self-center items-center" }
const _hoisted_11 = { class: "whitespace-nowrap" }
const _hoisted_12 = { class: "self-center" }
const _hoisted_13 = { class: "flex flex-col text-gray-500 text-sm" }
const _hoisted_14 = { class: "text-gray-500" }
const _hoisted_15 = { class: "self-center" }
const _hoisted_16 = { class: "flex flex-col text-gray-500 text-sm" }
const _hoisted_17 = { class: "text-gray-500 text-sm" }
const _hoisted_18 = { class: "self-center" }
const _hoisted_19 = { class: "flex flex-col text-gray-500 text-sm" }
const _hoisted_20 = { class: "text-gray-500 text-sm" }
const _hoisted_21 = { class: "self-center" }
const _hoisted_22 = { class: "flex flex-col text-gray-500 text-sm" }
const _hoisted_23 = { class: "text-gray-500 text-sm" }
const _hoisted_24 = { class: "self-center" }
const _hoisted_25 = { class: "flex flex-col text-gray-500 text-sm" }
const _hoisted_26 = { class: "text-gray-500 text-sm" }
const _hoisted_27 = { class: "self-center hidden sm:block" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "grid grid-cols-1 gap-7 sm:grid-cols-3" }
const _hoisted_30 = { class: "overflow-hidden" }
const _hoisted_31 = { class: "text-2xl font-semibold attendant-color" }
const _hoisted_32 = { class: "overflow-hidden" }
const _hoisted_33 = { class: "text-2xl font-semibold client-color" }
const _hoisted_34 = { class: "overflow-hidden" }
const _hoisted_35 = { class: "text-2xl font-semibold text-blue-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["UiPanel"], { class: "mt-0" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["ClockIcon"], {
              class: "h-5 w-5 text-gray-400 mr-1",
              "aria-hidden": "true"
            }),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString($setup.moment($setup.call.startedAt).format("DD/MM/YYYY")), 1 /* TEXT */),
              _createElementVNode("span", _hoisted_6, _toDisplayString($setup.moment($setup.call.startedAt).format("HH:mm:ss")), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            ($setup.call.type === $setup.CallType.INBOUND)
              ? (_openBlock(), _createBlock($setup["PhoneIncomingIcon"], {
                  key: 0,
                  class: "h-5 w-5 text-cyan-500 mr-1",
                  "aria-hidden": "true"
                }))
              : ($setup.call.type === $setup.CallType.OUTBOUND)
                ? (_openBlock(), _createBlock($setup["PhoneOutgoingIcon"], {
                    key: 1,
                    class: "h-5 w-5 text-orange-500 mr-1",
                    "aria-hidden": "true"
                  }))
                : ($setup.call.type === $setup.CallType.INTERNAL)
                  ? (_openBlock(), _createBlock($setup["PhoneIcon"], {
                      key: 2,
                      class: "h-5 w-5 text-gray-500 mr-1",
                      "aria-hidden": "true"
                    }))
                  : _createCommentVNode("v-if", true),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(`call.type.${$setup.call.type}`)), 1 /* TEXT */)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex rounded-full h-2.5 w-2.5", $setup.CallStatusClassColor[$setup.call.status]])
              }, null, 2 /* CLASS */),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t(`call.status.${$setup.call.status}`)), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("call.view.origin")), 1 /* TEXT */),
              _createElementVNode("span", null, _toDisplayString($setup.call.events[0].sourceNumber), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("call.view.mask")), 1 /* TEXT */),
              _cache[0] || (_cache[0] = _createElementVNode("span", null, "-", -1 /* HOISTED */))
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("call.view.destination")), 1 /* TEXT */),
              _createElementVNode("span", null, _toDisplayString($setup.call.destinationNumber), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("call.view.duration")), 1 /* TEXT */),
              _createElementVNode("span", null, _toDisplayString($setup.formatSecToTime($setup.duration)), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("call.view.media")), 1 /* TEXT */),
              _createElementVNode("span", null, _toDisplayString($setup.call.media), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            false
              ? (_openBlock(), _createBlock($setup["UiButtonDownload"], {
                  key: 0,
                  name: "CallAudio",
                  fileUrl: 
                $setup.call.audioFilePath !== undefined ? $setup.call.audioFilePath : ''
              ,
                  class: _normalizeClass(
                $setup.call.audioFilePath !== undefined
                  ? 'theme-bg-color hover:opacity-70'
                  : 'bg-gray-300 cursor-not-allowed'
              )
                }, null, 8 /* PROPS */, ["fileUrl", "class"]))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        false
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createElementVNode("dl", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("dd", _hoisted_31, _toDisplayString($setup.percentageAttendant), 1 /* TEXT */),
                  _cache[1] || (_cache[1] = _createElementVNode("dt", { class: "text-sm font-medium text-gray-500 truncate" }, " Fala do atendente ", -1 /* HOISTED */))
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("dd", _hoisted_33, _toDisplayString($setup.percentageClient), 1 /* TEXT */),
                  _cache[2] || (_cache[2] = _createElementVNode("dt", { class: "text-sm font-medium text-gray-500 truncate" }, " Fala do cliente ", -1 /* HOISTED */))
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("dd", _hoisted_35, _toDisplayString($setup.percentageNonTalk), 1 /* TEXT */),
                  _cache[3] || (_cache[3] = _createElementVNode("dt", { class: "text-sm font-medium text-gray-500 truncate" }, " Non-talk ", -1 /* HOISTED */))
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}