import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import LevelService from "@/services/LevelService";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
} from "@/components";
import {
  ILevel,
  Permission,
  UiActions,
  IDefaultServiceResult,
  ILevelServiceCreateResult,
  FormAction,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<ILevel>({
  id: 0,
  name: "",
  isActive: true,
});

const rules = computed(() => ({
  id: {},
  name: {
    required,
    maxLength: maxLength(500),
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<ILevel>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getLevel = (id: number) => {
  setLoading(true);
  LevelService.get<ILevel>(id)
    .then((response: AxiosResponse<ILevel>) => {
      const { name, isActive } = response.data;
      formState.id = id;
      formState.name = name;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getLevel(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewLevelById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    LevelService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    LevelService.create<ILevelServiceCreateResult>(formState)
      .then(
        (response: AxiosResponse<ILevelServiceCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewLevelById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "ListLevel",
    });
};

const __returned__ = { route, props, formState, rules, v$, setLoading, getLevel, onSubmit, handleBackClick, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})