import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, onMounted } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import ConnectFlowService from "@/services/ConnectFlowService";
import ConnectFlowHistoryItem from "@/views/pages/connect-flow/components/ConnectFlowHistoryItem.vue";
import { UiPageHeader, UiButton, UiPagination } from "@/components";
import {
  Permission,
  ConnectFlowGetters,
  ConnectFlowActions,
  UiActions,
  IConnectFlowServiceGetHistoryParams,
  IConnectFlowFlow,
  IConnectFlow,
} from "@/definitions";
import { AxiosResponse } from "axios";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";

const perPage = 20;

export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowHistoryPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const connectFlow: Ref<IConnectFlow | undefined> = ref(undefined);
const flowHistory: Ref<IConnectFlowFlow[]> = ref([]);

const currentPage = computed<number>({
  get: () => store.getters[ConnectFlowGetters.PAGE],
  set: (page: number) => store.dispatch(ConnectFlowActions.SET_PAGE, page),
});

const count = ref(0);
currentPage.value = 1;

if (route.query.clearStateOnSetup) {
  router.replace({ query: {} });
}

const getHistory = () => {
  const params: IConnectFlowServiceGetHistoryParams = {
    page: currentPage.value,
    per_page: perPage,
  };

  setLoading(true);
  ConnectFlowService.getFlowHistory<Array<IConnectFlowFlow>>(
    route.params.id as string,
    {
      params,
    },
  )
    .then((response: AxiosResponse<IConnectFlowFlow[]>) => {
      flowHistory.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => setLoading(false));
};
getHistory();
const getConnectFlow = (id: number) => {
  setLoading(true);
  ConnectFlowService.get<IConnectFlow>(id)
    .then((response: AxiosResponse<IConnectFlow>) => {
      connectFlow.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
onMounted(() => {
  setLoading(true);
  getConnectFlow(parseInt(route.params.id as string));
  getHistory();
});

const __returned__ = { route, router, setLoading, connectFlow, flowHistory, currentPage, count, perPage, getHistory, getConnectFlow, ConnectFlowHistoryItem, get UiPageHeader() { return UiPageHeader }, get UiButton() { return UiButton }, get UiPagination() { return UiPagination }, get Permission() { return Permission }, get ConnectFlowIcon() { return ConnectFlowIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})