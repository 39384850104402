import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 pb-6 border-b border-gray-200" }
const _hoisted_6 = { class: "font-semibold mb-1" }
const _hoisted_7 = { class: "font-semibold mb-1" }
const _hoisted_8 = { class: "font-semibold mb-1" }
const _hoisted_9 = { class: "font-semibold mb-1" }
const _hoisted_10 = { class: "font-semibold mb-1" }
const _hoisted_11 = { class: "text-base font-semibold leading-6 text-gray-900 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("schedule.view.title")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'ListSchedule' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.$can($setup.Permission.EDIT_SCHEDULE))
            ? (_openBlock(), _createBlock($setup["RouterLink"], {
                key: 0,
                to: {
              name: 'EditScheduleById',
              params: {
                id: $setup.route.params.id,
              },
            },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["UiButton"], {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("schedule.labels.name")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.schedule?.name), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("schedule.labels.status")), 1 /* TEXT */),
              _createElementVNode("p", {
                class: _normalizeClass([[
                  $setup.schedule?.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800',
                ], "inline-flex rounded-full px-2 text-sm font-semibold"])
              }, _toDisplayString($setup.schedule?.isActive
                    ? _ctx.$t("core.options.Active")
                    : _ctx.$t("core.options.Inactive")), 3 /* TEXT, CLASS */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("schedule.labels.timeBegin")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.schedule?.timeBegin.substring(0, 5)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("schedule.labels.timeEnd")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.schedule?.timeEnd.substring(0, 5)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("schedule.labels.daysActive")), 1 /* TEXT */),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredDaysOfWeek, (day) => {
                return (_openBlock(), _createElementBlock("ol", {
                  class: "list-disc list-inside",
                  key: day
                }, [
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t(`schedule.labels.${day}`)), 1 /* TEXT */)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t("schedule.view.section.users.title")), 1 /* TEXT */),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6" }, null, -1 /* HOISTED */))
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}