import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { UserLabelService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UiPagination,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
  UiBadge,
  UiButton,
} from "@/components";
import UserLabelListItem from "@/views/pages/userLabel/components/UserLabelListItem.vue";
import UserLabelListSort from "@/views/pages/userLabel/components/UserLabelListSort.vue";
import UserLabelListFilters from "@/views/pages/userLabel/components/UserLabelListFilters.vue";
import {
  UserLabelGetters,
  UserLabelActions,
  UiActions,
  Permission,
  IUserLabelServiceGetAllParams,
  ISortMenuItem,
  IFilterItem,
  IUserLabel,
  IDefaultServiceResult,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { UserGroupIcon } from "@heroicons/vue/solid";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const userLabels: Ref<IUserLabel[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[UserLabelGetters.SEARCH],
  set: (search: string) => store.dispatch(UserLabelActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[UserLabelGetters.SORT],
  set: (sort: ISortMenuItem[]) =>
    store.dispatch(UserLabelActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[UserLabelGetters.PAGE],
  set: (page: number) => store.dispatch(UserLabelActions.SET_PAGE, page),
});

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const filter = computed(() => store.getters[UserLabelGetters.FILTER]);

const getUserLabels = () => {
  const params: IUserLabelServiceGetAllParams = {
    page: currentPage.value,
    call_participant: filter.value.callParticipant?.value,
    is_active: filter.value.isActive?.value,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
  };

  setLoading(true);
  UserLabelService.getAll<Array<IUserLabel>>({
    params,
  })
    .then((response: AxiosResponse<IUserLabel[]>) => {
      userLabels.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch(
  [level, search, filter, orderBy],
  () => {
    if (currentPage.value === 1) getUserLabels();
    else currentPage.value = 1;
  },
  { deep: true },
);

watch([currentPage], () => {
  getUserLabels();
});

getUserLabels();

const setIsActive = async (item: IUserLabel, isActive: boolean) => {
  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getUserLabels();
  };

  (isActive ? UserLabelService.activate : UserLabelService.inactivate)(item.id)
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[UserLabelGetters.ACTIVE_FILTERS],
);
function handleRemoveFilter(filter: string) {
  store.dispatch(UserLabelActions.CLEAR_FILTER, filter);
}
const handleActivateClick = (item: IUserLabel) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: IUserLabel) => {
  setIsActive(item, false);
};

const __returned__ = { route, router, setLoading, userLabels, search, sortMenuSelected, level, orderBy, currentPage, count, perPage, filter, getUserLabels, setIsActive, handleRemoveSortClick, activeFilters, handleRemoveFilter, handleActivateClick, handleInactivateClick, get UiPagination() { return UiPagination }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get UiBadge() { return UiBadge }, get UiButton() { return UiButton }, UserLabelListItem, UserLabelListSort, UserLabelListFilters, get Permission() { return Permission }, get UserGroupIcon() { return UserGroupIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})