import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, ref, WritableComputedRef } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { ArrowsExpandIcon } from "@heroicons/vue/outline";
import { UiPageHeader, UiButton } from "@/components";
import { VueFlow, useVueFlow } from "@vue-flow/core";
import { Background } from "@vue-flow/background";
import { Controls, ControlButton } from "@vue-flow/controls";
import { MiniMap } from "@vue-flow/minimap";
import {
  UiActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingActions,
  Permission,
  EdgeType,
  IConnectFlowFlowResult,
  IConnectFlow,
} from "@/definitions";
import {
  ComponentSelector,
  CustomNode,
  FormClienteInfoEdge,
  FormAwaitableEdge,
} from "./components/modeling";
import {
  AwaitableEdge,
  ClientInfoEdge,
} from "@/views/pages/connect-flow/components/modeling/edges";
import { StartNode } from "./components/modeling/nodes";
import { useI18n } from "vue-i18n";
import { ConnectFlowService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { AxiosResponse } from "axios";
import { formatDateAsString } from "@/utils/datetimeCommon";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowModelingPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();
const route = useRoute();

const props = __props;

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const connectFlow: WritableComputedRef<IConnectFlow> = computed({
  get: () => store.getters["flow/" + ConnectFlowModelingGetters.CONNECT_FLOW],
  set: (value) =>
    store.dispatch(
      "flow/" + ConnectFlowModelingActions.SET_CONNECT_FLOW,
      value,
    ),
});

const elements = computed({
  get: () => store.getters["flow/" + ConnectFlowModelingGetters.ELEMENTS],
  set: (value) =>
    store.dispatch("flow/" + ConnectFlowModelingActions.SET_ELEMENTS, value),
});

const isAllElementsValid = computed(
  () =>
    store.getters["flow/" + ConnectFlowModelingGetters.IS_ALL_ELEMENTS_VALID],
);

const fullScreen = ref(false);

onMounted(() => {
  setLoading(true);
  store
    .dispatch("flow/" + ConnectFlowModelingActions.LOAD_FLOW_FLOW, {
      id: route.params.id,
      historyId: route.params.historyId,
    })
    .then(() => {
      setLoading(false);
    });
});

function save() {
  setLoading(true);
  ConnectFlowService.saveFlow<IConnectFlowFlowResult>(
    route.params.id.toString(),
    elements.value,
  )
    .then((response: AxiosResponse<IConnectFlowFlowResult>) => {
      connectFlow.value = response.data.connectFlow;
      toast.success(response.data.message);
    })
    .finally(() => setLoading(false));
}

function publish() {
  setLoading(true);
  ConnectFlowService.saveFlow<IConnectFlowFlowResult>(
    route.params.id.toString(),
    elements.value,
  ).then(() => {
    ConnectFlowService.publishFlow<IConnectFlowFlowResult>(
      route.params.id.toString(),
      elements.value,
    )
      .then((response: AxiosResponse<IConnectFlowFlowResult>) => {
        connectFlow.value = response.data.connectFlow;
        toast.success(response.data.message);
      }, toastServiceError)
      .finally(() => setLoading(false));
  });
}

const { onPaneReady, onEdgeClick } = useVueFlow();

onPaneReady(({ fitView }) => {
  fitView();
});

const showFormClienteInfoEdge = ref(false);
const showFormAwaitableEdge = ref(false);
const triggeredEdge = ref();
onEdgeClick((event) => {
  if (event.edge.data.component === EdgeType.AWAITABLE) {
    showFormAwaitableEdge.value = true;
    triggeredEdge.value = event.edge.id;
  }
  if (event.edge.data.component === EdgeType.CLIENT_INFO_EDGE) {
    showFormClienteInfoEdge.value = true;
    triggeredEdge.value = event.edge.id;
  }
});

const __returned__ = { t, route, props, setLoading, connectFlow, elements, isAllElementsValid, fullScreen, save, publish, onPaneReady, onEdgeClick, showFormClienteInfoEdge, showFormAwaitableEdge, triggeredEdge, get ArrowsExpandIcon() { return ArrowsExpandIcon }, get UiPageHeader() { return UiPageHeader }, get UiButton() { return UiButton }, get VueFlow() { return VueFlow }, get Background() { return Background }, get Controls() { return Controls }, get ControlButton() { return ControlButton }, get MiniMap() { return MiniMap }, get Permission() { return Permission }, get EdgeType() { return EdgeType }, get ComponentSelector() { return ComponentSelector }, get CustomNode() { return CustomNode }, get FormClienteInfoEdge() { return FormClienteInfoEdge }, get FormAwaitableEdge() { return FormAwaitableEdge }, get AwaitableEdge() { return AwaitableEdge }, get ClientInfoEdge() { return ClientInfoEdge }, get StartNode() { return StartNode }, get formatDateAsString() { return formatDateAsString } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})