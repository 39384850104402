import { defineComponent as _defineComponent } from 'vue'
import { ErrorObject } from "@vuelidate/core";
import { computed } from "vue";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiTextInput',
  props: {
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: [String, Boolean],
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "off",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isPhoneNumber: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array<ErrorObject>,
    default: [],
  },
  silentErrors: {
    type: Array<ErrorObject>,
    default: [],
  },
  inputClass: {
    type: String,
    default: "px-4 py-3",
  },
},
  emits: ["update:modelValue", "blur"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const disabledClass = computed(() =>
  props.disabled ? "bg-gray-300 text-gray-500" : "",
);

const hasErros = computed(
  () => props.errors.length || props.silentErrors.length,
);
const emit = __emit;
function handleInput(event: Event) {
  let value = (event.target as HTMLInputElement).value;
  if (props.isPhoneNumber) {
    value = applyPhoneMask(value);
  }
  emit("update:modelValue", value);
}

const __returned__ = { props, disabledClass, hasErros, emit, handleInput }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})