import { defineComponent as _defineComponent } from 'vue'
import { computed, onUnmounted, ref, Ref, watch } from "vue";
import { UiPageHeader } from "@/components";
import {
  UsersStatusDoughnutChart,
  CallQueueGaugeChart,
  QueueDetailsTable,
  UsersExtensionsDetailsTable,
  DataTotalize,
} from "@/views/pages/reports/realtime/components";
import RealtimeReportService from "@/services/RealtimeReportService";
import { toastServiceError } from "@/utils/notification";
import { FilterGetters, IDataTotalize, ILevel } from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'RealtimeReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const dataTotalize: Ref<IDataTotalize> = ref({
  callQueue: 0,
  callInboundInProgress: undefined,
  callOutboundInProgress: undefined,
  userOffline: undefined,
  userOnline: undefined,
  averageQuality: undefined,
});

const loading = ref(true);

const getData = () => {
  loading.value = true;

  RealtimeReportService.getDataTotalize<IDataTotalize>()
    .then((response) => {
      dataTotalize.value.callQueue = response.data.callQueue;
      dataTotalize.value.callInboundInProgress =
        response.data.callInboundInProgress;
      dataTotalize.value.callOutboundInProgress =
        response.data.callOutboundInProgress;
      dataTotalize.value.userOffline = response.data.userOffline;
      dataTotalize.value.userOnline = response.data.userOnline;
      dataTotalize.value.averageQuality = response.data.averageQuality;
    }, toastServiceError)
    .finally(() => (loading.value = false));
};

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch(level, () => {
  dataTotalize.value.callQueue = 0;
  dataTotalize.value.callInboundInProgress = undefined;
  dataTotalize.value.callOutboundInProgress = undefined;
  dataTotalize.value.userOffline = undefined;
  dataTotalize.value.userOnline = undefined;
  dataTotalize.value.averageQuality = undefined;
  clearInterval(interval);
  interval = setInterval(getData, 5000);
});

let interval = setInterval(getData, 5000);
onUnmounted(() => {
  clearInterval(interval);
});

const __returned__ = { dataTotalize, loading, getData, level, get interval() { return interval }, set interval(v) { interval = v }, get UiPageHeader() { return UiPageHeader }, get UsersStatusDoughnutChart() { return UsersStatusDoughnutChart }, get CallQueueGaugeChart() { return CallQueueGaugeChart }, get QueueDetailsTable() { return QueueDetailsTable }, get UsersExtensionsDetailsTable() { return UsersExtensionsDetailsTable }, get DataTotalize() { return DataTotalize } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})