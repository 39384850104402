import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IAgentProductivityPerDayReportFilter,
  AgentProductivityPerDayReportGetters,
  AgentProductivityPerDayReportActions,
  IUser,
  ILevel,
  FilterGetters,
  IUserLabel,
} from "@/definitions";
import { UserService, UserLabelService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentProductivityPerDayReportFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filterOpened = ref(false);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const defaultFilter = computed(
  () => store.getters[AgentProductivityPerDayReportGetters.DEFAULT_FILTER],
);
const filter: Ref<IAgentProductivityPerDayReportFilter> = ref({
  user: defaultFilter.value.user,
  userLabel: defaultFilter.value.userLabel,
});
const storeFilter = computed(
  () => store.getters[AgentProductivityPerDayReportGetters.FILTER],
);

const userOptions: Ref<IUser[]> = ref([]);
const getUsers = () => {
  if (level.value)
    UserService.getAll<IUser[]>({
      params: {
        isAgent: true,
        page: 1,
        per_page: 100,
        level: level.value.id,
      },
    }).then((response) => (userOptions.value = response.data));
};

const userLabelOptions: Ref<IUserLabel[]> = ref([]);
const getUserLabels = () => {
  if (level.value)
    UserLabelService.getAll<IUserLabel[]>({
      params: {
        level: level.value.id,
      },
    }).then((response) => (userLabelOptions.value = response.data));
};

function applyFilters() {
  store.dispatch(AgentProductivityPerDayReportActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(AgentProductivityPerDayReportActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.user = storeFilter.value.user;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

watch(level, () => {
  store.dispatch(AgentProductivityPerDayReportActions.CLEAR_FILTER, "user");
  getUsers();
  getUserLabels();
});

onMounted(() => {
  getUsers();
  getUserLabels();
});

const __returned__ = { t, filterOpened, level, defaultFilter, filter, storeFilter, userOptions, getUsers, userLabelOptions, getUserLabels, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiRightPanel() { return UiRightPanel }, get UiMultiselect() { return UiMultiselect }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})