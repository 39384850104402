import { defineComponent as _defineComponent } from 'vue'
import { PropType } from "vue";
import { ICall, ICallTimelineItem } from "@/definitions";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/solid";
import CallTimelineItem from "@/views/pages/call/components/CallTimelineItem.vue";
import moment from "moment";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallTimeline',
  props: {
  call: {
    type: Object as PropType<ICall>,
    required: true,
  },
  timeline: {
    type: Array as () => Array<ICallTimelineItem>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { get CheckCircleIcon() { return CheckCircleIcon }, get XCircleIcon() { return XCircleIcon }, CallTimelineItem, get moment() { return moment } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})