import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, reactive, Ref } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import router from "@/router";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import {
  required,
  maxLength,
  minLength,
  containsUppercase,
  containsLowercase,
  containsNumber,
  containsSpecial,
  sameAs,
} from "@/utils/validators";
import { toastServiceError } from "@/utils/notification";
import { UiTextInput, UiButton } from "@/components";
import {
  UiActions,
  IUpdatePasswordFormState,
  IUser,
  AuthGetters,
} from "@/definitions";
import { useI18n } from "vue-i18n";
import { UserService } from "@/services";

const imageLoginPath = "/img/slogan-login.png";
const logoPath = "/img/logomob.png";


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdatePasswordPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();
const route = useRoute();

const formState = reactive<IUpdatePasswordFormState>({
  password: "",
  confirmPassword: "",
});

const token = route.query.token as string | undefined;

const rules = computed<ValidationArgs<IUpdatePasswordFormState>>(() => ({
  password: {
    required,
    minLength: minLength(8),
    maxLength: maxLength(250),
    containsUppercase,
    containsLowercase,
    containsNumber,
    containsSpecial,
  },
  confirmPassword: {
    required,
    sameAsPassword: sameAs(formState.password, t("user.labels.password")),
  },
}));

const v$: Ref<Validation<ValidationArgs<IUpdatePasswordFormState>>> =
  useVuelidate(rules, formState);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const onSubmit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  if (token) {
    UserService.resetPassword({ token, password: formState.password })
      .then(() => {
        router.push("/");
      })
      .catch((error) => {
        toastServiceError(error);
        router.push({ name: "Login" });
      })
      .finally(() => setLoading(false));
  } else {
    UserService.firstLogin(formState)
      .then(() => {
        router.push("/");
      }, toastServiceError)
      .finally(() => setLoading(false));
  }
};

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);
onMounted(() => {
  if (user?.value?.lastLogin) {
    router.push({ name: "Home" });
  }
});

const __returned__ = { imageLoginPath, logoPath, t, route, formState, token, rules, v$, setLoading, onSubmit, user, get UiTextInput() { return UiTextInput }, get UiButton() { return UiButton } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})