import { defineComponent as _defineComponent } from 'vue'
import { StepEdge, EdgeText, getSmoothStepPath } from "@vue-flow/core";
import { computed, ComputedRef } from "vue";
import { ConnectFlowModelingGetters, IFlowConnection } from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'AwaitableEdge',
  props: {
  id: {
    type: String,
    required: true,
  },
  sourceX: {
    type: Number,
    required: true,
  },
  sourceY: {
    type: Number,
    required: true,
  },
  targetX: {
    type: Number,
    required: true,
  },
  targetY: {
    type: Number,
    required: true,
  },
  sourcePosition: {
    type: String,
    required: true,
  },
  targetPosition: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: false,
  },
  markerEnd: {
    type: String,
    required: false,
  },
  style: {
    type: Object,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;



const path = computed(() => getSmoothStepPath(props));

const edge: ComputedRef<IFlowConnection> = computed(() =>
  store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](props.id),
);

const __returned__ = { props, path, edge, get StepEdge() { return StepEdge }, get EdgeText() { return EdgeText } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})