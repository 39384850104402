import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { AxiosResponse } from "axios";
import { goBack } from "@/utils/navigation";
import { useRoute, RouterLink } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import { Permission, UiActions, IWord } from "@/definitions";
import { UiButton, UiPageHeader, UiContainerSection } from "@/components";
import { WordService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const word: Ref<IWord | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getWord = (id: number) => {
  setLoading(true);
  WordService.get<IWord>(id)
    .then((response: AxiosResponse<IWord>) => {
      word.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getWord(parseInt(route.params.id as string));

const __returned__ = { route, word, setLoading, getWord, get goBack() { return goBack }, get RouterLink() { return RouterLink }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiContainerSection() { return UiContainerSection } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})