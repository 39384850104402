import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef } from "vue";
import { TagIcon } from "@heroicons/vue/solid";
import { CallPlayerGetters, ICall } from "@/definitions";
import { UiPanel, UiBadge } from "@/components";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallTags',
  setup(__props, { expose: __expose }) {
  __expose();

const call: ComputedRef<ICall> = computed(
  () => store.getters[CallPlayerGetters.CALL],
);

const __returned__ = { call, get TagIcon() { return TagIcon }, get UiPanel() { return UiPanel }, get UiBadge() { return UiBadge } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})