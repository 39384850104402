import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import ScheduleService from "@/services/ScheduleService";
import { LevelService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiTimePicker,
  UiMultiselect,
} from "@/components";
import {
  ISchedule,
  Permission,
  UiActions,
  IDefaultServiceResult,
  IScheduleServiceCreateResult,
  FormAction,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";

import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ScheduleFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const route = useRoute();

const props = __props;

const formState = reactive<ISchedule>({
  id: 0,
  name: "",
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
  timeBegin: "",
  timeEnd: "",
  isActive: true,
  levelId: 0,
});

const dayList = () => {
  return (
    formState.monday ||
    formState.tuesday ||
    formState.wednesday ||
    formState.thursday ||
    formState.friday ||
    formState.saturday ||
    formState.sunday
  );
};

const rules = computed(() => ({
  id: {},
  name: {
    required,
    maxLength: maxLength(500),
  },
  levelId: {
    required,
  },
  monday: {
    required,
  },
  tuesday: {
    required,
  },
  wednesday: {
    required,
  },
  thursday: {
    required,
  },
  friday: {
    required,
  },
  saturday: {
    required,
  },
  sunday: {
    required,
  },
  isActive: {
    required,
  },
  timeBegin: {
    required,
  },
  timeEnd: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<ISchedule>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

const getSchedule = (id: number) => {
  setLoading(true);
  ScheduleService.get<ISchedule>(id)
    .then((response: AxiosResponse<ISchedule>) => {
      const {
        levelId,
        name,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        timeBegin,
        timeEnd,
        isActive,
      } = response.data;
      formState.id = id;
      formState.levelId = levelId;
      formState.name = name;
      formState.isActive = isActive;
      formState.monday = monday;
      formState.tuesday = tuesday;
      formState.wednesday = wednesday;
      formState.thursday = thursday;
      formState.friday = friday;
      formState.saturday = saturday;
      formState.sunday = sunday;
      formState.timeBegin = timeBegin;
      formState.timeEnd = timeEnd;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getSchedule(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  if (!dayList()) {
    toast.error(t("schedule.error.dayNotSelected"));
    return;
  }

  const { timeBegin, timeEnd } = formState;

  if (!isTimeBefore(timeBegin, timeEnd)) {
    toast.error(t("schedule.error.invalidTimeRange"));
    return;
  }

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewScheduleById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    ScheduleService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    ScheduleService.create<IScheduleServiceCreateResult>(formState)
      .then(
        (response: AxiosResponse<IScheduleServiceCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

function isTimeBefore(initialHour: string, finalHour: string) {
  const parseTime = (time: string) => time.split(":").map(Number);

  const [hours1, minutes1] = parseTime(initialHour);
  const [hours2, minutes2] = parseTime(finalHour);

  return hours1 < hours2 || (hours1 === hours2 && minutes1 < minutes2);
}

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewScheduleById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "ListSchedule",
    });
};

const __returned__ = { t, route, props, formState, dayList, rules, v$, setLoading, levelFilter, levels, getLevels, getSchedule, onSubmit, isTimeBefore, handleBackClick, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get UiTimePicker() { return UiTimePicker }, get UiMultiselect() { return UiMultiselect }, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})