import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { toast, toastServiceError } from "@/utils/notification";
import UserService from "@/services/UserService";
import moment from "moment";
import { useI18n } from "vue-i18n";
import {
  UiPageHeader,
  UiListSearch,
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
} from "@/components";
import {
  UserListItem,
  UserListSort,
  UserListFilters,
} from "@/views/pages/user/components";
import {
  IUser,
  Permission,
  IUserServiceGetAllParams,
  UserGetters,
  UserActions,
  IUserFilter,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
  IFilterItem,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { UsersIcon, DocumentDownloadIcon } from "@heroicons/vue/solid";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const users: Ref<IUser[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[UserGetters.SEARCH],
  set: (search: string) => store.dispatch(UserActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[UserGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(UserActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[UserGetters.PAGE],
  set: (page: number) => store.dispatch(UserActions.SET_PAGE, page),
});

const filter: IUserFilter = store.getters[UserGetters.FILTER];

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[UserGetters.ACTIVE_FILTERS],
);

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getParams = (): IUserServiceGetAllParams => {
  return {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active:
      filter.isActive !== null ? (filter.isActive.value as boolean) : undefined,
    roles: filter.roles.map((r) => r.id),
    search: search.value,
  };
};

const getUsers = () => {
  const params = getParams();

  setLoading(true);
  UserService.getAll<IUser[]>({
    params,
  })
    .then((response: AxiosResponse<IUser[]>) => {
      users.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => setLoading(false));
};

const getCsv = () => {
  const params = getParams();

  setLoading(true);
  UserService.getCsvReport<Array<IUser>>({
    params,
  })
    .then((response) => {
      const dateNow = moment(Date.now()).format("DD/MM/YYYY HH:mm:ss");
      const filename = `${t("dashboard.report.users")} - ${dateNow}.csv`;

      const csvString =
        typeof response.data === "string"
          ? response.data
          : JSON.stringify(response.data);
      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8;",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

watch([level, search, filter, orderBy], () => {
  if (currentPage.value === 1) getUsers();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getUsers();
});

getUsers();

const setIsActive = async (item: IUser, isActive: boolean) => {
  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getUsers();
  };

  (isActive ? UserService.activate : UserService.inactivate)(item.id)
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) =>
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);

const handleRemoveStatusFilterClick = (field: string) => {
  store.dispatch(UserActions.CLEAR_FILTER, field);
};

const handleActivateClick = (item: IUser) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: IUser) => {
  setIsActive(item, false);
};

const __returned__ = { route, router, t, setLoading, users, search, sortMenuSelected, level, orderBy, currentPage, filter, activeFilters, count, perPage, getParams, getUsers, getCsv, setIsActive, handleRemoveSortClick, handleRemoveStatusFilterClick, handleActivateClick, handleInactivateClick, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get UiButton() { return UiButton }, get UiPagination() { return UiPagination }, get UiBadge() { return UiBadge }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UserListItem() { return UserListItem }, get UserListSort() { return UserListSort }, get UserListFilters() { return UserListFilters }, get Permission() { return Permission }, get UsersIcon() { return UsersIcon }, get DocumentDownloadIcon() { return DocumentDownloadIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})