import { defineComponent as _defineComponent } from 'vue'
import { UiPageHeader, UiSpinner } from "@/components";
import { IUserDetails } from "@/definitions/chart-typings/Realtime";
import { computed, onMounted, onUnmounted, ref, Ref, watch } from "vue";
import RealtimeReportService from "@/services/RealtimeReportService";
import { toastServiceError } from "@/utils/notification";
import { applyPhoneMask } from "@/utils/number";
import { UserIcon, DesktopComputerIcon } from "@heroicons/vue/solid";
import { DeviceMobileIcon } from "@heroicons/vue/outline";
import SVGDeviceExternal from "@/components/svg/SVGDeviceExternal.vue";
import SVGChromeLogo from "@/components/svg/SVGChromeLogo.vue";
import {
  FilterGetters,
  ILevel,
  UiActions,
  UserDevice,
  UserDeviceStatus,
} from "@/definitions";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentExtensionReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const loading = ref(true);

const items: Ref<IUserDetails[]> = ref([]);

const getData = () => {
  loading.value = true;
  RealtimeReportService.getAgentExtensions<IUserDetails[]>()
    .then((response) => {
      items.value = response.data;
    }, toastServiceError)
    .finally(() => (loading.value = false));
  setLoading(false);
};

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch(level, () => {
  items.value = [];
  clearInterval(interval);
  interval = setInterval(getData, 5000);
});

let interval = setInterval(getData, 5000);
onMounted(() => {
  setLoading(true);
});
onUnmounted(() => {
  clearInterval(interval);
});

const __returned__ = { loading, items, getData, setLoading, level, get interval() { return interval }, set interval(v) { interval = v }, get UiPageHeader() { return UiPageHeader }, get UiSpinner() { return UiSpinner }, get applyPhoneMask() { return applyPhoneMask }, get UserIcon() { return UserIcon }, get DesktopComputerIcon() { return DesktopComputerIcon }, get DeviceMobileIcon() { return DeviceMobileIcon }, SVGDeviceExternal, SVGChromeLogo, get UserDevice() { return UserDevice }, get UserDeviceStatus() { return UserDeviceStatus } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})