import { defineComponent as _defineComponent } from 'vue'
import {
  PropType,
  computed,
  WritableComputedRef,
  ref,
  Ref,
  onMounted,
} from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IFlowNode,
  IMultiSelectOption,
  ISchedule,
} from "@/definitions";
import store from "@/store";
import { ClockIcon } from "@heroicons/vue/outline";
import { UiRightPanel, UiNode, UiButton, UiMultiselect } from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@/utils/validators";

interface ITimeFormState {
  schedule: null | ISchedule;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeValidation',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const scheduleOptions = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.SCHEDULE_OPTIONS],
);
const formState: Ref<ITimeFormState> = ref({
  schedule: null,
});
const rules = computed<ValidationArgs<ITimeFormState>>(() => {
  const r: ValidationArgs<ITimeFormState> = {
    schedule: {
      required,
    },
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<ITimeFormState>>> = useVuelidate(
  rules,
  formState,
);

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    nodeWritable.value.data.related = {
      value: formState.value.schedule?.id,
      label: formState.value.schedule?.name,
    } as IMultiSelectOption;
    showForm.value = false;
  }
  nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  v$.value.$reset();
}

onMounted(() => {
  if (nodeWritable.value.data.related !== null) {
    formState.value.schedule = scheduleOptions.value.find(
      (schedule: ISchedule) =>
        schedule.id == nodeWritable.value.data.related?.value,
    );
  }

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

const __returned__ = { props, showForm, nodeWritable, scheduleOptions, formState, rules, v$, isFormCorrect, removeNode, onSubmit, get ClockIcon() { return ClockIcon }, get UiRightPanel() { return UiRightPanel }, get UiNode() { return UiNode }, get UiButton() { return UiButton }, get UiMultiselect() { return UiMultiselect } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})