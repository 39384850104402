import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, Ref, ref, watch } from "vue";
import store from "@/store";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IMultiSelectOption,
  INumberFilter,
  NumberGetters,
  NumberActions,
  ICityOption,
} from "@/definitions";
import NumberService from "@/services/NumberService";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const filterOpened = ref(false);

const defaultFilter = computed(
  () => store.getters[NumberGetters.DEFAULT_FILTER],
);
const filter: Ref<INumberFilter> = ref({
  hasConnectFlow: defaultFilter.value.level,
  level: defaultFilter.value.level,
  state: defaultFilter.value.state,
  city: defaultFilter.value.city,
});
const storeFilter = computed(() => store.getters[NumberGetters.FILTER]);

const stateOptions: Ref<IMultiSelectOption[]> = ref([]);
const getLevelsStates = () => {
  NumberService.getStates<IMultiSelectOption[]>().then(
    (response) => (stateOptions.value = response.data),
  );
};

const cityOptions: Ref<ICityOption[]> = ref([]);
const getLevelsCities = () => {
  NumberService.getCities<ICityOption[]>().then(
    (response) => (cityOptions.value = response.data),
  );
};
const cityOptionsScoped = computed(() => {
  if (filter.value.state !== null && cityOptions.value.length) {
    return cityOptions.value.filter((city) => {
      if (city.state !== undefined) {
        return city.state === filter.value.state?.value;
      }
    });
  }
  return cityOptions.value;
});

const { t } = useI18n();
const hasConnectFlowFilterOptions: Ref<IMultiSelectOption[]> = ref([
  {
    label: t("number.filters.withConnectFlow"),
    value: true,
  } as IMultiSelectOption,
  {
    label: t("number.filters.withoutConnectFlow"),
    value: false,
  } as IMultiSelectOption,
]);

function applyFilters() {
  store.dispatch(NumberActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(NumberActions.CLEAR_FILTER);
  refreshLocalFilter();
}

onMounted(() => {
  getLevelsStates();
  getLevelsCities();
});
function refreshLocalFilter() {
  filter.value.hasConnectFlow = storeFilter.value.hasConnectFlow;
  filter.value.state = storeFilter.value.state;
  filter.value.city = storeFilter.value.city;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

const __returned__ = { filterOpened, defaultFilter, filter, storeFilter, stateOptions, getLevelsStates, cityOptions, getLevelsCities, cityOptionsScoped, t, hasConnectFlowFilterOptions, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiRightPanel() { return UiRightPanel }, get UiMultiselect() { return UiMultiselect }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})