import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import { IUserLabel, Permission } from "@/definitions";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { CalendarIcon, FolderIcon, UsersIcon } from "@heroicons/vue/solid";
import { UiButton } from "@/components";
import {
  CheckCircleIcon,
  PencilAltIcon,
  XCircleIcon,
} from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelListItem',
  props: {
  modelValue: {
    type: Object as PropType<IUserLabel>,
    required: true,
  },
},
  emits: ["activate", "inactivate"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const handleActivateClick = () => {
  emit("activate", props.modelValue);
};

const handleInactivateClick = () => {
  emit("inactivate", props.modelValue);
};

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

const __returned__ = { props, emit, handleActivateClick, handleInactivateClick, lastModifiedBy, get Permission() { return Permission }, get formatDateAsString() { return formatDateAsString }, get CalendarIcon() { return CalendarIcon }, get FolderIcon() { return FolderIcon }, get UsersIcon() { return UsersIcon }, get UiButton() { return UiButton }, get CheckCircleIcon() { return CheckCircleIcon }, get PencilAltIcon() { return PencilAltIcon }, get XCircleIcon() { return XCircleIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})