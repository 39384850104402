import { defineComponent as _defineComponent } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  ChevronDownIcon,
  SwitchVerticalIcon,
} from "@heroicons/vue/solid";
import { ISortMenuItem, ISortMenuItemDirection } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSortMenu',
  props: {
  modelValue: {
    type: Array<ISortMenuItem>,
    default: [],
  },
  label: {
    type: String,
    required: true,
    default: "",
  },
  items: {
    type: Array<ISortMenuItem>,
    required: true,
    default: [],
  },
  fixmobile: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const handleItemClick = (
  item: ISortMenuItem,
  direction?: ISortMenuItemDirection,
) => {
  const currentItems = Object.assign([] as ISortMenuItem[], props.modelValue);

  const currentItem = currentItems.find((cItem) => cItem.key == item.key);

  if (currentItem) {
    if (currentItem.direction == direction) {
      currentItems.splice(currentItems.indexOf(currentItem), 1);
    } else if (direction) {
      currentItem.direction = direction;
    } else
      currentItem.direction =
        currentItem.direction == ISortMenuItemDirection.DESC
          ? ISortMenuItemDirection.ASC
          : ISortMenuItemDirection.DESC;
  } else {
    currentItems.push(
      Object.assign(
        { direction: direction || ISortMenuItemDirection.ASC } as ISortMenuItem,
        item,
      ),
    );
  }

  emit("update:modelValue", currentItems);
};

const __returned__ = { props, emit, handleItemClick, get Menu() { return Menu }, get MenuButton() { return MenuButton }, get MenuItem() { return MenuItem }, get MenuItems() { return MenuItems }, get ArrowCircleDownIcon() { return ArrowCircleDownIcon }, get ArrowCircleUpIcon() { return ArrowCircleUpIcon }, get ChevronDownIcon() { return ChevronDownIcon }, get SwitchVerticalIcon() { return SwitchVerticalIcon }, get ISortMenuItemDirection() { return ISortMenuItemDirection } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})