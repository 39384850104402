import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "space-y-6" }
const _hoisted_5 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_6 = { class: "font-semibold mb-1" }
const _hoisted_7 = { class: "font-semibold mb-1" }
const _hoisted_8 = { class: "font-semibold mb-1" }
const _hoisted_9 = { class: "font-semibold mb-1" }
const _hoisted_10 = { class: "font-semibold mb-1" }
const _hoisted_11 = { class: "font-semibold mb-1" }
const _hoisted_12 = { class: "text-base font-semibold leading-6 text-gray-800 mb-4" }
const _hoisted_13 = { class: "grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-6 relative" }
const _hoisted_14 = {
  key: 0,
  class: "flex flex-col justify-between bg-gray-50 text-sm px-4 py-3 absolute rounded bottom-0 mt-6 -right-[215px] shadow text-gray-600 border-r-4 border-b-4 border-cyan-600"
}
const _hoisted_15 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-6 gap-4 relative mt-5"
}
const _hoisted_16 = { class: "text-base font-semibold leading-6 text-gray-800" }
const _hoisted_17 = { class: "text-base font-semibold leading-6 text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("user.view.title")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'ListUser' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.$can($setup.Permission.EDIT_USER))
            ? (_openBlock(), _createBlock($setup["RouterLink"], {
                key: 0,
                to: {
              name: 'EditUserById',
              params: {
                id: $setup.route.params.id,
              },
            },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["UiButton"], {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("user.labels.name")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.user?.name), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("user.labels.email")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.user?.email), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t("user.labels.status")), 1 /* TEXT */),
              _createElementVNode("p", {
                class: _normalizeClass([[
                  $setup.user?.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800',
                ], "inline-flex rounded-full px-2 text-sm font-semibold leading-5"])
              }, _toDisplayString($setup.user?.isActive
                    ? _ctx.$t("core.options.Active")
                    : _ctx.$t("core.options.Inactive")), 3 /* TEXT, CLASS */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("user.labels.levels")), 1 /* TEXT */),
              _createElementVNode("p", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.user?.levels, (level) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: `level${level.id}`,
                    class: "mr-3 inline-flex rounded-full px-2 text-sm font-semibold leading-5 bg-gray-100 text-gray-700"
                  }, _toDisplayString(level.name), 1 /* TEXT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("user.labels.standardLevel")), 1 /* TEXT */),
              _createElementVNode("p", null, _toDisplayString($setup.getLevelName($setup.user?.standardLevelId)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t("user.labels.roles")), 1 /* TEXT */),
              _createElementVNode("p", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.user?.roles, (roles) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: `roles${roles.id}`,
                    class: "mr-3 inline-flex rounded-full px-2 text-sm font-semibold leading-5 bg-gray-100 text-gray-700"
                  }, _toDisplayString(roles.name), 1 /* TEXT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t("user.labels.devices.title")), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", {
              class: _normalizeClass([
                $setup.user?.devices?.mobile
                  ? 'bg-cyan-50 text-cyan-600'
                  : 'bg-gray-50 text-gray-400',
                'rounded shadow ring-1 ring-black ring-opacity-5 relative w-40 h-40 flex flex-col items-center',
              ])
            }, [
              _createVNode($setup["DeviceMobileIcon"], { class: "h-20 w-20 mt-6" }),
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-medium" }, "Mobile", -1 /* HOISTED */))
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass([
                $setup.user?.devices?.desktop
                  ? 'bg-cyan-50 text-cyan-600'
                  : 'bg-gray-50 text-gray-400',
                'rounded shadow ring-1 ring-black ring-opacity-5 relative w-40 h-40 flex flex-col items-center',
              ])
            }, [
              _createVNode($setup["DesktopComputerIcon"], { class: "h-20 w-20 mt-6" }),
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-medium" }, "Desktop", -1 /* HOISTED */))
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass([
                $setup.user?.devices?.web
                  ? 'bg-cyan-50 text-cyan-600'
                  : 'bg-gray-50 text-gray-400',
                'rounded shadow ring-1 ring-black ring-opacity-5 relative w-40 h-40 flex flex-col items-center',
              ])
            }, [
              _createVNode($setup["SVGChromeLogo"], { class: "h-20 w-20 mt-6" }),
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "font-medium" }, "Web", -1 /* HOISTED */))
            ], 2 /* CLASS */),
            _createElementVNode("div", {
              class: _normalizeClass([
                $setup.user?.devices?.external
                  ? 'bg-cyan-50 text-cyan-600'
                  : 'bg-gray-50 text-gray-400',
                'rounded shadow ring-1 ring-black ring-opacity-5 w-40 h-40 flex flex-col items-center relative',
              ])
            }, [
              _createVNode($setup["SVGDeviceExternal"], { class: "h-20 w-20 mt-6" }),
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "font-medium" }, "Externo", -1 /* HOISTED */)),
              ($setup.user?.deviceExternal)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "font-semibold text-base mb-1" }, " Configurações do PABX ", -1 /* HOISTED */)),
                    _createElementVNode("p", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-semibold" }, "Hostname:", -1 /* HOISTED */)),
                      _createTextVNode(" " + _toDisplayString($setup.user?.deviceExternal.hostname), 1 /* TEXT */)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-semibold" }, "Username:", -1 /* HOISTED */)),
                      _createTextVNode(" " + _toDisplayString($setup.user?.deviceExternal.sipUser), 1 /* TEXT */)
                    ]),
                    _createElementVNode("p", null, [
                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "font-semibold" }, "Password:", -1 /* HOISTED */)),
                      _createTextVNode(" " + _toDisplayString($setup.user?.deviceExternal.sipPassword), 1 /* TEXT */)
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */)
          ]),
          ($setup.user?.devices)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t("user.labels.extension.number")), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString($setup.user?.extension?.number), 1 /* TEXT */)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("user.labels.number")), 1 /* TEXT */),
                  _createElementVNode("pre", null, _toDisplayString($setup.getBinaNumber($setup.user?.standardLevelId, $setup.user?.number?.numberId)), 1 /* TEXT */)
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}