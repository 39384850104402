import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { AxiosResponse } from "axios";
import { toastServiceError } from "@/utils/notification";
import { UiButton, UiMultiselect, UiRightPanel } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  CallActions,
  CallGetters,
  CallStatus,
  CallType,
  FilterGetters,
  ICallFilter,
  ILevel,
  IMultiSelectOption,
  ISelectOptionsServiceGetWordsParams,
  ParticipantType,
  UiActions,
} from "@/definitions";
import { CallService, SelectOptionsService } from "@/services";
import { useRoute } from "vue-router";
import {
  ISelectOptionsServiceGetUsersResult,
  ISelectOptionsServiceGetUsersLabelsResult,
  ISelectOptionsServiceGetWordsResult,
} from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();
const route = useRoute();

const filtersOpened = ref(false);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const words: Ref<ISelectOptionsServiceGetWordsResult[]> = ref([]);

const getWords = (participant: string) => {
  const params: ISelectOptionsServiceGetWordsParams = {
    level: level.value.id,
    call_participant: participant,
  };

  setLoading(true);
  SelectOptionsService.getWords({
    params,
  })
    .then((response) => {
      words.value = response.data;
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

const filter: Ref<ICallFilter> = ref({
  type: null,
  status: null,
  userLabel: null,
  media: null,
  numberClient: null,
  user: null,
  word: null,
});

const selectedParticipant = ref<ParticipantType | null>(null);

const callParticipantOptions = [
  { label: t("word.participant.ALL"), value: ParticipantType.All },
  { label: t("word.participant.AGENT"), value: ParticipantType.Agent },
  { label: t("word.participant.CLIENT"), value: ParticipantType.Client },
];

const filterCallParticipants = (value: ParticipantType | null) => {
  selectedParticipant.value = value || ParticipantType.All;
  filter.value.word = null;
  getWords(value || ParticipantType.All);
};

const getButtonVariant = (value: ParticipantType | null) => {
  if (selectedParticipant.value === value) {
    if (value === ParticipantType.Agent) {
      return "purple-200";
    }
    if (value === ParticipantType.Client) {
      return "blue-200";
    }
    if (value === ParticipantType.All) {
      return "gray-300";
    }
  }
  return "neutral";
};

const getTextVariant = (value: ParticipantType | null) => {
  if (selectedParticipant.value === value) {
    if (value === ParticipantType.Agent) {
      return "purple-700";
    }
    if (value === ParticipantType.Client) {
      return "blue-700";
    }
    if (value === ParticipantType.All) {
      return "gray-700";
    }
  }
  return "gray-700";
};

const storeFilters = computed<ICallFilter>(
  () => store.getters[CallGetters.FILTER],
);

function applyFilters() {
  store.dispatch(CallActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(CallActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.type = storeFilters.value.type;
  filter.value.status = storeFilters.value.status;
  filter.value.userLabel = storeFilters.value.userLabel;
  filter.value.media = storeFilters.value.media;
  filter.value.numberClient = storeFilters.value.numberClient;
  filter.value.user = storeFilters.value.user;
  filter.value.word = storeFilters.value.word;
}

watch(filtersOpened, () => {
  refreshLocalFilter();
});

watch(
  () => filter.value.word?.callParticipant,
  (newParticipant) => {
    if (newParticipant !== undefined && newParticipant !== null) {
      getWords(newParticipant);
    }
  },
  { immediate: true },
);

const statusFilterOptions = [
  {
    label: t(`call.status.${CallStatus.ANSWERED}`),
    value: CallStatus.ANSWERED,
  },
  {
    label: t(`call.status.${CallStatus.BUSY}`),
    value: CallStatus.BUSY,
  },
  {
    label: t(`call.status.${CallStatus.NO_ANSWER}`),
    value: CallStatus.NO_ANSWER,
  },
  {
    label: t(`call.status.${CallStatus.DITCHED}`),
    value: CallStatus.DITCHED,
  },
];

const typeFilterOptions = [
  {
    label: t(`call.type.${CallType.INBOUND}`),
    value: CallType.INBOUND,
  },
  {
    label: t(`call.type.${CallType.OUTBOUND}`),
    value: CallType.OUTBOUND,
  },
  {
    label: t(`call.type.${CallType.INTERNAL}`),
    value: CallType.INTERNAL,
  },
];

const userLabelOptions: Ref<ISelectOptionsServiceGetUsersLabelsResult[]> = ref(
  [],
);
const getUserLabels = () => {
  if (level.value) {
    SelectOptionsService.getUsersLabels({
      params: {
        level: level.value.id,
      },
    }).then((response) => (userLabelOptions.value = response.data));
  }
};

const agentOptions: Ref<ISelectOptionsServiceGetUsersResult[]> = ref([]);

const getAgents = () => {
  SelectOptionsService.getUsers({
    params: {
      level: level.value.id,
    },
  }).then((response) => {
    agentOptions.value = response.data;
  }, toastServiceError);
};

const mediaFilterOptions: Ref<IMultiSelectOption[]> = ref([]);
function getMedias() {
  if (level.value) {
    CallService.getMedias<IMultiSelectOption[]>({
      params: {
        level: level.value.id,
      },
    }).then((response: AxiosResponse<IMultiSelectOption[]>) => {
      mediaFilterOptions.value = response.data;
    }, toastServiceError);
  }
}

const isCallTypeRoute = computed(() => {
  return Object.values(CallType)
    .map((value) => value.toLowerCase())
    .some((callTypeValue) => route.path.toLowerCase().includes(callTypeValue));
});

const isCallStatusRoute = computed(() => {
  return Object.values(CallStatus)
    .map((value) => value.toLowerCase())
    .some((callStatusValue) =>
      route.path.toLowerCase().includes(callStatusValue),
    );
});

const getClientNumbers = async (query: string) => {
  const params = {
    search: query,
  };
  const { data } = await CallService.getClientNumbers({ params });
  return data;
};

watch(level, () => {
  store.dispatch(CallActions.CLEAR_FILTER, "userLabel");
  getUserLabels();
});

onMounted(() => {
  getMedias();
  getUserLabels();
  getAgents();
  getWords(ParticipantType.All);
  filterCallParticipants(ParticipantType.All);
});

const __returned__ = { t, route, filtersOpened, level, setLoading, words, getWords, filter, selectedParticipant, callParticipantOptions, filterCallParticipants, getButtonVariant, getTextVariant, storeFilters, applyFilters, clearFilters, refreshLocalFilter, statusFilterOptions, typeFilterOptions, userLabelOptions, getUserLabels, agentOptions, getAgents, mediaFilterOptions, getMedias, isCallTypeRoute, isCallStatusRoute, getClientNumbers, get UiButton() { return UiButton }, get UiMultiselect() { return UiMultiselect }, get UiRightPanel() { return UiRightPanel }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})