import { defineComponent as _defineComponent } from 'vue'
import { computed, ComputedRef } from "vue";
import { ICallSurvey } from "@/definitions";
import { ISurveyQuestionAnswer } from "@/definitions/";
import { AnnotationIcon } from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallSurveyAnswers',
  props: {
  surveyAnswers: {
    type: Object as () => ICallSurvey,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const formattedAnswers: ComputedRef<
  { label: string; value: string; rangeAnswer: string }[]
> = computed(() => {
  return props.surveyAnswers.surveyQuestions.map(
    (answer: ISurveyQuestionAnswer) => ({
      label: String(answer.label),
      value: String(answer.value),
      rangeAnswer: String(answer.rangeAnswer),
    }),
  );
});

const __returned__ = { props, formattedAnswers, get AnnotationIcon() { return AnnotationIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})