import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { toastServiceError } from "@/utils/notification";
import {
  CallReportActions,
  CallReportGetters,
  CallStatus,
  CallType,
  FilterActions,
  FilterGetters,
  ICallReportFilter,
  ICallReportServiceGetInfoParams,
  ICallsStats,
  IDataItem,
  IFilterItem,
  ILevel,
  UiActions,
  Permission,
} from "@/definitions";
import {
  CallMediasDoughnutChart,
  CallReportFilters,
  CallServiceTimeRangesDoughnutChart,
  CallStatusDoughnutChart,
  CallStatusPerDayBarChart,
  CallTimeOfDayHeatmapChart,
  CallTypesPerDayBarChart,
  CallAverageTimePerDayBarChart,
} from "@/views/pages/reports/call/components";
import { UiPageHeader, UiDatePicker, UiBadge } from "@/components";
import moment from "moment";
import CallReportService from "@/services/CallReportService";
import { formatSecToTime } from "@/utils/datetimeCommon";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const callsStats: Ref<ICallsStats | null> = ref(null);

const headerStats = computed<IDataItem<string>[]>(() => {
  if (!callsStats.value) return [];

  const result: IDataItem<string>[] = [
    {
      name: "totalCalls",
      value: callsStats.value.total.toString(),
    },
    {
      name: "inboundCalls",
      value: callsStats.value.type[CallType.INBOUND].toString(),
    },
    {
      name: "outboundCalls",
      value: callsStats.value.type[CallType.OUTBOUND].toString(),
    },
    {
      name: "noAnswer",
      value: callsStats.value.status[CallStatus.NO_ANSWER].toString(),
    },
    {
      name: "busy",
      value: callsStats.value.status[CallStatus.BUSY].toString(),
    },
    // {
    //   name: "averageSilencePercentage",
    //   value: callsStats.value.averageSilencePercentage + "%",
    // },
    {
      name: "averageServiceTime",
      value: moment
        .utc((callsStats.value.averageServiceTime || 0) * 1000)
        .format("HH:mm:ss"),
    },
    {
      name: "averageWaitingTime",
      value: callsStats.value.averageWaitingTime
        ? formatSecToTime(callsStats.value.averageWaitingTime, true)
        : "-",
    },
  ];

  return result;
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});
const filter = computed<ICallReportFilter>(
  () => store.getters[CallReportGetters.FILTER],
);
const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[CallReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: ICallReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    type: filter.value.type?.value || null,
    status: filter.value.status?.value || null,
    user: filter.value.user?.id || null,
    userLabel: filter.value.userLabel?.id || null,
    number: filter.value.number?.number || null,
    serviceTimeRange: filter.value.serviceTimeRange?.value || null,
  };

  setLoading(true);

  CallReportService.getInfo<ICallsStats>({
    params,
  })
    .then((response) => {
      callsStats.value = response.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(CallReportActions.CLEAR_FILTER, filter.field);
}

watch([level, dateRange, activeFilters], () => {
  getReportData();
});

getReportData();

const __returned__ = { callsStats, headerStats, level, dateRange, filter, activeFilters, setLoading, getReportData, handleRemoveFilter, get Permission() { return Permission }, get CallMediasDoughnutChart() { return CallMediasDoughnutChart }, get CallReportFilters() { return CallReportFilters }, get CallServiceTimeRangesDoughnutChart() { return CallServiceTimeRangesDoughnutChart }, get CallStatusDoughnutChart() { return CallStatusDoughnutChart }, get CallStatusPerDayBarChart() { return CallStatusPerDayBarChart }, get CallTimeOfDayHeatmapChart() { return CallTimeOfDayHeatmapChart }, get CallTypesPerDayBarChart() { return CallTypesPerDayBarChart }, get CallAverageTimePerDayBarChart() { return CallAverageTimePerDayBarChart }, get UiPageHeader() { return UiPageHeader }, get UiDatePicker() { return UiDatePicker }, get UiBadge() { return UiBadge } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})