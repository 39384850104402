import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { BarChart } from "@/components";
import {
  CallStatus,
  IDataItem,
  ICallsStatsPerDay,
  CallStatusColor,
} from "@/definitions";

interface ChartData {
  category: Array<string>;
  series: Array<IDataItem<number[]>>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CallStatusPerDayColumnChart',
  props: {
  title: {
    type: String,
    required: true,
  },
  data: {
    type: Array<ICallsStatsPerDay>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const chartData = computed<ChartData>(() => {
  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t("core.options.call.status.answered"),
      color: CallStatusColor[CallStatus.ANSWERED],
    },
    {
      value: [],
      name: t("core.options.call.status.no_answer"),
      color: CallStatusColor[CallStatus.NO_ANSWER],
    },
    {
      value: [],
      name: t("core.options.call.status.busy"),
      color: CallStatusColor[CallStatus.BUSY],
    },
    {
      value: [],
      name: t("core.options.call.status.ditched"),
      color: CallStatusColor[CallStatus.DITCHED],
    },
  ];

  for (let i = 0; i < props.data.length; i++) {
    const day = props.data[i];

    category.push(day.date);

    series[0].value.push(day.status[CallStatus.ANSWERED]);
    series[1].value.push(day.status[CallStatus.NO_ANSWER]);
    series[2].value.push(day.status[CallStatus.BUSY]);
    series[3].value.push(day.status[CallStatus.DITCHED]);
  }

  return {
    category,
    series,
  };
});

const xAxisLabelFormatter = (value: number | string) => {
  return moment.utc(value).format("DD/MM");
};

const __returned__ = { t, props, chartData, xAxisLabelFormatter, get BarChart() { return BarChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})