import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiCheckboxInput',
  props: {
  modelValue: {
    type: Boolean,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;
const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const __returned__ = { props, emit, model }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})