import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { SurveyService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
} from "@/components";
import {
  SurveyListItem,
  SurveyListSort,
  SurveyListFilters,
} from "@/views/pages/survey/components";
import {
  ISurvey,
  Permission,
  SurveyGetters,
  SurveyActions,
  ISurveyFilter,
  ISurveyServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { MenuAlt2Icon } from "@heroicons/vue/solid";

const perPage = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const surveys: Ref<ISurvey[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[SurveyGetters.SEARCH],
  set: (search: string) => store.dispatch(SurveyActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[SurveyGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(SurveyActions.SET_SORT, sort),
});

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[SurveyGetters.PAGE],
  set: (page: number) => store.dispatch(SurveyActions.SET_PAGE, page),
});

const filter: ISurveyFilter = store.getters[SurveyGetters.FILTER];

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getSurveys = () => {
  const params: ISurveyServiceGetAllParams = {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active:
      filter.isActive !== null ? (filter.isActive as boolean) : undefined,
    search: search.value,
  };

  setLoading(true);
  SurveyService.getAll<Array<ISurvey>>({
    params,
  })
    .then((response: AxiosResponse<ISurvey[]>) => {
      surveys.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([search, filter], () => {
  if (currentPage.value === 1) getSurveys();
  else currentPage.value = 1;
});

watch([currentPage, orderBy], () => {
  getSurveys();
});

getSurveys();

const setIsActive = async (item: ISurvey, isActive: boolean) => {
  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getSurveys();
  };

  (isActive ? SurveyService.activate : SurveyService.inactivate)(item.id)
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveStatusFilterClick = () => {
  store.dispatch(SurveyActions.CLEAR_FILTER, "isActive");
};

const handleActivateClick = (item: ISurvey) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: ISurvey) => {
  setIsActive(item, false);
};

const __returned__ = { route, router, setLoading, surveys, search, sortMenuSelected, orderBy, currentPage, filter, count, perPage, getSurveys, setIsActive, handleRemoveSortClick, handleRemoveStatusFilterClick, handleActivateClick, handleInactivateClick, get UiButton() { return UiButton }, get UiPagination() { return UiPagination }, get UiBadge() { return UiBadge }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get SurveyListItem() { return SurveyListItem }, get SurveyListSort() { return SurveyListSort }, get SurveyListFilters() { return SurveyListFilters }, get Permission() { return Permission }, get MenuAlt2Icon() { return MenuAlt2Icon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})