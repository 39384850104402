import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch } from "vue";
import store from "@/store";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IMultiSelectOption,
  IUserLabelFilter,
  UserLabelGetters,
  UserLabelActions,
} from "@/definitions";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const filterOpened = ref(false);

const defaultFilter = computed(
  () => store.getters[UserLabelGetters.DEFAULT_FILTER],
);

const filter: Ref<IUserLabelFilter> = ref({
  isActive: defaultFilter.value.isActive,
});

const storeFilter = computed(() => store.getters[UserLabelGetters.FILTER]);

const { t } = useI18n();

const isActiveFilterOptions = [
  {
    label: t("level.filters.isActive.options.actives"),
    value: true,
  },
  {
    label: t("level.filters.isActive.options.inactives"),
    value: false,
  },
] as IMultiSelectOption[];

function applyFilters() {
  store.dispatch(UserLabelActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(UserLabelActions.CLEAR_FILTER);
  refreshLocalFilter();
}
function refreshLocalFilter() {
  filter.value.isActive = storeFilter.value.isActive;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

const __returned__ = { filterOpened, defaultFilter, filter, storeFilter, t, isActiveFilterOptions, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiRightPanel() { return UiRightPanel }, get UiMultiselect() { return UiMultiselect }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})