import { defineComponent as _defineComponent } from 'vue'
import { AnnotationIcon, ArrowRightIcon } from "@heroicons/vue/solid";
import { Permission, IDashboardItem } from "@/definitions";
import {
  UsersIcon,
  UserGroupIcon,
  LockClosedIcon,
  PhoneIcon,
  OfficeBuildingIcon,
  ClockIcon,
  MenuAlt2Icon,
  CloudUploadIcon,
  LinkIcon,
} from "@heroicons/vue/solid";

import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfigurationPage',
  setup(__props, { expose: __expose }) {
  __expose();

const items: IDashboardItem[] = [
  {
    title: "dashboard.configuration.users",
    description: "user.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_USERS,
    routeName: "ListUser",
    icon: UsersIcon,
  },
  {
    title: "dashboard.configuration.userLabel",
    description: "userLabel.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_USER_LABELS,
    routeName: "ListUserLabel",
    icon: UserGroupIcon,
  },
  {
    title: "dashboard.configuration.roles",
    description: "role.description",
    callToAction: "core.actions.goPage",
    permission: Permission.ASSIGN_ROLE_TO_USER,
    routeName: "Permissions",
    icon: LockClosedIcon,
  },
  {
    title: "dashboard.configuration.levels",
    description: "level.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_LEVELS,
    routeName: "ListLevel",
    icon: OfficeBuildingIcon,
  },
  {
    title: "dashboard.configuration.numbers",
    description: "number.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_NUMBERS,
    routeName: "ListNumber",
    icon: PhoneIcon,
  },
  {
    title: "dashboard.configuration.connectFlow",
    description: "connectFlow.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_CONNECT_FLOWS,
    routeName: "ListConnectFlow",
    icon: ConnectFlowIcon,
  },
  {
    title: "dashboard.configuration.schedules",
    description: "schedule.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_SCHEDULES,
    routeName: "ListSchedule",
    icon: ClockIcon,
  },
  {
    title: "dashboard.configuration.queues",
    description: "queue.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_QUEUES,
    routeName: "ListQueue",
    icon: MenuAlt2Icon,
  },
  {
    title: "dashboard.configuration.connectFlowAudios",
    description: "connectFlowAudio.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_CONNECT_FLOW_AUDIOS,
    routeName: "ListConnectFlowAudio",
    icon: CloudUploadIcon,
  },
  {
    title: "dashboard.configuration.surveys",
    description: "survey.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_SURVEYS,
    routeName: "ListSurvey",
    icon: AnnotationIcon,
  },
  {
    title: "dashboard.configuration.integrations",
    description: "integration.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_INTEGRATIONS,
    routeName: "ListIntegration",
    icon: LinkIcon,
  },
  // {
  //   title: "dashboard.configuration.words",
  //   description: "word.description",
  //   callToAction: "core.actions.goPage",
  //   permission: Permission.VIEW_WORDS,
  //   routeName: "ListWord",
  //   icon: "CogIcon",
  // },
  // {
  //   title: "dashboard.configuration.scripts",
  //   description: "Descrição de scripts de replace de replace",
  //   callToAction: "core.actions.goPage",
  //   routeName: "",
  //   icon: "CogIcon",
  // },
];

const __returned__ = { items, get ArrowRightIcon() { return ArrowRightIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})