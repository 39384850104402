import { defineComponent as _defineComponent } from 'vue'
import { DownloadIcon } from "@heroicons/vue/solid";
import { UiButton } from "@/components/index";
import Analytics from "@/utils/analytics";
import axios from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiButtonDownload',
  props: {
  name: {
    type: String,
    required: true,
  },
  fileUrl: {
    type: String,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

Analytics.trackingAction(`${props.name}Download`).then();

async function downloadFile(fileUrl: string | null): Promise<void> {
  if (fileUrl === null) {
    return;
  }
  const response = await axios.get(fileUrl, { responseType: "blob" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(new Blob([response.data]));
  link.click();
  URL.revokeObjectURL(link.href);
}

const __returned__ = { props, downloadFile, get DownloadIcon() { return DownloadIcon }, get UiButton() { return UiButton } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})