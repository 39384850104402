import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import router from "@/router";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { UiButton } from "@/components";
import { CalendarIcon, UsersIcon, PhoneIcon } from "@heroicons/vue/solid";
import {
  CheckCircleIcon,
  XCircleIcon,
  PencilAltIcon,
} from "@heroicons/vue/outline";
import { ILevel, Permission } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelListItem',
  props: {
  modelValue: {
    type: Object as PropType<ILevel>,
    required: true,
  },
},
  emits: ["activate", "inactivate"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

const handleActivateClick = () => {
  emit("activate", props.modelValue);
};

const handleInactivateClick = () => {
  emit("inactivate", props.modelValue);
};

const handleEditClick = () => {
  router.push({
    name: "EditLevelById",
    params: {
      id: props.modelValue.id,
    },
  });
};

const __returned__ = { props, emit, lastModifiedBy, handleActivateClick, handleInactivateClick, handleEditClick, get formatDateAsString() { return formatDateAsString }, get UiButton() { return UiButton }, get CalendarIcon() { return CalendarIcon }, get UsersIcon() { return UsersIcon }, get PhoneIcon() { return PhoneIcon }, get CheckCircleIcon() { return CheckCircleIcon }, get XCircleIcon() { return XCircleIcon }, get PencilAltIcon() { return PencilAltIcon }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})