import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref } from "vue";
import store from "@/store";
import router from "@/router";
import AuthService from "@/services/AuthService";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength, email, requiredIf } from "@/utils/validators";
import { toastServiceError } from "@/utils/notification";
import { UiTextInput, UiCheckboxInput, UiButton } from "@/components";
import {
  UiActions,
  AuthActions,
  ILoginFormState,
  IUser,
  AuthGetters,
} from "@/definitions";
import { UserService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props, { expose: __expose }) {
  __expose();

const formState = reactive<ILoginFormState>({
  email: "",
  password: "",
  rememberMe: true,
});

const FormType = {
  LOGIN: "LOGIN",
  RECOVER: "RECOVER",
  SENT_RECOVER: "SENT_RECOVER",
};

const rules = computed<ValidationArgs<ILoginFormState>>(() => ({
  email: {
    email,
    required,
    maxLength: maxLength(250),
  },
  password: {
    required: requiredIf(() => {
      return showFormType.value === FormType.LOGIN;
    }),
  },
  rememberMe: {},
}));

const v$: Ref<Validation<ValidationArgs<ILoginFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);

const onSubmitLogin = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  AuthService.login(formState)
    .then(async (response) => {
      await store.dispatch(AuthActions.LOGIN, response.data);

      if (user.value.lastLogin === undefined || user.value.lastLogin === null) {
        router.push({ name: "UpdatePassword" }).then();
        return true;
      }
      router.push("/").then();
    }, toastServiceError)
    .finally(() => setLoading(false));
};

const onSubmitResetPassword = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  UserService.recoveryPassword(formState)
    .then(
      async (/* response */) => {
        showFormType.value = FormType.SENT_RECOVER;
      },
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const showFormType = ref(FormType.LOGIN);

const __returned__ = { formState, FormType, rules, v$, setLoading, user, onSubmitLogin, onSubmitResetPassword, showFormType, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiButton() { return UiButton } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})