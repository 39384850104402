import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import RoleService from "@/services/RoleService";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { Permission, UiActions, IRole } from "@/definitions";
import { UiButton, UiPageHeader, UiContainerSection } from "@/components";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'RoleDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const role: Ref<IRole | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getRole = (id: number) => {
  setLoading(true);
  RoleService.get<IRole>(id)
    .then((response: AxiosResponse<IRole>) => {
      role.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getRole(parseInt(route.params.id as string));

const __returned__ = { route, role, setLoading, getRole, get goBack() { return goBack }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiContainerSection() { return UiContainerSection } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})