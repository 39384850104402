import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createSlots as _createSlots, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_4 = {
  key: 2,
  class: ""
}
const _hoisted_5 = {
  for: "levels",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_6 = {
  key: 3,
  class: ""
}
const _hoisted_7 = {
  for: "roles",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_8 = { class: "" }
const _hoisted_9 = {
  for: "levels",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_10 = { class: "flex flex-col justify-end pb-1.5" }
const _hoisted_11 = { class: "text-base font-semibold leading-6 text-gray-800 mb-4" }
const _hoisted_12 = { class: "grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-6 relative" }
const _hoisted_13 = { class: "flex flex-col justify-between bg-gray-50 text-sm px-4 py-3 absolute rounded bottom-0 mt-6 -right-[215px] shadow text-gray-600 border-r-4 border-b-4 border-cyan-600" }
const _hoisted_14 = {
  key: 0,
  class: "grid grid-cols-2 md:grid-cols-12 gap-4 relative mt-5"
}
const _hoisted_15 = { class: "col-span-3" }
const _hoisted_16 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-800"
}
const _hoisted_17 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("user." + $setup.props.action + ".title")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.v$.name.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('user.labels.name'),
              class: "",
              errors: $setup.v$.name.$errors,
              required: $setup.v$.name.required !== undefined,
              onBlur: $setup.v$.name.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]),
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.v$.email.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.email.$model) = $event)),
              name: "email",
              type: "text",
              label: _ctx.$t('user.labels.email'),
              class: "",
              autocomplete: "new-email",
              errors: $setup.v$.email.$errors,
              required: $setup.v$.email.required !== undefined,
              onBlur: $setup.v$.email.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]),
            ($setup.props.action == $setup.FormAction.CREATE)
              ? (_openBlock(), _createBlock($setup["UiTextInput"], {
                  key: 0,
                  modelValue: $setup.formState.password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.password) = $event)),
                  name: "password",
                  type: "password",
                  label: _ctx.$t('user.labels.password'),
                  class: "",
                  autocomplete: "new-password",
                  errors: $setup.v$.password?.$errors,
                  required: $setup.v$.password.required !== undefined,
                  onBlur: $setup.v$.password.$touch
                }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]))
              : _createCommentVNode("v-if", true),
            ($setup.props.action == $setup.FormAction.CREATE)
              ? (_openBlock(), _createBlock($setup["UiTextInput"], {
                  key: 1,
                  modelValue: $setup.formState.confirmPassword,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.confirmPassword) = $event)),
                  name: "confirmPassword",
                  type: "password",
                  label: _ctx.$t('user.labels.confirmPassword'),
                  class: "",
                  errors: $setup.v$.confirmPassword?.$errors,
                  required: $setup.v$.confirmPassword.required !== undefined,
                  onBlur: $setup.v$.confirmPassword.$touch
                }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]))
              : _createCommentVNode("v-if", true),
            (_ctx.$can($setup.Permission.ASSIGN_LEVEL_TO_USER))
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("label", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.$t("user.labels.levels")) + " ", 1 /* TEXT */),
                    _cache[15] || (_cache[15] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
                  ]),
                  _createVNode($setup["UiMultiselect"], {
                    name: "levels",
                    id: "levels",
                    modelValue: $setup.v$.levels.$model,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.v$.levels.$model) = $event)),
                    options: $setup.levels,
                    primitive: true,
                    multiple: true,
                    "can-clear": true,
                    errors: $setup.v$.levels?.$errors,
                    label: "name",
                    "value-prop": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.$can($setup.Permission.ASSIGN_ROLE_TO_USER))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("label", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.$t("user.labels.roles")) + " ", 1 /* TEXT */),
                    _cache[16] || (_cache[16] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
                  ]),
                  _createVNode($setup["UiMultiselect"], {
                    name: "roles",
                    id: "roles",
                    modelValue: $setup.v$.roles.$model,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.v$.roles.$model) = $event)),
                    options: $setup.roles,
                    primitive: true,
                    multiple: true,
                    "can-clear": true,
                    errors: $setup.v$.roles?.$errors,
                    label: "name",
                    "value-prop": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, [
                _createTextVNode(_toDisplayString(_ctx.$t("user.labels.standardLevel")) + " ", 1 /* TEXT */),
                _cache[17] || (_cache[17] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
              ]),
              _createVNode($setup["UiMultiselect"], {
                name: "levels",
                id: "levels",
                modelValue: $setup.v$.standardLevelId.$model,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.v$.standardLevelId.$model) = $event)),
                options: $setup.selectedLevels,
                errors: $setup.v$.standardLevelId?.$errors,
                primitive: "",
                label: "name",
                "value-prop": "id"
              }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode($setup["UiCheckboxInput"], {
                modelValue: $setup.v$.isActive.$model,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
                name: "isActive",
                label: _ctx.$t('user.labels.isActive'),
                class: "h-6"
              }, null, 8 /* PROPS */, ["modelValue", "label"]),
              ($setup.levelHasMeeting)
                ? (_openBlock(), _createBlock($setup["UiCheckboxInput"], {
                    key: 0,
                    modelValue: $setup.v$.hasMeeting.$model,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.v$.hasMeeting.$model) = $event)),
                    name: "hasMeeting",
                    label: _ctx.$t('user.labels.hasMeeting'),
                    class: "h-6 pt-1.5"
                  }, null, 8 /* PROPS */, ["modelValue", "label"]))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t("user.labels.devices.title")), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_12, [
            _createVNode($setup["UserFormDevice"], {
              label: _ctx.$t('user.labels.devices.mobile'),
              modelValue: $setup.formState.devices.mobile,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.formState.devices.mobile) = $event))
            }, {
              icon: _withCtx(() => [
                _createVNode($setup["DeviceMobileIcon"], { class: "h-20 w-20 mt-6" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label", "modelValue"]),
            _createVNode($setup["UserFormDevice"], {
              label: _ctx.$t('user.labels.devices.desktop'),
              modelValue: $setup.formState.devices.desktop,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.formState.devices.desktop) = $event))
            }, {
              icon: _withCtx(() => [
                _createVNode($setup["DesktopComputerIcon"], { class: "h-20 w-20 mt-6" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label", "modelValue"]),
            _createVNode($setup["UserFormDevice"], {
              label: _ctx.$t('user.labels.devices.web'),
              modelValue: $setup.formState.devices.web,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.formState.devices.web) = $event))
            }, {
              icon: _withCtx(() => [
                _createVNode($setup["SVGChromeLogo"], { class: "h-20 w-20 mt-6" })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label", "modelValue"]),
            _createVNode($setup["UserFormDevice"], {
              label: _ctx.$t('user.labels.devices.external'),
              modelValue: $setup.formState.devices.external,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.formState.devices.external) = $event))
            }, _createSlots({
              icon: _withCtx(() => [
                _createVNode($setup["SVGDeviceExternal"], { class: "h-20 w-20 mt-6" })
              ]),
              _: 2 /* DYNAMIC */
            }, [
              ($setup.externalConfig)
                ? {
                    name: "extra-info",
                    fn: _withCtx(() => [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[21] || (_cache[21] = _createElementVNode("h3", { class: "font-semibold text-base mb-1" }, " Configurações do PABX ", -1 /* HOISTED */)),
                        _createElementVNode("p", null, [
                          _cache[18] || (_cache[18] = _createElementVNode("span", { class: "font-semibold" }, "Hostname:", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString($setup.externalConfig.hostname), 1 /* TEXT */)
                        ]),
                        _createElementVNode("p", null, [
                          _cache[19] || (_cache[19] = _createElementVNode("span", { class: "font-semibold" }, "Username:", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString($setup.externalConfig.sipUser), 1 /* TEXT */)
                        ]),
                        _createElementVNode("p", null, [
                          _cache[20] || (_cache[20] = _createElementVNode("span", { class: "font-semibold" }, "Password:", -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString($setup.externalConfig.sipPassword), 1 /* TEXT */)
                        ])
                      ])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "modelValue"])
          ]),
          ($setup.formState.number)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode($setup["UserFormExtension"], {
                  modelValue: $setup.formState.extension,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.formState.extension) = $event)),
                  devices: $setup.formState.devices
                }, null, 8 /* PROPS */, ["modelValue", "devices"]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("user.labels.number")), 1 /* TEXT */),
                  _createVNode($setup["UiMultiselect"], {
                    name: `number`,
                    modelValue: $setup.formState.number.numberId,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.formState.number.numberId) = $event)),
                    placeholder: _ctx.$t('core.random'),
                    options: $setup.getLevelNumbers($setup.formState.standardLevelId),
                    primitive: true,
                    multiple: false,
                    "can-clear": true,
                    disabled: $setup.noDevice,
                    label: "number",
                    "value-prop": "id"
                  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options", "disabled"])
                ])
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_17, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          size: "xl",
          "text-variant": "gray-500",
          to: { name: 'ListUser' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_USER)) ||
            ($setup.props.action == $setup.FormAction.EDIT && _ctx.$can($setup.Permission.EDIT_USER))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              size: "xl",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}