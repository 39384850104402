import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, WritableComputedRef, PropType, watch } from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IConnectFlowAudio,
  IFlowNode,
} from "@/definitions";
import {
  UiNode,
  UiButton,
  UiMultiselect,
  UiRightPanel,
  UiTextArea,
} from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import store from "@/store";
import { PlayIcon } from "@heroicons/vue/outline";
import { helpers } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

interface IAudioFormState {
  audio: null | IConnectFlowAudio;
  text: undefined | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PlayAudio',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const audioOptions = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.AUDIO_OPTIONS],
);
const formState: Ref<IAudioFormState> = ref({
  audio: null,
  text: undefined,
});
const textValidation = t("connectFlow.node.playAudio.validation");
const atLeastOneFilled = helpers.withMessage(textValidation, (value, state) => {
  return !!state.audio || !!state.text;
});
const rules = computed<ValidationArgs<IAudioFormState>>(() => {
  const r: ValidationArgs<IAudioFormState> = {
    audio: {
      atLeastOneFilled,
    },
    text: {
      atLeastOneFilled,
    },
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IAudioFormState>>> = useVuelidate(
  rules,
  formState,
);

const nodeText = computed(() => {
  let text = t("connectFlow.node.playAudio.empty");
  if (nodeWritable.value.data.related) {
    text = nodeWritable.value.data.related.label;
  } else if (nodeWritable.value.data.textToAudio) {
    text = t("connectFlow.node.playAudio.textToAudio");
  }
  return text;
});

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    if (formState.value.audio) {
      nodeWritable.value.data.related = {
        value: formState.value.audio.id,
        label: formState.value.audio.name,
      };
      nodeWritable.value.data.textToAudio = undefined;
    } else if (formState.value.text) {
      nodeWritable.value.data.textToAudio = formState.value.text;
      nodeWritable.value.data.related = null;
    }
    showForm.value = false;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  }
  v$.value.$reset();
}

watch([nodeWritable.value], () => {
  formState.value.audio = audioOptions.value.find(
    (audio: IConnectFlowAudio) =>
      audio.id == nodeWritable.value.data.related?.value,
  );
  formState.value.text = nodeWritable.value.data.textToAudio;

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

const __returned__ = { t, props, showForm, nodeWritable, audioOptions, formState, textValidation, atLeastOneFilled, rules, v$, nodeText, isFormCorrect, removeNode, onSubmit, get UiNode() { return UiNode }, get UiButton() { return UiButton }, get UiMultiselect() { return UiMultiselect }, get UiRightPanel() { return UiRightPanel }, get UiTextArea() { return UiTextArea }, get PlayIcon() { return PlayIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})