import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "flex items-center space-x-4" }
const _hoisted_5 = { class: "space-y-6" }
const _hoisted_6 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6" }
const _hoisted_7 = { class: "font-semibold mb-1" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "font-semibold mb-1" }
const _hoisted_10 = { controls: "" }
const _hoisted_11 = ["src", "type"]
const _hoisted_12 = { class: "font-semibold mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("connectFlowAudio.view.title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("connectFlowAudio.view.description")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["UiButton"], {
            variant: "white",
            "text-variant": "gray-500",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goBack({ name: 'ListConnectFlowAudio' })))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Back")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.$can($setup.Permission.EDIT_CONNECT_FLOW_AUDIO))
            ? (_openBlock(), _createBlock($setup["RouterLink"], {
                key: 0,
                to: {
              name: 'EditConnectFlowAudioById',
              params: {
                id: $setup.route.params.id,
              },
            },
                class: "block hover:bg-gray-50"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["UiButton"], {
                    variant: "primary",
                    "text-variant": "white",
                    themed: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["to"]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["UiPanel"], null, _createSlots({ _: 2 /* DYNAMIC */ }, [
        ($setup.audio !== undefined)
          ? {
              name: "content",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", null, [
                    _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t("connectFlowAudio.labels.name")), 1 /* TEXT */),
                    _createElementVNode("p", null, _toDisplayString($setup.audio.name), 1 /* TEXT */)
                  ]),
                  ($setup.audioData)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("connectFlowAudio.labels.file")), 1 /* TEXT */),
                        _createElementVNode("audio", _hoisted_10, [
                          _createElementVNode("source", {
                            src: $setup.audioData,
                            type: $setup.getAudioFormat($setup.audio.audioFilePath)
                          }, null, 8 /* PROPS */, _hoisted_11),
                          _cache[1] || (_cache[1] = _createTextVNode(" Your browser does not support the audio element. "))
                        ])
                      ]))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("div", null, [
                    _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.$t("connectFlowAudio.labels.status")), 1 /* TEXT */),
                    _createElementVNode("p", {
                      class: _normalizeClass([[
                  $setup.audio?.isActive
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800',
                ], "inline-flex rounded-full px-2 text-sm font-semibold"])
                    }, _toDisplayString($setup.audio?.isActive
                    ? _ctx.$t("core.options.Active")
                    : _ctx.$t("core.options.Inactive")), 3 /* TEXT, CLASS */)
                  ])
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1024 /* DYNAMIC_SLOTS */)
    ])
  ]))
}