import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import router from "@/router";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { UiButton } from "@/components";
import {
  CalendarIcon,
  FolderIcon,
  InformationCircleIcon,
  LocationMarkerIcon,
  UsersIcon,
} from "@heroicons/vue/solid";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";
import { PencilAltIcon, TagIcon } from "@heroicons/vue/outline";
import { INumber, Permission } from "@/definitions";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberListItem',
  props: {
  modelValue: {
    type: Object as PropType<INumber>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

const handleEditClick = () => {
  router.push({
    name: "EditNumberById",
    params: {
      id: props.modelValue.id,
    },
  });
};

const __returned__ = { props, lastModifiedBy, handleEditClick, get formatDateAsString() { return formatDateAsString }, get UiButton() { return UiButton }, get CalendarIcon() { return CalendarIcon }, get FolderIcon() { return FolderIcon }, get InformationCircleIcon() { return InformationCircleIcon }, get LocationMarkerIcon() { return LocationMarkerIcon }, get UsersIcon() { return UsersIcon }, get ConnectFlowIcon() { return ConnectFlowIcon }, get PencilAltIcon() { return PencilAltIcon }, get TagIcon() { return TagIcon }, get Permission() { return Permission }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})