import { defineComponent as _defineComponent } from 'vue'
import { PropType, computed } from "vue";
import { UiChart } from "@/components";
import { IDataItem } from "@/definitions";
import { EChartsOption } from "echarts";
import {
  CallbackDataParams,
  LegendComponentOption,
  SeriesOption,
  XAXisOption,
  YAXisOption,
} from "echarts/types/dist/shared";


export default /*@__PURE__*/_defineComponent({
  __name: 'BarChart',
  props: {
  title: {
    type: String,
    required: true,
  },
  categoryData: Array<string>,
  seriesData: {
    type: Array<IDataItem<number[]>>,
    required: true,
  },
  xAxisLabelFormatter: {
    type: Function as PropType<(value: number | string) => string>,
    default: (value: number) => value,
  },
  yAxisLabelFormatter: {
    type: Function as PropType<(value: number | string) => string>,
    default: (value: number) => value,
  },
  column: {
    type: Boolean,
    default: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const chartOption = computed<EChartsOption>(() => {
  const series: SeriesOption[] = props.seriesData.map((d) => {
    const seriesOption: SeriesOption = {
      name: d.name,
      type: "bar",
      stack: "total",
      label: {
        show: false,
      },
      itemStyle: {
        color: d.color,
      },
      emphasis: {
        focus: "series",
      },
      data: d.value,
    };
    return seriesOption;
  });

  const categoryAxis = {
    type: "category",
    axisLabel: {
      fontSize: "9px",
    },
    data: props.categoryData,
  };

  const valueAxis = {
    type: "value",
    axisLabel: {
      fontSize: "9px",
    },
  };

  const xAxis = (props.column ? categoryAxis : valueAxis) as XAXisOption;
  const yAxis = (props.column ? valueAxis : categoryAxis) as YAXisOption;

  xAxis.axisLabel.formatter = props.xAxisLabelFormatter;
  yAxis.axisLabel.formatter = props.yAxisLabelFormatter;

  const legend: LegendComponentOption = {
    top: "15%",
    left: "center",
    itemHeight: 10,
    itemWidth: 10,
    icon: "circle",
    textStyle: {
      fontSize: "11px",
      lineHeight: 14,
      rich: {
        a: {
          align: "center", // Alinha o texto da legenda horizontalmente ao meio
          verticalAlign: "bottom", // Alinha o texto da legenda verticalmente ao meio
        },
        b: {
          align: "center", // Alinha o valor numérico horizontalmente ao meio
          verticalAlign: "bottom", // Alinha o valor numérico verticalmente ao meio
        },
      },
    },
    formatter: function (name) {
      return "{a|" + name + "}"; // Formata o texto da legenda para adicionar um valor numérico
    },
    selectedMode: true,
  };

  const eChartsOption: EChartsOption = {
    title: {
      text: props.title,
      left: "14px",
      top: "14px",
      textStyle: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#555",
      },
    },
    yAxis,
    xAxis,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter: (params: CallbackDataParams[]) => {
        const name = props.column
          ? props.xAxisLabelFormatter(params[0].name)
          : props.yAxisLabelFormatter(params[0].name);
        let output = "<b>" + name + "</b><br/>";
        for (let i = 0; i < params.length; i++) {
          const value = props.column
            ? props.yAxisLabelFormatter(params[i].value as number)
            : props.xAxisLabelFormatter(params[i].value as number);
          output +=
            (params[i].marker || "") +
            (params[i].seriesName || "") +
            ": " +
            value;

          if (i != params.length - 1) {
            // Append a <br/> tag if not last in loop
            output += "<br/>";
          }
        }
        return output;
      },
    },
    grid: {
      left: "3%",
      right: "3%",
      bottom: "3%",
      top: "20%",
      containLabel: true,
    },
    series,
  };

  if (series.length > 1) {
    eChartsOption.legend = legend;
    eChartsOption.grid.top = "30%";
  }

  return eChartsOption;
});

const __returned__ = { props, chartOption, get UiChart() { return UiChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})