import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex h-screen w-full" }
const _hoisted_2 = { class: "md:fixed flex flex-col justify-between overflow-y-auto sm:w-60 h-full bg-primary" }
const _hoisted_3 = { class: "h-full pb-24" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "w-full md:pl-60" }
const _hoisted_6 = { class: "flex flex-col justify-between" }
const _hoisted_7 = { class: "w-full flex items-center justify-between" }
const _hoisted_8 = { class: "grid grid-cols-2 md:grid-cols-5 gap-4 w-full" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["TemplateSidebar"], null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, { to: { name: 'Home' } }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "mt-3 ml-3 mb-8 h-12",
                  src: $setup.logoPath,
                  alt: "Logo Mobcall"
                }, null, 8 /* PROPS */, _hoisted_4)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode($setup["TemplateNavigation"], { items: $setup.sideNavigationItems }, null, 8 /* PROPS */, ["items"])
          ]),
          _createVNode($setup["TemplateProfile"])
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass([$setup.headerClass, "p-2 sm:px-4 sm:py-2 shadow flex items-center sticky top-0 z-30"])
        }, [
          _createElementVNode("header", _hoisted_7, [
            _createVNode($setup["TemplateHeader"], null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode($setup["UiMultiselect"], {
                    name: "template_level",
                    label: "name",
                    "value-prop": "id",
                    modelValue: $setup.level,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.level) = $event)),
                    searchable: "local",
                    options: $setup.levels,
                    class: "col-span-2"
                  }, null, 8 /* PROPS */, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  false
                    ? _withDirectives((_openBlock(), _createElementBlock("select", {
                        key: 0,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
                        class: "rounded-md inline-flex py-1 pl-2 pr-7 sm:mr-1 border border-transparent text-gray-400 hover:text-gray-500 focus:outline-none bg-inherit hover:bg-inherit focus:ring-primary",
                        onChange: $setup.handleLocaleChange
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: `locale-${locale}`,
                            value: locale
                          }, _toDisplayString(locale), 9 /* TEXT, PROPS */, _hoisted_10))
                        }), 128 /* KEYED_FRAGMENT */))
                      ], 544 /* NEED_HYDRATION, NEED_PATCH */)), [
                        [_vModelSelect, _ctx.$i18n.locale]
                      ])
                    : _createCommentVNode("v-if", true),
                  _createVNode($setup["UiButton"], {
                    size: "xs",
                    shape: "rounded-full",
                    variant: "inherit",
                    "text-variant": "gray-400",
                    shadow: "none",
                    class: "border-transparent focus:ring-primary shadow-none mr-1",
                    onClick: $setup.handleConfigClick
                  }, {
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Config", -1 /* HOISTED */)),
                      _createVNode($setup["CogIcon"], {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  false
                    ? (_openBlock(), _createBlock($setup["UiButton"], {
                        key: 1,
                        size: "xs",
                        shape: "rounded-full",
                        variant: "inherit",
                        "text-variant": "gray-400",
                        shadow: "none",
                        class: "border-transparent focus:ring-primary shadow-none mr-1"
                      }, {
                        default: _withCtx(() => [
                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "View notifications", -1 /* HOISTED */)),
                          _createVNode($setup["BellIcon"], {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true),
                  ($setup.showLogout)
                    ? (_openBlock(), _createBlock($setup["UiButton"], {
                        key: 2,
                        size: "xs",
                        shape: "rounded-full",
                        variant: "inherit",
                        "text-variant": "gray-400",
                        shadow: "none",
                        class: "border-transparent focus:ring-primary shadow-none",
                        onClick: $setup.handleLogoutClick
                      }, {
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "sr-only" }, "Logout", -1 /* HOISTED */)),
                          _createVNode($setup["LogoutIcon"], {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _: 1 /* STABLE */
            })
          ])
        ], 2 /* CLASS */),
        _createElementVNode("main", null, [
          ($setup.level)
            ? (_openBlock(), _createBlock($setup["RouterView"], {
                key: _ctx.$route.fullPath
              }))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createVNode($setup["UiLoadingDialog"], {
        show: $setup.loading,
        text: _ctx.$t('ui-loading-dialog-text')
      }, null, 8 /* PROPS */, ["show", "text"])
    ])
  ]))
}