import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { RouterLink, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { UserService, NumberService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { IUser, INumber, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiPanel } from "@/components";
import { AxiosResponse } from "axios";

import { DesktopComputerIcon } from "@heroicons/vue/solid";
import { DeviceMobileIcon } from "@heroicons/vue/outline";

import SVGDeviceExternal from "@/components/svg/SVGDeviceExternal.vue";
import SVGChromeLogo from "@/components/svg/SVGChromeLogo.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const route = useRoute();

const user: Ref<IUser | undefined> = ref(undefined);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getUser = (id: number) => {
  setLoading(true);
  UserService.get<IUser>(id)
    .then((response: AxiosResponse<IUser>) => {
      user.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

function getLevelName(levelID: number) {
  if (user.value !== undefined) {
    const level = user.value.levels?.find((level) => level.id === levelID);
    return level ? level.name : "";
  }
}

getUser(parseInt(route.params.id as string));

const numbersRaw: Ref<INumber[]> = ref([]);
const getNumbers = () => {
  setLoading(true);
  const levels = user.value?.levels;
  NumberService.getAll<INumber[]>({
    params: {
      levels,
    },
  })
    .then((response: AxiosResponse<INumber[]>) => {
      numbersRaw.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
getNumbers();

function getBinaNumber(levelID: number | null, binaID: number | null) {
  const number = numbersRaw.value.filter(
    (number) => number.level.id == levelID && number.id == binaID,
  );
  return number.length > 0 ? number[0].number : t("core.random");
}

const __returned__ = { t, route, user, setLoading, getUser, getLevelName, numbersRaw, getNumbers, getBinaNumber, get RouterLink() { return RouterLink }, get goBack() { return goBack }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get DesktopComputerIcon() { return DesktopComputerIcon }, get DeviceMobileIcon() { return DeviceMobileIcon }, SVGDeviceExternal, SVGChromeLogo }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})