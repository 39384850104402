import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import {
  QueueService,
  LevelService,
  UserService,
  VaultService,
} from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiMultiselect,
  UiAudioUpload,
} from "@/components";
import {
  IQueue,
  ILevel,
  IUser,
  Permission,
  UiActions,
  IDefaultServiceResult,
  IQueueServiceCreateResult,
  FormAction,
  IQueueForm,
  FilterGetters,
  IMultiSelectOption,
  QueueStrategy,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";
import { useI18n } from "vue-i18n";
import { getAudioFormat } from "@/utils/audio";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'QueueFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const { t } = useI18n();

const props = __props;

const strategyOptions: Ref<IMultiSelectOption[]> = ref([
  {
    label: t(`queue.strategy.${QueueStrategy.RANDOM}`),
    value: QueueStrategy.RANDOM,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.RRORDERED}`),
    value: QueueStrategy.RRORDERED,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.RRMEMORY}`),
    value: QueueStrategy.RRMEMORY,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.RINGALL}`),
    value: QueueStrategy.RINGALL,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.FEWESTCALLS}`),
    value: QueueStrategy.FEWESTCALLS,
  },
  {
    label: t(`queue.strategy.${QueueStrategy.LEASTRECENT}`),
    value: QueueStrategy.LEASTRECENT,
  },
]);

const formState = reactive<IQueueForm>({
  id: 0,
  name: "",
  strategy: "",
  fileAudio: "",
  isActive: true,
  levelId: 0,
  users: [],
  audio: undefined,
});

const rules = computed(() => ({
  id: {},
  name: {
    required,
    maxLength: maxLength(100),
  },
  strategy: {
    required,
  },
  levelId: {
    required,
  },
  fileAudio: {},
  users: {
    required,
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<IQueueForm>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getQueue = (id: number) => {
  setLoading(true);
  QueueService.get<IQueue>(id)
    .then((response: AxiosResponse<IQueue>) => {
      const { name, levelId, strategy, fileAudio, isActive, queueUsers } =
        response.data;
      formState.id = id;
      formState.name = name;
      formState.levelId = levelId;
      formState.strategy = strategy;
      formState.fileAudio = fileAudio || "";
      formState.isActive = isActive;
      formState.users = queueUsers ? queueUsers?.map((u) => u.userId) : [];

      getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getQueue(parseInt(route.params.id as string));
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch([levelFilter], () => {
  getLevels();
});

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

const users = computed(() => {
  return usersRaw.value.map((user) => {
    return {
      id: user.id,
      name: user.name,
    };
  });
});
const usersRaw: Ref<IUser[]> = ref([]);
const getUsers = () => {
  setLoading(true);
  UserService.getAll<IUser[]>({
    params: {
      isAgent: true,
      page: 1,
      per_page: 100,
      level: formState.levelId,
    },
  }).then((response) => {
    setLoading(false);
    usersRaw.value = response.data;
  });
};
getUsers();

const audioData: Ref<string | null> = ref(null);
const getAudioFile = async () => {
  if (formState.fileAudio) {
    const { data } = await VaultService.getAudioAsset(formState.fileAudio);
    const blob = new Blob([data], {
      type: getAudioFormat(formState.fileAudio),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};
const handleFileUpdate = (file: File) => {
  if (file) {
    formState.audio = file;
  } else {
    formState.audio = null;
  }
};

watch(
  () => formState.levelId,
  () => {
    getUsers();
  },
);

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const formData = new FormData();

  formData.append("id", formState.id.toString());
  formData.append("levelId", formState.levelId.toString());
  formData.append("name", formState.name);
  formData.append("strategy", formState.strategy);
  formData.append("isActive", formState.isActive.toString());
  formState.users.forEach((user, index) => {
    formData.append(`users[${index}]`, user.toString());
  });

  if (formState.audio) {
    formData.append("audio", formState.audio);
  }

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    v$.value.$reset();
    router.push({ name: "ListQueue" });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    QueueService.update<IDefaultServiceResult, FormData>(id, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    QueueService.create<IQueueServiceCreateResult, FormData>(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(
        (response: AxiosResponse<IQueueServiceCreateResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

const __returned__ = { route, t, props, strategyOptions, formState, rules, v$, setLoading, getQueue, levelFilter, levels, getLevels, users, usersRaw, getUsers, audioData, getAudioFile, handleFileUpdate, onSubmit, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get UiMultiselect() { return UiMultiselect }, get UiAudioUpload() { return UiAudioUpload }, get Permission() { return Permission }, get FormAction() { return FormAction }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})