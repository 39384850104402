import { defineComponent as _defineComponent } from 'vue'
import store from "@/store";
import { NavigationActions } from "@/definitions";
import { MenuIcon } from "@heroicons/vue/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateHeader',
  setup(__props, { expose: __expose }) {
  __expose();

function toogleMenu() {
  store.dispatch(NavigationActions.TOGGLE_SIDE_NAVIGATION, true);
}

const __returned__ = { toogleMenu, get MenuIcon() { return MenuIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})