import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { ConnectFlowAudioService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
} from "@/components";
import {
  AudioListItem,
  AudioListSort,
  AudioListFilters,
} from "@/views/pages/connect-flow-audio/components";
import {
  IConnectFlowAudio,
  Permission,
  AudioGetters,
  AudioActions,
  IConnectFlowAudioFilter,
  IConnectFlowAudioServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { CloudUploadIcon } from "@heroicons/vue/solid";

const perPage = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'AudioPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const audios: Ref<IConnectFlowAudio[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[AudioGetters.SEARCH],
  set: (search: string) => store.dispatch(AudioActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[AudioGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(AudioActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[AudioGetters.PAGE],
  set: (page: number) => store.dispatch(AudioActions.SET_PAGE, page),
});

const filter: IConnectFlowAudioFilter = store.getters[AudioGetters.FILTER];

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getAudios = () => {
  const params: IConnectFlowAudioServiceGetAllParams = {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active:
      filter.isActive !== null ? (filter.isActive as boolean) : undefined,
    search: search.value,
  };

  setLoading(true);
  ConnectFlowAudioService.getAll<Array<IConnectFlowAudio>>({
    params,
  })
    .then((response: AxiosResponse<IConnectFlowAudio[]>) => {
      audios.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([level, search, filter, orderBy], () => {
  if (currentPage.value === 1) getAudios();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getAudios();
});

getAudios();

const setIsActive = async (item: IConnectFlowAudio, isActive: boolean) => {
  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getAudios();
  };

  (isActive
    ? ConnectFlowAudioService.activate
    : ConnectFlowAudioService.inactivate)(item.id)
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveStatusFilterClick = () => {
  store.dispatch(AudioActions.CLEAR_FILTER, "isActive");
};

const handleActivateClick = (item: IConnectFlowAudio) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: IConnectFlowAudio) => {
  setIsActive(item, false);
};

const __returned__ = { route, router, setLoading, audios, search, sortMenuSelected, level, orderBy, currentPage, filter, count, perPage, getAudios, setIsActive, handleRemoveSortClick, handleRemoveStatusFilterClick, handleActivateClick, handleInactivateClick, get UiButton() { return UiButton }, get UiPagination() { return UiPagination }, get UiBadge() { return UiBadge }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get AudioListItem() { return AudioListItem }, get AudioListSort() { return AudioListSort }, get AudioListFilters() { return AudioListFilters }, get Permission() { return Permission }, get CloudUploadIcon() { return CloudUploadIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})