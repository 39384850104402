import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = { class: "hidden sm:block flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "text-sm text-gray-700"
}
const _hoisted_4 = {
  key: 1,
  class: "text-sm text-gray-700"
}
const _hoisted_5 = {
  class: "inline-flex rounded-md shadow-sm -space-x-px",
  "aria-label": "Pagination"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($props.count > 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.i18n?.("pagination.label", $setup.paginationLabelData)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($props.count === 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.i18n?.("pagination.labelOnePage", {
            total: $props.count,
          })), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("nav", _hoisted_5, [
        ($setup.props.modelValue > 1)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goTo($setup.props.modelValue - 1))),
              class: "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "sr-only" }, "Previous", -1 /* HOISTED */)),
              _createVNode($setup["ChevronLeftIcon"], {
                class: "h-5 w-5",
                "aria-hidden": "true"
              })
            ]))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pages, (page) => {
          return (_openBlock(), _createElementBlock("span", {
            key: `page${page}`,
            onClick: ($event: any) => ($setup.goTo(page)),
            "aria-current": "page",
            class: _normalizeClass(["relative inline-flex items-center px-4 py-2 border text-sm font-medium", 
              page === $setup.props.modelValue
                ? 'theme-border-color theme-color z-3'
                : 'border-gray-300 text-gray-500 hover:bg-gray-50 cursor-pointer z-2'
            ])
          }, _toDisplayString(page), 11 /* TEXT, CLASS, PROPS */, _hoisted_6))
        }), 128 /* KEYED_FRAGMENT */)),
        ($setup.props.modelValue < $setup.pageCount)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.goTo($setup.props.modelValue + 1))),
              class: "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 cursor-pointer"
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "Next", -1 /* HOISTED */)),
              _createVNode($setup["ChevronRightIcon"], {
                class: "h-5 w-5",
                "aria-hidden": "true"
              })
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}