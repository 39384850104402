import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = {
  key: 0,
  class: "mt-5 flex px-4 py-5 sm:p-6 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
}
const _hoisted_4 = { class: "mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2" }
const _hoisted_5 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_6 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_7 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_8 = { class: "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6" }
const _hoisted_9 = { class: "truncate text-sm font-medium text-gray-500" }
const _hoisted_10 = { class: "mt-1 text-3xl font-semibold tracking-tight text-gray-900" }
const _hoisted_11 = { class: "mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2" }
const _hoisted_12 = { class: "bg-white shadow rounded-lg p-6" }
const _hoisted_13 = { class: "text-lg font-semibold text-gray-900 mb-4" }
const _hoisted_14 = { class: "space-y-4" }
const _hoisted_15 = { class: "flex justify-between border-b border-gray-200 pb-2" }
const _hoisted_16 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_17 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_18 = { class: "text-sm text-gray-500" }
const _hoisted_19 = { class: "text-sm font-semibold text-gray-900" }
const _hoisted_20 = { class: "bg-white shadow rounded-lg p-6" }
const _hoisted_21 = { class: "text-lg font-semibold text-gray-900 mb-4" }
const _hoisted_22 = { class: "space-y-4" }
const _hoisted_23 = { class: "flex justify-between border-b border-gray-200 pb-2" }
const _hoisted_24 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_25 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_26 = { class: "text-sm text-gray-500" }
const _hoisted_27 = { class: "text-sm font-semibold text-gray-900" }
const _hoisted_28 = { class: "bg-white shadow rounded-lg p-6 mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" Header com Total de Minutos e Custo Total "),
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("report.billing.title")), 1 /* TEXT */)
      ]),
      actions: _withCtx(() => [
        _createVNode($setup["BillingReportFilters"], {
          sources: $setup.reportInfo?.sources || [],
          destinations: $setup.reportInfo?.destinations || []
        }, null, 8 /* PROPS */, ["sources", "destinations"]),
        _createVNode($setup["UiDatePicker"], {
          modelValue: $setup.dateRange,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.dateRange) = $event)),
          range: "",
          multiCalendars: ""
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" Filtros Ativos "),
    ($setup.activeFilters.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeFilters, (filter) => {
            return (_openBlock(), _createBlock($setup["UiBadge"], {
              key: filter,
              removable: "",
              onRemove: ($event: any) => ($setup.handleRemoveFilter(filter))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(`report.billing.filters.activeTag.${filter.field}`)) + ": " + _toDisplayString(filter.label), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onRemove"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" Estatísticas Gerais "),
    _createElementVNode("dl", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.$t("report.billing.stats.totalMinutes")), 1 /* TEXT */),
        _createElementVNode("dd", _hoisted_7, _toDisplayString(`${Math.floor($setup.reportInfo?.totalMinutes || 0)}:${Math.floor(
              ($setup.reportInfo?.totalMinutes % 1) * 60 || 0,
            )
              .toString()
              .padStart(2, "0")}`) + "s ", 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("dt", _hoisted_9, _toDisplayString(_ctx.$t("report.billing.stats.totalCost")), 1 /* TEXT */),
        _createElementVNode("dd", _hoisted_10, " R$ " + _toDisplayString($setup.reportInfo?.totalCost?.toFixed(2) || "0,00"), 1 /* TEXT */)
      ])
    ]),
    _createCommentVNode(" Estatísticas Entrante e Saínte "),
    _createElementVNode("div", _hoisted_11, [
      _createCommentVNode(" Seção Entrante "),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t("report.billing.stats.inbound")), 1 /* TEXT */),
        _createElementVNode("dl", _hoisted_14, [
          _createCommentVNode(" Custo Total Entrante "),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("dt", _hoisted_16, _toDisplayString(_ctx.$t("report.billing.stats.totalInboundCost")), 1 /* TEXT */),
            _createElementVNode("dd", _hoisted_17, " R$ " + _toDisplayString($setup.reportInfo?.inboundCost?.toFixed(2) || "0,00"), 1 /* TEXT */)
          ]),
          _createCommentVNode(" Subcustos por Tipo de Origem (Entrante) "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.reportInfo?.costByOriginType || {}, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "flex justify-between"
            }, [
              _createElementVNode("dt", _hoisted_18, _toDisplayString(_ctx.$t(`report.billing.stats.originType.${key}`)), 1 /* TEXT */),
              _createElementVNode("dd", _hoisted_19, " R$ " + _toDisplayString(value?.toFixed(2) || "0,00"), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createCommentVNode(" Seção Saínte "),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("h2", _hoisted_21, _toDisplayString(_ctx.$t("report.billing.stats.outbound")), 1 /* TEXT */),
        _createElementVNode("dl", _hoisted_22, [
          _createCommentVNode(" Custo Total Sainte "),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("dt", _hoisted_24, _toDisplayString(_ctx.$t("report.billing.stats.totalOutboundCost")), 1 /* TEXT */),
            _createElementVNode("dd", _hoisted_25, " R$ " + _toDisplayString($setup.reportInfo?.outboundCost?.toFixed(2) || "0,00"), 1 /* TEXT */)
          ]),
          _createCommentVNode(" Subcustos por Tipo de Destino (Sainte) "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.reportInfo?.costByDestinationType || {}, (value, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "flex justify-between"
            }, [
              _createElementVNode("dt", _hoisted_26, _toDisplayString(_ctx.$t(`report.billing.stats.destinationType.${key}`)), 1 /* TEXT */),
              _createElementVNode("dd", _hoisted_27, " R$ " + _toDisplayString(value?.toFixed(2) || "0,00"), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ]),
    _createCommentVNode(" Gráfico de Consumo por Dia "),
    _createElementVNode("div", _hoisted_28, [
      _createVNode($setup["BarChart"], {
        title: $setup.dailyConsumptionChart.title,
        categoryData: $setup.dailyConsumptionChart.category,
        seriesData: $setup.dailyConsumptionChart.series,
        column: true,
        style: {"width":"100%","height":"400px"}
      }, null, 8 /* PROPS */, ["title", "categoryData", "seriesData"])
    ])
  ]))
}