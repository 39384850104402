import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import {
  ICallTimelineItem,
  ParticipantType,
  AudioPlayerGetters,
  AudioPlayerActions,
} from "@/definitions";
import { PlayIcon, PauseIcon, XCircleIcon } from "@heroicons/vue/solid";
import { UiBadge, UiButton } from "@/components";
import { formatSecToTime } from "@/utils/datetimeCommon";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallTimelineItem',
  props: {
  item: {
    type: Object as PropType<ICallTimelineItem>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();



// function getSentimentColor(sentiment: string): string {
//   switch (sentiment) {
//     case Sentiment.Positive:
//       return "text-green-500";
//     case Sentiment.Negative:
//       return "text-red-600";
//     default:
//       return "text-gray-400";
//   }
// }

const waveInstance = computed(() => store.getters[AudioPlayerGetters.INSTANCE]);
const playerReady = computed(
  () => store.getters[AudioPlayerGetters.PLAYER_READY],
);
const isPlaying = computed(() =>
  playerReady.value ? waveInstance.value?.isPlaying() : false,
);
const secondsCurrent = computed(
  () => store.getters[AudioPlayerGetters.SECONDS_CURRENT],
);

const playerHasError = computed(
  () => store.getters[AudioPlayerGetters.PLAYER_ERROR] !== null,
);

function playSnippet(start: number, end: number) {
  if (!playerHasError.value) {
    store.dispatch(AudioPlayerActions.PLAY_SNIPPET, [start, end]);
  }
}
function pause() {
  waveInstance.value?.pause();
}

const __returned__ = { waveInstance, playerReady, isPlaying, secondsCurrent, playerHasError, playSnippet, pause, get ParticipantType() { return ParticipantType }, get PlayIcon() { return PlayIcon }, get PauseIcon() { return PauseIcon }, get XCircleIcon() { return XCircleIcon }, get UiBadge() { return UiBadge }, get UiButton() { return UiButton }, get formatSecToTime() { return formatSecToTime } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})