import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { QueueService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UiButton,
  UiPagination,
  UiBadge,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
} from "@/components";
import {
  QueueListItem,
  QueueListSort,
  QueueListFilters,
} from "@/views/pages/queue/components";
import {
  IQueue,
  Permission,
  QueueGetters,
  QueueActions,
  IQueueFilter,
  IQueueServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IDefaultServiceResult,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { MenuAlt2Icon } from "@heroicons/vue/solid";

const perPage = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'QueuePage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const queues: Ref<IQueue[]> = ref([]);

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const search = computed<string>({
  get: () => store.getters[QueueGetters.SEARCH],
  set: (search: string) => store.dispatch(QueueActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[QueueGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(QueueActions.SET_SORT, sort),
});

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[QueueGetters.PAGE],
  set: (page: number) => store.dispatch(QueueActions.SET_PAGE, page),
});

const filter: IQueueFilter = store.getters[QueueGetters.FILTER];

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getQueues = () => {
  const params: IQueueServiceGetAllParams = {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    is_active:
      filter.isActive !== null ? (filter.isActive as boolean) : undefined,
    search: search.value,
  };

  setLoading(true);
  QueueService.getAll<Array<IQueue>>({
    params,
  })
    .then((response: AxiosResponse<IQueue[]>) => {
      queues.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([level, search, filter, orderBy], () => {
  if (currentPage.value === 1) getQueues();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getQueues();
});

getQueues();

const setIsActive = async (item: IQueue, isActive: boolean) => {
  setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    getQueues();
  };

  (isActive ? QueueService.activate : QueueService.inactivate)(item.id)
    .then(
      (response: AxiosResponse<IDefaultServiceResult>) =>
        handleServiceSuccess(response.data.message),
      toastServiceError,
    )
    .finally(() => setLoading(false));
};

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const handleRemoveStatusFilterClick = () => {
  store.dispatch(QueueActions.CLEAR_FILTER, "isActive");
};

const handleActivateClick = (item: IQueue) => {
  setIsActive(item, true);
};

const handleInactivateClick = (item: IQueue) => {
  setIsActive(item, false);
};

const __returned__ = { route, router, setLoading, queues, level, search, sortMenuSelected, orderBy, currentPage, filter, count, perPage, getQueues, setIsActive, handleRemoveSortClick, handleRemoveStatusFilterClick, handleActivateClick, handleInactivateClick, get UiButton() { return UiButton }, get UiPagination() { return UiPagination }, get UiBadge() { return UiBadge }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get QueueListItem() { return QueueListItem }, get QueueListSort() { return QueueListSort }, get QueueListFilters() { return QueueListFilters }, get Permission() { return Permission }, get MenuAlt2Icon() { return MenuAlt2Icon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})