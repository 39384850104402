import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { NumberService, LevelService, ConnectFlowService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import router from "@/router";
import {
  UiButton,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiMultiselect,
  UiPanel,
  UiTextInput,
} from "@/components";
import {
  INumber,
  ILevel,
  IConnectFlow,
  IDefaultServiceResult,
  Permission,
  UiActions,
  FormAction,
  INumberFormState,
  FilterGetters,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { AxiosResponse } from "axios";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<INumberFormState>({
  id: 0,
  levelId: 0,
  connectFlowId: 0,
  mediaLabel: "",
  agents: [],
});

const rules = computed(() => ({
  id: {},
  levelId: {
    required,
  },
  connectFlowId: {},
  mediaLabel: {},
  agents: {},
}));

const v$: Ref<Validation<ValidationArgs<INumberFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const number: Ref<INumber | undefined> = ref();
const getNumber = (id: number) => {
  setLoading(true);
  NumberService.get<INumber>(id)
    .then((response: AxiosResponse<INumber>) => {
      number.value = response.data;
      formState.id = id;
      formState.levelId = response.data.level.id;
      formState.connectFlowId = response.data.connectFlowId;
      formState.mediaLabel = response.data.mediaLabel;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
getNumber(parseInt(route.params.id as string));

const connectFlows: Ref<IConnectFlow[]> = ref([]);
const getConnectFlows = () => {
  setLoading(true);
  ConnectFlowService.getAll<IConnectFlow[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  }).then((response) => (connectFlows.value = response.data));
};

watch([formState.levelId], () => {
  getConnectFlows();
});

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>()
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
      getConnectFlows();
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const id = formState.id || 0;
  NumberService.update<IDefaultServiceResult>(id, formState)
    .then((response: AxiosResponse<IDefaultServiceResult>) => {
      toast.success(response.data.message);
      v$.value.$reset();
      router.push({
        name: "ListNumber",
      });
    }, toastServiceError)
    .finally(() => setLoading(false));
}

const __returned__ = { route, props, formState, rules, v$, setLoading, number, getNumber, connectFlows, getConnectFlows, levelFilter, levels, getLevels, onSubmit, get UiButton() { return UiButton }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiMultiselect() { return UiMultiselect }, get UiPanel() { return UiPanel }, get UiTextInput() { return UiTextInput }, get Permission() { return Permission }, get FormAction() { return FormAction }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})