import { defineComponent as _defineComponent } from 'vue'
import { PhoneIcon } from "@heroicons/vue/solid";
import { computed, ComputedRef } from "vue";
import {
  CallType,
  ICall,
  ICallData,
  CallStatusClassColor,
  CallPlayerGetters,
} from "@/definitions";
import { UiPanel, UiButtonDownload } from "@/components";
import moment from "moment";
import {
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  ClockIcon,
} from "@heroicons/vue/outline";

import { formatSecToTime } from "@/utils/datetimeCommon";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallDetails',
  setup(__props, { expose: __expose }) {
  __expose();

const call: ComputedRef<ICall> = computed(
  () => store.getters[CallPlayerGetters.CALL],
);

const callData: ComputedRef<ICallData | null> = computed(() => call.value.data);

const duration = computed(() => {
  return moment(call.value?.endedAt).diff(
    moment(call.value?.answeredAt),
    "seconds",
  );
});

const percentageAttendant = computed(() => {
  if (callData.value !== null && callData.value.duration > 0) {
    return call.value.data.agentTalkPercentage + "%";
  } else {
    return "-";
  }
});

const percentageClient = computed(() => {
  if (callData.value !== null && callData.value.duration > 0) {
    return call.value.data.clientTalkPercentage + "%";
  } else {
    return "-";
  }
});

const percentageNonTalk = computed(() => {
  if (callData.value !== null && callData.value.duration > 0) {
    return (
      (
        ((call.value.data.duration -
          call.value.data.agentSilenceTime -
          call.value.data.clientTalkTime) *
          100) /
        call.value.data.duration
      )
        .toFixed(2)
        .toString() + "%"
    );
  } else {
    return "-";
  }
});

const __returned__ = { call, callData, duration, percentageAttendant, percentageClient, percentageNonTalk, get PhoneIcon() { return PhoneIcon }, get CallType() { return CallType }, get CallStatusClassColor() { return CallStatusClassColor }, get UiPanel() { return UiPanel }, get UiButtonDownload() { return UiButtonDownload }, get moment() { return moment }, get PhoneIncomingIcon() { return PhoneIncomingIcon }, get PhoneOutgoingIcon() { return PhoneOutgoingIcon }, get ClockIcon() { return ClockIcon }, get formatSecToTime() { return formatSecToTime } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})