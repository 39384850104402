import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import orderBy from "lodash.orderby";
import { toastServiceError } from "@/utils/notification";
import {
  CallType,
  ContactCenterReportActions,
  ContactCenterReportGetters,
  FilterActions,
  FilterGetters,
  ICallsStats,
  IContactCenterReportFilter,
  IContactCenterReportServiceGetInfoParams,
  IContactCenterReportServiceGetInfoResult,
  IDataItem,
  IFilterItem,
  ILevel,
  IUserAgentCallsStats,
  UiActions,
} from "@/definitions";
import { ContactCenterPerAgentReportFilters } from "@/views/pages/reports/contactCenterPerAgent/components/";
import {
  UiPageHeader,
  UiDatePicker,
  UiBadge,
  BarChart,
  CallTypePerUserBarChart,
  CallStatusPerUserBarChart,
} from "@/components";
import moment from "moment";
import { ContactCenterPerAgentReportService } from "@/services";

interface IContactCenterReportChart {
  title: string;
  category: Array<string>;
  series: Array<IDataItem<number[]>>;
  xAxisLabelFormatter: (value: number) => string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactCenterPerAgentReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const reportInfo: Ref<IContactCenterReportServiceGetInfoResult | null> =
  ref(null);

const headerStats = computed<IDataItem<string>[]>(() => {
  const callsStats: ICallsStats | undefined = reportInfo.value?.stats;

  if (callsStats == undefined) return [];

  const result: IDataItem<string>[] = [
    {
      name: "totalCalls",
      value: callsStats.total.toString(),
    },
    {
      name: "inboundCalls",
      value: callsStats.type[CallType.INBOUND].toString(),
    },
    {
      name: "outboundCalls",
      value: callsStats.type[CallType.OUTBOUND].toString(),
    },
    {
      name: "internalCalls",
      value: callsStats.type[CallType.INTERNAL].toString(),
    },
    {
      name: "uniqueNumbers",
      value: callsStats.uniqueNumbers.toString(),
    },
    // {
    //   name: "averageSilencePercentage",
    //   value: callsStats.averageSilencePercentage + "%",
    // },
    {
      name: "averageServiceTime",
      value: moment
        .utc((callsStats.averageServiceTime || 0) * 1000)
        .format("HH:mm:ss"),
    },
  ];

  return result;
});

const getChart = (
  title: string,
  prop: keyof IUserAgentCallsStats,
  color = "#BFDBFE",
  xAxisLabelFormatter: (value: number) => string,
) => {
  const topUsers = orderBy(
    reportInfo.value?.userAgentCallsStats,
    prop,
    "desc",
  ).slice(0, 10);

  const category: string[] = [];
  const serie: IDataItem<number[]> = {
    name: title,
    value: [],
    color,
  };

  for (let i = topUsers.length - 1; i >= 0; i--) {
    const user = topUsers[i];
    category.push(user.name);
    serie.value.push(user[prop] as number);
  }

  return {
    title,
    category,
    series: [serie],
    xAxisLabelFormatter,
  };
};

const xAxisLabelFormatterAsTime = (value: number) => {
  return moment.utc(value * 1000).format("HH:mm:ss");
};

const charts = computed<IContactCenterReportChart[]>(() => {
  return [
    getChart(
      t("report.contactCenterPerAgent.serviceTimePerUserBarChart.title"),
      "averageServiceTime",
      "#BFDBFE",
      xAxisLabelFormatterAsTime,
    ),
    // getChart(
    //   t("report.contactCenterPerAgent.silencePerUserBarChart.title"),
    //   "averageSilencePercentage",
    //   "#E9D5FF",
    //   (value: number) => {
    //     return value + "%";
    //   }
    // ),
    getChart(
      t("report.contactCenterPerAgent.durationPerUserBarChart.title"),
      "averageDuration",
      "#A5F3FC",
      xAxisLabelFormatterAsTime,
    ),
  ];
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});
const filter = computed<IContactCenterReportFilter>(
  () => store.getters[ContactCenterReportGetters.FILTER],
);
const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[ContactCenterReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: IContactCenterReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    type: filter.value.type?.value || null,
    status: filter.value.status?.value || null,
    user: filter.value.user?.id || null,
    number: filter.value.number?.number || null,
    serviceTimeRange: filter.value.serviceTimeRange?.value || null,
    userLabel: filter.value.userLabel?.id || null,
  };

  setLoading(true);

  ContactCenterPerAgentReportService.getInfo({
    params,
  })
    .then((response) => {
      reportInfo.value = response.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(ContactCenterReportActions.CLEAR_FILTER, filter.field);
}

watch([level, dateRange, activeFilters], () => {
  getReportData();
});

getReportData();

const __returned__ = { t, reportInfo, headerStats, getChart, xAxisLabelFormatterAsTime, charts, level, dateRange, filter, activeFilters, setLoading, getReportData, handleRemoveFilter, get ContactCenterPerAgentReportFilters() { return ContactCenterPerAgentReportFilters }, get UiPageHeader() { return UiPageHeader }, get UiDatePicker() { return UiDatePicker }, get UiBadge() { return UiBadge }, get BarChart() { return BarChart }, get CallTypePerUserBarChart() { return CallTypePerUserBarChart }, get CallStatusPerUserBarChart() { return CallStatusPerUserBarChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})