import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { WordService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import {
  UiPagination,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
  UiBadge,
  UiButton,
} from "@/components";
import WordListItem from "@/views/pages/word/components/WordListItem.vue";
import WordListSort from "@/views/pages/word/components/WordListSort.vue";
import WordListFilters from "@/views/pages/word/components/WordListFilters.vue";
import {
  WordGetters,
  WordActions,
  IWordServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IFilterItem,
  IWord,
  Permission,
  ILevel,
  FilterGetters,
} from "@/definitions";
import { AxiosResponse } from "axios";

const perPage = 10;


export default /*@__PURE__*/_defineComponent({
  __name: 'WordPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const words: Ref<IWord[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[WordGetters.SEARCH],
  set: (search: string) => store.dispatch(WordActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[WordGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(WordActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[WordGetters.PAGE],
  set: (page: number) => store.dispatch(WordActions.SET_PAGE, page),
});

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const filter = computed(() => store.getters[WordGetters.FILTER]);

const getWords = () => {
  const params: IWordServiceGetAllParams = {
    page: currentPage.value,
    call_participant: filter.value.callParticipant
      ? filter.value.callParticipant?.value
      : undefined,
    is_active: filter.value.isActive,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
  };

  setLoading(true);
  WordService.getAll<Array<IWord>>({
    params,
  })
    .then((response: AxiosResponse<IWord[]>) => {
      words.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch(
  [search, level, filter, orderBy],
  () => {
    if (currentPage.value === 1) getWords();
    else currentPage.value = 1;
  },
  { deep: true },
);

watch([currentPage], () => {
  getWords();
});

getWords();
const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[WordGetters.ACTIVE_FILTERS],
);
function handleRemoveFilter(filter: string) {
  store.dispatch(WordActions.CLEAR_FILTER, filter);
}

const __returned__ = { route, router, setLoading, words, search, sortMenuSelected, level, orderBy, currentPage, count, perPage, filter, getWords, handleRemoveSortClick, activeFilters, handleRemoveFilter, get UiPagination() { return UiPagination }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get UiBadge() { return UiBadge }, get UiButton() { return UiButton }, WordListItem, WordListSort, WordListFilters, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})