import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute, RouterLink } from "vue-router";
import { ConnectFlowAudioService, VaultService } from "@/services";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { IConnectFlowAudio, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiPanel } from "@/components";
import { AxiosResponse } from "axios";
import { getAudioFormat } from "@/utils/audio";


export default /*@__PURE__*/_defineComponent({
  __name: 'AudioDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const audio: Ref<IConnectFlowAudio | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getAudio = (id: number) => {
  setLoading(true);
  ConnectFlowAudioService.get<IConnectFlowAudio>(id)
    .then(async (response: AxiosResponse<IConnectFlowAudio>) => {
      audio.value = response.data;
      await getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const getAudioFile = async () => {
  if (audio.value !== undefined) {
    const { data } = await VaultService.getAudioAsset(
      audio.value.audioFilePath,
    );
    const blob = new Blob([data], {
      type: getAudioFormat(audio.value?.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

const audioData = ref();

getAudio(parseInt(route.params.id as string));

const __returned__ = { route, audio, setLoading, getAudio, getAudioFile, audioData, get RouterLink() { return RouterLink }, get goBack() { return goBack }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})