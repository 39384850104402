import { defineComponent as _defineComponent } from 'vue'
import {
  onMounted,
  ref,
  Ref,
  computed,
  WritableComputedRef,
  PropType,
} from "vue";
import {
  ConnectFlowModelingActions,
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IFlowNode,
  IMultiSelectOption,
  IQueue,
} from "@/definitions";
import {
  UiNode,
  UiButton,
  UiMultiselect,
  UiRightPanel,
  UiTextArea,
} from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { ReplyIcon } from "@heroicons/vue/outline";
import { required } from "@/utils/validators";
import { useI18n } from "vue-i18n";
import store from "@/store";

interface ITransferFormState {
  queue: null | IQueue;
  whisper: null | string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferToQueue',
  props: {
  node: {
    type: Object as PropType<IFlowNode>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const showForm = ref(false);

const nodeWritable: WritableComputedRef<IFlowNode> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.node.id,
    ),
  set: (value: IFlowNode) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const queueOptions = computed(
  () => store.getters["flow/" + ConnectFlowModelingGetters.QUEUE_OPTIONS],
);
const formState: Ref<ITransferFormState> = ref({
  queue: null,
  whisper: null,
});

const rules = computed<ValidationArgs<ITransferFormState>>(() => {
  const r: ValidationArgs<ITransferFormState> = {
    queue: {
      required,
    },
    whisper: {},
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<ITransferFormState>>> = useVuelidate(
  rules,
  formState,
);

const nodeText = computed(() => {
  let text = t("connectFlow.node.transferToQueue.empty");
  if (nodeWritable.value.data.related) {
    text = nodeWritable.value.data.related.label;
  } else if (nodeWritable.value.data.textToAudio) {
    text = t("connectFlow.node.transferToQueue.textToAudio");
  }
  return text;
});

const isFormCorrect = ref(true);

function removeNode() {
  store.dispatch("flow/" + ConnectFlowModelingActions.DELETE_NODE, {
    nodeId: nodeWritable.value.id,
    parentId: nodeWritable.value.parentId,
  });
}
async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();
  if (isFormCorrect.value) {
    nodeWritable.value.data.related = {
      value: formState.value.queue?.id,
      label: formState.value.queue?.name,
    } as IMultiSelectOption;
    nodeWritable.value.data.whisper = formState.value.whisper ?? undefined;
    showForm.value = false;
  }
  nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  v$.value.$reset();
}

onMounted(() => {
  if (nodeWritable.value.data.related !== null) {
    formState.value.queue = queueOptions.value.find(
      (queue: IQueue) => queue.id == nodeWritable.value.data.related?.value,
    );
  }

  if (nodeWritable.value.data.whisper !== undefined) {
    formState.value.whisper = nodeWritable.value.data.whisper;
  }

  v$.value.$validate().then((result) => {
    isFormCorrect.value = result;
    nodeWritable.value.data.hasErrors = !isFormCorrect.value;
  });
});

const __returned__ = { t, props, showForm, nodeWritable, queueOptions, formState, rules, v$, nodeText, isFormCorrect, removeNode, onSubmit, get UiNode() { return UiNode }, get UiButton() { return UiButton }, get UiMultiselect() { return UiMultiselect }, get UiRightPanel() { return UiRightPanel }, get UiTextArea() { return UiTextArea }, get ReplyIcon() { return ReplyIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})