import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import router from "@/router";
import { UiButton, UiGravatar } from "@/components";
import moment from "moment";
import {
  FolderIcon,
  CheckCircleIcon,
  XCircleIcon,
  PencilAltIcon,
  LockClosedIcon,
  ClockIcon,
} from "@heroicons/vue/outline";
import { IUser, Permission } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserListItem',
  props: {
  modelValue: {
    type: Object as PropType<IUser>,
    required: true,
  },
},
  emits: ["activate", "inactivate"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const levels = computed(() => props.modelValue.levels || []);
const limitedLevels = computed(() => levels.value.slice(0, 3));
const roles = computed(() => props.modelValue?.roles || []);

const handleActivateClick = () => {
  emit("activate", props.modelValue);
};

const handleInactivateClick = () => {
  emit("inactivate", props.modelValue);
};

const trimText = (text: string, limit: number) => {
  if (text.length <= limit) {
    return text;
  } else {
    return text.slice(0, limit - 3) + "...";
  }
};

const checkDevices = (device: Record<string, boolean>) => {
  return Object.values(device).every((value) => value === false);
};

const handleEditClick = () => {
  router.push({
    name: "EditUserById",
    params: {
      id: props.modelValue.id,
    },
  });
};

const __returned__ = { props, emit, levels, limitedLevels, roles, handleActivateClick, handleInactivateClick, trimText, checkDevices, handleEditClick, get UiButton() { return UiButton }, get UiGravatar() { return UiGravatar }, get moment() { return moment }, get FolderIcon() { return FolderIcon }, get CheckCircleIcon() { return CheckCircleIcon }, get XCircleIcon() { return XCircleIcon }, get PencilAltIcon() { return PencilAltIcon }, get LockClosedIcon() { return LockClosedIcon }, get ClockIcon() { return ClockIcon }, get Permission() { return Permission } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})