import { defineComponent as _defineComponent } from 'vue'
import { PropType } from "vue";
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from "@heroicons/vue/solid";
import { ISortMenuItem, ISortMenuItemDirection } from "@/definitions";
import { UiBadge } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiSortMenuBadge',
  props: {
  item: {
    type: Object as PropType<ISortMenuItem>,
    required: true,
  },
},
  emits: ["remove"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const handleRemoveClick = (item: ISortMenuItem) => {
  emit("remove", item);
};

const __returned__ = { props, emit, handleRemoveClick, get ArrowCircleDownIcon() { return ArrowCircleDownIcon }, get ArrowCircleUpIcon() { return ArrowCircleUpIcon }, get ISortMenuItemDirection() { return ISortMenuItemDirection }, get UiBadge() { return UiBadge } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})