import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, Ref, watch, WritableComputedRef } from "vue";
import { UiButton, UiRightPanel, UiTextInput } from "@/components";
import {
  ConnectFlowModelingGetters,
  ConnectFlowModelingMutations,
  IFlowConnection,
} from "@/definitions";
import store from "@/store";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { useI18n } from "vue-i18n";

interface IEdgeValidationFormState {
  timer: number | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormAwaitableEdge',
  props: {
  edgeId: {
    type: String,
  },
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();

const props = __props;

watch(
  () => props.modelValue,
  (show) => {
    internalShow.value = show;
    if (show) {
      if (edge.value?.data !== undefined) {
        formState.value.timer = edge.value.data?.timerWait ?? null;
      }
    }
  },
);

const emit = __emit;

const internalShow = ref(props.modelValue);
const handleToggle = (state: boolean) => {
  emit("update:modelValue", state);
};
watch(internalShow, () => {
  handleToggle(internalShow.value);
});

const edge: WritableComputedRef<IFlowConnection> = computed({
  get: () =>
    store.getters["flow/" + ConnectFlowModelingGetters.ELEMENT_BY_ID](
      props.edgeId,
    ),
  set: (value: IFlowConnection) => {
    store.commit("flow/" + ConnectFlowModelingMutations.UPDATE_ELEMENT, {
      idx: null,
      value,
    });
  },
});

const formState: Ref<IEdgeValidationFormState> = ref({
  timer: null,
});

const rules = computed<ValidationArgs<IEdgeValidationFormState>>(() => {
  const r: ValidationArgs<IEdgeValidationFormState> = {
    timer: {},
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IEdgeValidationFormState>>> =
  useVuelidate(rules, formState);

const isFormCorrect = ref(true);

async function onSubmit() {
  isFormCorrect.value = await v$.value.$validate();

  if (
    isFormCorrect.value &&
    edge.value.data != undefined &&
    formState.value.timer != null
  ) {
    edge.value.data.timerWait = formState.value.timer;
    edge.value.data.hasErrors = false;

    if (formState.value.timer) {
      edge.value.label = t("connectFlow.edge.labelTimer", {
        time: formState.value.timer,
      });
    } else {
      edge.value.label = "";
    }
    internalShow.value = false;
  }
  if (edge.value.data !== undefined) {
    edge.value.data.hasErrors = !isFormCorrect.value;
  }

  v$.value.$reset();
}

const __returned__ = { t, props, emit, internalShow, handleToggle, edge, formState, rules, v$, isFormCorrect, onSubmit, get UiButton() { return UiButton }, get UiRightPanel() { return UiRightPanel }, get UiTextInput() { return UiTextInput } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})