import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { WordService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiContainerSection,
  UiMultiselect,
} from "@/components";
import {
  IWord,
  ILevel,
  Permission,
  UiActions,
  IDefaultServiceResult,
  IWordServiceCreateResult,
  FormAction,
  ParticipantType,
  FilterGetters,
} from "@/definitions";
import { IWordFormState } from "@/definitions/ui-typings/IWordFormState";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";
import LevelService from "@/services/LevelService";
import { useI18n } from "vue-i18n";
import Analytics from "@/utils/analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<IWordFormState>({
  id: 0,
  levelId: 0,
  word: "",
  callParticipant: "",
  isActive: true,
});

const rules = computed<ValidationArgs<IWordFormState>>(() => ({
  id: {
    required,
  },
  levelId: {
    required,
  },
  word: {
    required,
    maxLength: maxLength(15),
  },
  callParticipant: {
    required,
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<IWordFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getWord = (id: number) => {
  setLoading(true);
  WordService.get<IWord>(id)
    .then((response: AxiosResponse<IWord>) => {
      const { id, word, callParticipant, levelId, isActive } = response.data;
      formState.id = id;
      formState.word = word;
      formState.callParticipant = callParticipant;
      formState.levelId = levelId;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
if (props.action == FormAction.EDIT) {
  getWord(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewWordById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    WordService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    WordService.create<IWordServiceCreateResult>(formState)
      .then((response: AxiosResponse<IWordServiceCreateResult>) => {
        Analytics.trackingAction(`NewWord`).then();
        handleServiceSuccess(response.data.message, response.data.result.id);
      }, toastServiceError)
      .finally(() => setLoading(false));
  }
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);

const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewLevelById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "ListLevel",
    });
};

const { t } = useI18n();
const participants = [
  {
    value: ParticipantType.All,
    label: t(`word.participant.${ParticipantType.All}`),
  },
  {
    value: ParticipantType.Agent,
    label: t(`word.participant.${ParticipantType.Agent}`),
  },
  {
    value: ParticipantType.Client,
    label: t(`word.participant.${ParticipantType.Client}`),
  },
];

const __returned__ = { route, props, formState, rules, v$, setLoading, getWord, onSubmit, levelFilter, levels, getLevels, handleBackClick, t, participants, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiContainerSection() { return UiContainerSection }, get UiMultiselect() { return UiMultiselect }, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})