import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { SurveyService, LevelService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { v4 as uuidv4 } from "uuid";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiMultiselect,
  UiTextArea,
  UiAudioUpload,
} from "@/components";
import {
  ISurvey,
  ILevel,
  Permission,
  UiActions,
  IDefaultServiceResult,
  FormAction,
  ISurveyForm,
  FilterGetters,
} from "@/definitions";
import { VaultService } from "@/services";
import { requiredIf } from "@/utils/validators";
import { SurveyFormQuestion } from "./components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { getAudioFormat } from "@/utils/audio";
import { AxiosResponse } from "axios";
import Analytics from "@/utils/analytics";


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<ISurveyForm>({
  id: 0,
  name: "",
  isActive: true,
  levelId: 0,
  audioFilePath: "",
  textToAudio: "",
  audio: undefined,
  questions: [],
});

const isTextAreaDisabled = computed(() => !!formState.audio);

const rules = computed<ValidationArgs<ISurveyForm>>(() => {
  const r: ValidationArgs<ISurveyForm> = {
    id: {},
    name: {
      required,
      maxLength: maxLength(100),
    },
    levelId: {
      required,
    },
    questions: { required },
    isActive: {
      required,
    },
    audioFilePath: {
      maxLength: maxLength(250),
    },
    textToAudio: {
      required: requiredIf(() => {
        return !formState.audio && !formState.audioFilePath;
      }),
    },
    audio: {
      required: requiredIf(() => {
        return (
          (formState.textToAudio?.length ?? 0) < 1 && !formState.audioFilePath
        );
      }),
    },
  };

  return r;
});

const v$: Ref<Validation<ValidationArgs<ISurveyForm>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getSurvey = (id: number) => {
  setLoading(true);
  SurveyService.get<ISurvey>(id)
    .then((response: AxiosResponse<ISurvey>) => {
      const { name, levelId, textToAudio, audioFilePath, isActive, questions } =
        response.data;
      formState.id = id;
      formState.name = name;
      formState.levelId = levelId;
      formState.isActive = isActive;
      formState.textToAudio = textToAudio || "";
      formState.audioFilePath = audioFilePath || "";
      formState.questions = (questions || []).map((question) => ({
        id: question.id,
        uuid: uuidv4(),
        levelId: question.levelId,
        label: question.label,
        rangeAnswer: question.rangeAnswer,
        audioFilePath: question.audioFilePath || "",
        textToAudio: question.textToAudio || "",
      }));
      getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

if (props.action == FormAction.EDIT) {
  getSurvey(parseInt(route.params.id as string));
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const formData = new FormData();

  formData.append("id", formState.id.toString());
  formData.append("levelId", formState.levelId.toString());
  formData.append("name", formState.name);
  formData.append("isActive", formState.isActive.toString());
  formData.append("textToAudio", formState.textToAudio || "");

  if (formState.audio) {
    formData.append("audio", formState.audio);
  }

  formState.questions.forEach((question, index) => {
    formData.append(`questions[${index}][id]`, question.id.toString());
    formData.append(
      `questions[${index}][levelId]`,
      question.levelId.toString(),
    );
    formData.append(`questions[${index}][label]`, question.label);
    formData.append(`questions[${index}][rangeAnswer]`, question.rangeAnswer);
    formData.append(
      `questions[${index}][textToAudio]`,
      question.textToAudio || "",
    );
    if (question.audio) {
      formData.append(`questions[${index}][question-audio]`, question.audio);
    }
    if (question.audioFilePath) {
      formData.append(
        `questions[${index}][audioFilePath]`,
        question.audioFilePath,
      );
    }
  });

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewSurveyById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    SurveyService.update(id, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    SurveyService.create(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: AxiosResponse) => {
        Analytics.trackingAction(`NewSurvey`).then();
        handleServiceSuccess(response.data.message, response.data.result.id);
      }, toastServiceError)
      .finally(() => setLoading(false));
  }
}

const handleFileUpdate = (file: File) => {
  if (file) {
    formState.audio = file;
  } else {
    formState.audio = null;
  }
};

const audioData = ref();
const getAudioFile = async () => {
  if (formState.audioFilePath) {
    const { data } = await VaultService.getAudioAsset(formState.audioFilePath);
    const blob = new Blob([data], {
      type: getAudioFormat(formState.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

const addQuestion = () => {
  const newQuestion = {
    id: 0,
    uuid: uuidv4(),
    levelId: formState.levelId,
    surveyId: formState.id,
    label: "",
    rangeAnswer: "",
    audioFilePath: "",
    textToAudio: "",
    isActive: true,
  };

  formState.questions.push(newQuestion);
};

const removeQuestion = (index: number) => {
  formState.questions.splice(index, 1);
};

const __returned__ = { route, props, formState, isTextAreaDisabled, rules, v$, setLoading, getSurvey, levelFilter, levels, getLevels, onSubmit, handleFileUpdate, audioData, getAudioFile, addQuestion, removeQuestion, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get UiMultiselect() { return UiMultiselect }, get UiTextArea() { return UiTextArea }, get UiAudioUpload() { return UiAudioUpload }, get Permission() { return Permission }, get FormAction() { return FormAction }, get SurveyFormQuestion() { return SurveyFormQuestion }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})