import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { AxiosResponse } from "axios";
import { goBack } from "@/utils/navigation";
import { useRoute, RouterLink } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import { Permission, UiActions, IUserLabel } from "@/definitions";
import { UiButton, UiPageHeader, UiGravatar, UiPanel } from "@/components";
import { MailIcon } from "@heroicons/vue/solid";
import { UserLabelService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const userLabel: Ref<IUserLabel | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getUserLabel = (id: number) => {
  setLoading(true);
  UserLabelService.get<IUserLabel>(id)
    .then((response: AxiosResponse<IUserLabel>) => {
      userLabel.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getUserLabel(parseInt(route.params.id as string));

const __returned__ = { route, userLabel, setLoading, getUserLabel, get goBack() { return goBack }, get RouterLink() { return RouterLink }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiGravatar() { return UiGravatar }, get UiPanel() { return UiPanel }, get MailIcon() { return MailIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})