import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IMultiSelectOption,
  IConnectFlowFilter,
  ConnectFlowGetters,
  ConnectFlowActions,
} from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filterOpened = ref(false);

const defaultFilter = computed(
  () => store.getters[ConnectFlowGetters.DEFAULT_FILTER],
);
const filter: Ref<IConnectFlowFilter> = ref({
  isActive: defaultFilter.value.isActive,
  status: defaultFilter.value.status,
  user: defaultFilter.value.user,
  serviceTimeRange: defaultFilter.value.serviceTimeRange,
});
const storeFilter = computed(() => store.getters[ConnectFlowGetters.FILTER]);

const isActiveOptions: Ref<IMultiSelectOption[]> = ref([
  {
    label: t("connectFlow.filters.isActive.options.actives"),
    value: true,
  },
  {
    label: t("connectFlow.filters.isActive.options.inactives"),
    value: false,
  },
]);

function applyFilters() {
  store.dispatch(ConnectFlowActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(ConnectFlowActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.isActive = storeFilter.value.isActive;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

const __returned__ = { t, filterOpened, defaultFilter, filter, storeFilter, isActiveOptions, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiRightPanel() { return UiRightPanel }, get UiMultiselect() { return UiMultiselect }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})