import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, Ref, ref, watch } from "vue";
import store from "@/store";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  ILevel,
  IMultiSelectOption,
  IWordFilter,
  WordGetters,
  WordActions,
  ParticipantType,
} from "@/definitions";
import LevelService from "@/services/LevelService";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const filterOpened = ref(false);

const defaultFilter = computed(() => store.getters[WordGetters.DEFAULT_FILTER]);

const filter: Ref<IWordFilter> = ref({
  callParticipant: defaultFilter.value.callParticipant,
  isActive: defaultFilter.value.isActive,
});

const storeFilter = computed(() => store.getters[WordGetters.FILTER]);

const levelOptions: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  }).then((response) => (levelOptions.value = response.data));
};

const { t } = useI18n();
const participants = [
  {
    value: ParticipantType.All,
    label: t(`word.participant.${ParticipantType.All}`),
  },
  {
    value: ParticipantType.Agent,
    label: t(`word.participant.${ParticipantType.Agent}`),
  },
  {
    value: ParticipantType.Client,
    label: t(`word.participant.${ParticipantType.Client}`),
  },
] as IMultiSelectOption[];

const isActiveFilterOptions = [
  {
    label: t("level.filters.isActive.options.actives"),
    value: true,
  },
  {
    label: t("level.filters.isActive.options.inactives"),
    value: false,
  },
] as IMultiSelectOption[];

function applyFilters() {
  store.dispatch(WordActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(WordActions.CLEAR_FILTER);
  refreshLocalFilter();
}
onMounted(() => {
  getLevels();
});
function refreshLocalFilter() {
  filter.value.callParticipant = storeFilter.value.callParticipant;
  filter.value.isActive = storeFilter.value.isActive;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

const __returned__ = { filterOpened, defaultFilter, filter, storeFilter, levelOptions, getLevels, t, participants, isActiveFilterOptions, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiRightPanel() { return UiRightPanel }, get UiMultiselect() { return UiMultiselect }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})