import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { UiButton } from "@/components";
import { CalendarIcon, FolderIcon } from "@heroicons/vue/solid";
import {
  CheckCircleIcon,
  XCircleIcon,
  PencilAltIcon,
  ArchiveIcon,
  PhoneIcon,
} from "@heroicons/vue/outline";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";
import { IConnectFlow, Permission } from "@/definitions";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowListItem',
  props: {
  modelValue: {
    type: Object as PropType<IConnectFlow>,
    required: true,
  },
},
  emits: ["activate", "inactivate"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const lastModifiedBy = computed(
  () =>
    props.modelValue.updatedByUser || props.modelValue.createdByUser || null,
);

const handleActivateClick = () => {
  emit("activate", props.modelValue);
};

const handleInactivateClick = () => {
  emit("inactivate", props.modelValue);
};

const __returned__ = { props, emit, lastModifiedBy, handleActivateClick, handleInactivateClick, get formatDateAsString() { return formatDateAsString }, get UiButton() { return UiButton }, get CalendarIcon() { return CalendarIcon }, get FolderIcon() { return FolderIcon }, get CheckCircleIcon() { return CheckCircleIcon }, get XCircleIcon() { return XCircleIcon }, get PencilAltIcon() { return PencilAltIcon }, get ArchiveIcon() { return ArchiveIcon }, get PhoneIcon() { return PhoneIcon }, get ConnectFlowIcon() { return ConnectFlowIcon }, get Permission() { return Permission }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})