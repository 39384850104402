import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { AxiosResponse } from "axios";
import { goBack } from "@/utils/navigation";
import { useRoute, RouterLink } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import { Permission, UiActions, INumber } from "@/definitions";
import {
  UiButton,
  UiPageHeader,
  UiContainerSection,
  UiGravatar,
  UiPanel,
} from "@/components";
import NumberService from "@/services/NumberService";
import { MailIcon } from "@heroicons/vue/solid";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const number: Ref<INumber | undefined> = ref(undefined);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getNumber = (id: number) => {
  setLoading(true);
  NumberService.get<INumber>(id)
    .then((response: AxiosResponse<INumber>) => {
      number.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getNumber(parseInt(route.params.id as string));

const __returned__ = { route, number, setLoading, getNumber, get goBack() { return goBack }, get RouterLink() { return RouterLink }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiContainerSection() { return UiContainerSection }, get UiGravatar() { return UiGravatar }, get UiPanel() { return UiPanel }, get MailIcon() { return MailIcon }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})