import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import {
  ConnectFlowAudioService,
  LevelService,
  VaultService,
} from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiPanel,
  UiMultiselect,
} from "@/components";
import {
  IConnectFlowAudio,
  ILevel,
  Permission,
  UiActions,
  IDefaultServiceResult,
  FormAction,
  IConnectFlowAudioForm,
  FilterGetters,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";
import { getAudioFormat } from "@/utils/audio";


export default /*@__PURE__*/_defineComponent({
  __name: 'AudioFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<IConnectFlowAudioForm>({
  id: 0,
  levelId: null,
  name: "",
  file: null,
  isActive: true,
});

const rules = computed<ValidationArgs<IConnectFlowAudioForm>>(() => {
  const r: ValidationArgs<IConnectFlowAudioForm> = {
    id: {},
    name: {
      required,
      maxLength: maxLength(500),
    },
    file: {},
    levelId: {
      required,
    },
    isActive: {
      required,
    },
  };

  if (props.action == FormAction.CREATE) {
    r.file = {
      required,
    };
  }

  return r;
});

const v$: Ref<Validation<ValidationArgs<IConnectFlowAudioForm>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getAudio = (id: number) => {
  setLoading(true);
  ConnectFlowAudioService.get<IConnectFlowAudio>(id)
    .then((response: AxiosResponse<IConnectFlowAudio>) => {
      const { name, levelId, audioFilePath, isActive } = response.data;
      formState.id = id;
      formState.name = name;
      formState.levelId = levelId;
      filePath.value = audioFilePath;
      formState.isActive = isActive;
      getAudioFile();
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

const filePath = ref();

const audioData = ref();
const getAudioFile = async () => {
  if (filePath.value !== undefined) {
    const { data } = await VaultService.getAudioAsset(filePath.value);
    const blob = new Blob([data], {
      type: getAudioFormat(filePath.value),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

if (props.action === FormAction.EDIT) {
  getAudio(parseInt(route.params.id as string));
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewConnectFlowAudioById",
      params: {
        id,
      },
    });
  };

  const formData = new FormData();

  formData.append("id", formState.id.toString());

  if (formState.file !== null) {
    formData.append("audio", formState.file);
  }
  if (formState.levelId) {
    formData.append("levelId", formState?.levelId?.toString());
  }
  formData.append("name", formState.name);
  formData.append("isActive", formState.isActive.toString());

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    ConnectFlowAudioService.update(id, formData)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    ConnectFlowAudioService.create(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(
        (response: AxiosResponse) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

function handleAudioUpload(event: Event) {
  const input = event.target as HTMLInputElement;
  if (!input.files) return;

  const file = input.files[0];

  if (file) {
    formState.file = file;
  } else {
    alert("Please select an audio file.");
    formState.file = null;
  }
}

const __returned__ = { route, props, formState, rules, v$, setLoading, getAudio, filePath, audioData, getAudioFile, levelFilter, levels, getLevels, onSubmit, handleAudioUpload, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiPanel() { return UiPanel }, get UiMultiselect() { return UiMultiselect }, get Permission() { return Permission }, get FormAction() { return FormAction }, get getAudioFormat() { return getAudioFormat } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})