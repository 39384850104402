import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { VueI18nTranslation } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPagination',
  props: {
  modelValue: {
    type: Number,
    required: true,
  },
  count: {
    type: Number,
    required: true,
  },
  perPage: {
    type: Number,
    required: true,
  },
  i18n: Function as PropType<VueI18nTranslation>,
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const pageCount = computed(() => {
  return Math.ceil(props.count / props.perPage);
});

const pages = computed(() => {
  const response: number[] = [];
  const current = props.modelValue;

  let start = current - 2 <= 0 ? 1 : current - 2;
  let end = current + 2 < pageCount.value ? current + 2 : pageCount.value;

  const diff = end - start;
  if (diff < 4 && end + (4 - diff) < pageCount.value) {
    end = end + (4 - diff);
  } else if (diff < 4 && start - (4 - diff) > 0) {
    start = start - (4 - diff);
  }

  for (let i = start; i <= end; i++) {
    response.push(i);
  }

  return response;
});

const paginationLabelData = computed(() => {
  let start = 1;
  let end = props.modelValue * props.perPage;
  const total = props.count;

  if (props.modelValue >= 1)
    start = props.modelValue * props.perPage - props.perPage + 1;

  if (props.modelValue == pageCount.value) end = props.count;

  return {
    start,
    end,
    total,
  };
});

const emit = __emit;
function goTo(page: number) {
  if (props.modelValue !== page) {
    emit("update:modelValue", page);
  }
}

const __returned__ = { props, pageCount, pages, paginationLabelData, emit, goTo, get ChevronLeftIcon() { return ChevronLeftIcon }, get ChevronRightIcon() { return ChevronRightIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})