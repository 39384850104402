import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiMultiselect, UiRightPanel } from "@/components";
import {
  IMultiSelectOption,
  SurveyGetters,
  SurveyActions,
  ISurveyFilter,
} from "@/definitions";
import { FilterIcon } from "@heroicons/vue/solid";


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyListFilters',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filtersOpened = ref(false);

const filter: Ref<ISurveyFilter> = ref({
  isActive: null,
});

const storeFilters = computed<ISurveyFilter>(
  () => store.getters[SurveyGetters.FILTER],
);

const isActiveFilterOptions = [
  {
    label: t("survey.filters.isActive.options.actives"),
    value: true,
  },
  {
    label: t("survey.filters.isActive.options.inactives"),
    value: false,
  },
] as IMultiSelectOption[];

function applyFilters() {
  store.dispatch(SurveyActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(SurveyActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.isActive = storeFilters.value.isActive;
}

watch(filtersOpened, () => {
  refreshLocalFilter();
});

const __returned__ = { t, filtersOpened, filter, storeFilters, isActiveFilterOptions, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiMultiselect() { return UiMultiselect }, get UiRightPanel() { return UiRightPanel }, get FilterIcon() { return FilterIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})