import { defineComponent as _defineComponent } from 'vue'
import { ref, computed } from "vue";
import { CloudUploadIcon, XIcon } from "@heroicons/vue/outline";
import { ErrorObject } from "@vuelidate/core";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiAudioUpload',
  props: {
  index: {
    type: Number,
    required: false,
  },
  action: {
    type: String,
    default: "view",
  },
  file: {
    type: File,
    default: null,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  textRule: {
    type: String,
  },
  errors: {
    type: Array<ErrorObject>,
    default: [],
  },
  silentErrors: {
    type: Array<ErrorObject>,
    default: [],
  },
},
  emits: ["update:file"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const fileName = ref("");

function handleAudioUpload(event: Event) {
  const input = event.target as HTMLInputElement;
  if (!input.files) return;

  const file = input.files[0];

  if (file) {
    emit("update:file", file);
    fileName.value = file.name;
  } else {
    emit("update:file", null);
    fileName.value = "";
  }
}

function removeFile() {
  emit("update:file", null);
  fileName.value = "";
}
const disabledClass = computed(() =>
  props.disabled ? "bg-gray-200 text-gray-400" : "",
);

const hasErros = computed(
  () => props.errors.length || props.silentErrors.length,
);

const __returned__ = { props, emit, fileName, handleAudioUpload, removeFile, disabledClass, hasErros, get CloudUploadIcon() { return CloudUploadIcon }, get XIcon() { return XIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})