import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import { LockClosedIcon } from "@heroicons/vue/solid";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiPanel',
  props: {
  noPadding: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  textLock: {
    type: String,
    default: "",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const defaultClasses = computed(() => {
  let classes = "h-full ";
  if (!props.noPadding) {
    classes += "p-8";
  }

  return classes;
});

const __returned__ = { props, defaultClasses, get LockClosedIcon() { return LockClosedIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})