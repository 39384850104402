import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import orderBy from "lodash.orderby";
import { useI18n } from "vue-i18n";
import { BarChart } from "@/components";
import {
  CallStatus,
  IDataItem,
  IUserAgentCallsStats,
  CallStatusColor,
} from "@/definitions";

interface ChartData {
  category: Array<string>;
  series: Array<IDataItem<number[]>>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CallStatusPerUserBarChart',
  props: {
  title: {
    type: String,
    required: true,
  },
  data: {
    type: Array<IUserAgentCallsStats>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const chartData = computed<ChartData>(() => {
  const topUsers = orderBy(
    props.data,
    (user) => {
      return (
        user.status[CallStatus.ANSWERED] +
        user.status[CallStatus.NO_ANSWER] +
        user.status[CallStatus.BUSY] +
        user.status[CallStatus.DITCHED]
      );
    },
    "desc",
  ).slice(0, 10);

  const category: string[] = [];
  const series: IDataItem<number[]>[] = [
    {
      value: [],
      name: t("core.options.call.status.answered"),
      color: CallStatusColor[CallStatus.ANSWERED],
    },
    {
      value: [],
      name: t("core.options.call.status.no_answer"),
      color: CallStatusColor[CallStatus.NO_ANSWER],
    },
    {
      value: [],
      name: t("core.options.call.status.busy"),
      color: CallStatusColor[CallStatus.BUSY],
    },
    {
      value: [],
      name: t("core.options.call.status.ditched"),
      color: CallStatusColor[CallStatus.DITCHED],
    },
  ];

  for (let i = topUsers.length - 1; i >= 0; i--) {
    const user = topUsers[i];

    category.push(user.name);

    series[0].value.push(user.status[CallStatus.ANSWERED]);
    series[1].value.push(user.status[CallStatus.NO_ANSWER]);
    series[2].value.push(user.status[CallStatus.BUSY]);
    series[3].value.push(user.status[CallStatus.DITCHED]);
  }

  return {
    category,
    series,
  };
});

const __returned__ = { t, props, chartData, get BarChart() { return BarChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})