import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "timeline py-10" }
const _hoisted_2 = { class: "mx-auto w-full h-full" }
const _hoisted_3 = { class: "relative wrap h-full" }
const _hoisted_4 = { class: "flex sm:justify-between items-top w-full mb-5" }
const _hoisted_5 = { class: "z-20 flex flex-row-reverse sm:flex-col items-center order-1 w-12/12 sm:w-4/12" }
const _hoisted_6 = { class: "text-center sm:pb-3 bg-gray-100" }
const _hoisted_7 = { class: "font-semibold text-lg text-gray-700" }
const _hoisted_8 = { class: "text-gray-500 text-xs" }
const _hoisted_9 = { class: "z-20 bg-gray-600 shadow w-10 h-10 rounded-full mr-5 sm:mr-0" }
const _hoisted_10 = { class: "mx-auto font-semibold text-lg text-white" }
const _hoisted_11 = { class: "flex sm:justify-between items-top w-full mb-5" }
const _hoisted_12 = { class: "z-20 flex sm:flex-col items-center order-1 w-12/12 sm:w-4/12" }
const _hoisted_13 = { class: "z-20 bg-gray-600 shadow w-10 h-10 rounded-full mr-3 sm:mr-0" }
const _hoisted_14 = { class: "mx-auto font-semibold text-lg text-white" }
const _hoisted_15 = { class: "text-center sm:pt-3 bg-gray-100" }
const _hoisted_16 = { class: "font-semibold text-lg" }
const _hoisted_17 = { class: "text-gray-500 text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "border-4 absolute border-opacity-70 opacity-70 bg-gray-500 h-full left-5 sm:left-1/2 pt-3",
          style: {"margin-left":"-4px"}
        }, null, -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "order-1 w-0 sm:w-4/12" }, null, -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("call.timeline.start")), 1 /* TEXT */),
              _createElementVNode("p", _hoisted_8, _toDisplayString($setup.moment($props.call.startedAt).format(_ctx.$t("core.dateTimeFormat"))), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h1", _hoisted_10, [
                _createVNode($setup["CheckCircleIcon"])
              ])
            ])
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "order-1 w-0 sm:w-4/12" }, null, -1 /* HOISTED */))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.timeline, (item) => {
          return (_openBlock(), _createBlock($setup["CallTimelineItem"], {
            key: `call${$props.call.id}-timeline-item-${item.id}`,
            item: item
          }, null, 8 /* PROPS */, ["item"]))
        }), 128 /* KEYED_FRAGMENT */)),
        _createElementVNode("div", _hoisted_11, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "order-1 w-0 sm:w-4/12" }, null, -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("h1", _hoisted_14, [
                _createVNode($setup["XCircleIcon"])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t("call.timeline.end")), 1 /* TEXT */),
              _createElementVNode("p", _hoisted_17, _toDisplayString($setup.moment($props.call.startedAt)
                    .add($props.call.duration, "seconds")
                    .format(_ctx.$t("core.dateTimeFormat"))), 1 /* TEXT */)
            ])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "order-1 w-0 sm:w-4/12" }, null, -1 /* HOISTED */))
        ])
      ])
    ])
  ]))
}