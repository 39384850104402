import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import ConnectFlowService from "@/services/ConnectFlowService";
import { toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import { IConnectFlow, Permission, UiActions } from "@/definitions";
import { UiButton, UiPageHeader, UiContainerSection } from "@/components";
import { AxiosResponse } from "axios";
import { applyPhoneMask } from "../../../utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowDetailPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const connectFlow: Ref<IConnectFlow | undefined> = ref(undefined);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getConnectFlow = (id: number) => {
  setLoading(true);
  ConnectFlowService.get<IConnectFlow>(id)
    .then((response: AxiosResponse<IConnectFlow>) => {
      connectFlow.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

getConnectFlow(parseInt(route.params.id as string));

const __returned__ = { route, connectFlow, setLoading, getConnectFlow, get goBack() { return goBack }, get Permission() { return Permission }, get UiButton() { return UiButton }, get UiPageHeader() { return UiPageHeader }, get UiContainerSection() { return UiContainerSection }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})