import { defineComponent as _defineComponent } from 'vue'
import { ICallInsight } from "@/definitions";
import UiBadge from "@/components/UiBadge.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InsightBlock',
  props: {
  persona: {
    type: String,
    required: true,
  },
  insights: {
    type: Array as () => Array<ICallInsight>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { UiBadge }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})