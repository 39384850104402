import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "bg-gray-100 hover:bg-white my-2 grid grid-cols-9 p-3 items-center rounded ring-1 ring-black ring-opacity-5" }
const _hoisted_2 = { class: "md:col-span-4 col-span-9 flex items-center mb-3 md:mb-0" }
const _hoisted_3 = { class: "mr-2" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "font-semibold text-gray-800 flex items-center space-x-2" }
const _hoisted_6 = { class: "text-sm text-gray-700" }
const _hoisted_7 = { class: "md:col-span-2 col-span-3 flex items-center" }
const _hoisted_8 = { class: "flex flex-wrap" }
const _hoisted_9 = {
  key: 0,
  class: "md:col-span-2 col-span-5 flex items-center px-3 md:px-0"
}
const _hoisted_10 = { class: "flex flex-wrap pr-3 md:pr-0" }
const _hoisted_11 = {
  key: 0,
  class: "text-xs text-gray-600 md:hidden"
}
const _hoisted_12 = {
  key: 1,
  class: "md:col-span-2 col-span-5"
}
const _hoisted_13 = {
  key: 2,
  class: "flex items-center justify-end"
}
const _hoisted_14 = { class: "sr-only" }
const _hoisted_15 = { class: "sr-only" }
const _hoisted_16 = { class: "sr-only" }
const _hoisted_17 = { class: "col-span-9 mt-3 pt-3 border-t grid grid-cols-1 gap-3 lg:gap-0 lg:grid-cols-4" }
const _hoisted_18 = { class: "flex flex-wrap items-center space-x-0.5 text-xs text-gray-500" }
const _hoisted_19 = {
  key: 0,
  class: "px-1.5 py-0.5 rounded text-xs border bg-gray-50 text-gray-500"
}
const _hoisted_20 = {
  key: 1,
  class: "px-1.5 py-0.5 rounded text-xs border bg-gray-50 text-gray-500"
}
const _hoisted_21 = {
  key: 2,
  class: "px-1.5 py-0.5 rounded text-xs border bg-gray-50 text-gray-500"
}
const _hoisted_22 = {
  key: 3,
  class: "px-1.5 py-0.5 rounded text-xs border bg-gray-50 text-gray-500"
}
const _hoisted_23 = { key: 4 }
const _hoisted_24 = { class: "flex flex-wrap items-center space-x-0.5 text-xs text-gray-500" }
const _hoisted_25 = {
  key: 0,
  class: "px-1.5 py-0.5 rounded text-xs border bg-gray-50 text-gray-500"
}
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "flex items-center justify-end text-xs text-gray-500" }
const _hoisted_28 = { class: "flex flex-wrap" }
const _hoisted_29 = { class: "flex items-center justify-end text-xs text-gray-500" }
const _hoisted_30 = { class: "flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode($setup["UiGravatar"], {
          email: $setup.props.modelValue.email,
          name: $setup.props.modelValue.name,
          class: "h-12 w-12 rounded-full ring-1 ring-black ring-opacity-5"
        }, null, 8 /* PROPS */, ["email", "name"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_USER) ? 'RouterLink' : 'span'), {
            to: {
              name: 'ViewUserById',
              params: {
                id: $setup.props.modelValue?.id,
              },
            },
            class: "text-primary"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString($setup.trimText($setup.props.modelValue.name, 25)), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["to"])),
          _createElementVNode("span", {
            class: _normalizeClass([
              $setup.props.modelValue?.isActive
                ? 'bg-green-100 text-green-700'
                : 'bg-red-100 text-red-700',
              'px-2 py-0.5 rounded text-xs border',
            ])
          }, _toDisplayString($setup.props.modelValue.isActive
                ? _ctx.$t("core.options.Active")
                : _ctx.$t("core.options.Inactive")), 3 /* TEXT, CLASS */)
        ]),
        _createElementVNode("span", _hoisted_6, _toDisplayString($setup.props.modelValue.email), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode($setup["LockClosedIcon"], {
        class: "mr-1 h-5 w-5 flex-shrink-0 text-gray-400",
        "aria-hidden": "true"
      }),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roles, (role, index) => {
          return (_openBlock(), _createElementBlock("p", {
            key: `role${role.id}`,
            class: _normalizeClass([
            index != $setup.roles.length - 1 ? 'mr-0.5' : '',
            'text-xs font-medium text-gray-500',
          ])
          }, _toDisplayString(role.name) + _toDisplayString(index != $setup.roles.length - 1 ? "," : ""), 3 /* TEXT, CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    (_ctx.$can($setup.Permission.VIEW_LEVELS))
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode($setup["FolderIcon"], {
            class: "mr-1 h-5 w-5 flex-shrink-0 text-gray-400",
            "aria-hidden": "true"
          }),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.levels, (level, index) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_LEVEL) ? 'RouterLink' : 'span'), {
                key: level.id,
                to: {
            name: 'ViewLevelById',
            params: {
              id: level.id,
            },
          },
                class: _normalizeClass([
            index != $setup.levels.length - 1 ? 'mr-0.5' : '',
            'text-xs font-medium text-primary hidden md:block',
          ])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(level.name) + _toDisplayString(index != $setup.levels.length - 1 ? "," : ""), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"]))
            }), 128 /* KEYED_FRAGMENT */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.limitedLevels, (level, index) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$can($setup.Permission.VIEW_LEVEL) ? 'RouterLink' : 'span'), {
                key: level.id,
                to: {
            name: 'ViewLevelById',
            params: {
              id: level.id,
            },
          },
                class: _normalizeClass([
            index != $setup.limitedLevels.length - 1 ? 'mr-0.5' : '',
            'text-xs font-medium text-primary md:hidden',
          ])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(level.name) + _toDisplayString(index != $setup.limitedLevels.length - 1 ? "," : ""), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"]))
            }), 128 /* KEYED_FRAGMENT */)),
            ($setup.limitedLevels.length > 2)
              ? (_openBlock(), _createElementBlock("p", _hoisted_11, " ..." + _toDisplayString(_ctx.$t("core.actions.Edit")) + " " + _toDisplayString($setup.levels.length - 3), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_12)),
    (_ctx.$can($setup.Permission.EDIT_USER))
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          ($setup.props.modelValue?.isActive)
            ? _withDirectives((_openBlock(), _createBlock($setup["UiButton"], {
                key: 0,
                size: "",
                shape: "rounded-full",
                variant: "inherit",
                "text-variant": "danger",
                shadow: "none",
                themed: "",
                class: "border-transparent focus:ring-danger shadow-none text-sm",
                onClick: $setup.handleInactivateClick
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("core.actions.Inactivate")), 1 /* TEXT */),
                  _createVNode($setup["XCircleIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              })), [
                [_directive_tippy, _ctx.$t('core.actions.Inactivate')]
              ])
            : _withDirectives((_openBlock(), _createBlock($setup["UiButton"], {
                key: 1,
                size: "",
                shape: "rounded-full",
                variant: "inherit",
                "text-variant": "success",
                shadow: "none",
                themed: "",
                class: "border-transparent focus:ring-success shadow-none text-sm",
                onClick: $setup.handleActivateClick
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("core.actions.Activate")), 1 /* TEXT */),
                  _createVNode($setup["CheckCircleIcon"], {
                    class: "h-6 w-6",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1 /* STABLE */
              })), [
                [_directive_tippy, _ctx.$t('core.actions.Activate')]
              ]),
          _withDirectives((_openBlock(), _createBlock($setup["UiButton"], {
            size: "",
            shape: "rounded-full",
            variant: "inherit",
            "text-variant": "primary",
            shadow: "none",
            themed: "",
            class: "border-transparent focus:ring-primary shadow-none ml-1 text-sm",
            onClick: $setup.handleEditClick
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t("core.actions.Edit")), 1 /* TEXT */),
              _createVNode($setup["PencilAltIcon"], {
                class: "h-6 w-6",
                "aria-hidden": "true"
              })
            ]),
            _: 1 /* STABLE */
          })), [
            [_directive_tippy, _ctx.$t('core.actions.Edit')]
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("core.entity.Devices")) + ":", 1 /* TEXT */),
        ($props.modelValue.devices.mobile)
          ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$t("user.labels.devices.mobile")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($props.modelValue.devices.desktop)
          ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.$t("user.labels.devices.desktop")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($props.modelValue.devices.web)
          ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$t("user.labels.devices.web")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($props.modelValue.devices.external)
          ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t("user.labels.devices.external")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($setup.checkDevices($props.modelValue.devices))
          ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.$t("user.labels.devices.null")), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("number.labels.extension")) + ":", 1 /* TEXT */),
        ($props.modelValue.extension?.number)
          ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString($props.modelValue.extension?.number), 1 /* TEXT */))
          : (_openBlock(), _createElementBlock("span", _hoisted_26, "-"))
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createVNode($setup["ClockIcon"], {
          class: "mr-0.5 h-4 w-4 flex-shrink-0 text-gray-400",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("user.labels.lastLoginDevice")) + ": " + _toDisplayString($props.modelValue.lastDeviceLogin
              ? $setup.moment($props.modelValue.lastDeviceLogin).format("DD/MM/YYYY HH:mm:ss")
              : "-"), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createVNode($setup["ClockIcon"], {
          class: "mr-0.5 h-4 w-4 flex-shrink-0 text-gray-400",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t("user.labels.lastLoginPortal")) + ": " + _toDisplayString($props.modelValue.lastLogin
              ? $setup.moment($props.modelValue.lastLogin).format("DD/MM/YYYY HH:mm:ss")
              : "-"), 1 /* TEXT */)
      ])
    ])
  ]))
}