import { defineComponent as _defineComponent } from 'vue'
import {
  ref,
  Ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
  WritableComputedRef,
  ComputedRef,
} from "vue";
import {
  AudioPlayerActions,
  AudioPlayerMutations,
  AudioPlayerGetters,
  ICall,
  ICallTimelineItem,
  ICallInsight,
  IWordsByPersona,
  ICallWord,
  ParticipantType,
  CallPlayerGetters,
  CallPlayerMutations,
} from "@/definitions";
import { formatSecToTime } from "@/utils/datetimeCommon";
import store from "@/store";
import {
  PlayIcon,
  PauseIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/solid";
import CallPlayerBullets from "@/views/pages/call/components/CallPlayerBullets.vue";
import PlaySearchWord from "@/views/pages/call/components/PlaySearchWord.vue";
import { UiPanel, UiSpinner, UiButton } from "@/components";
import { VaultService } from "@/services";
import { getAudioFormat } from "@/utils/audio";
import { map, lowerCase } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallPlayer',
  setup(__props, { expose: __expose }) {
  __expose();

const audioElement: Ref<HTMLElement | null> = ref(null);

const call: ComputedRef<ICall> = computed(
  () => store.getters[CallPlayerGetters.CALL],
);

const searchWord: Ref<string> = ref("");

const timeline: WritableComputedRef<ICallTimelineItem[]> = computed({
  get: () => store.getters[CallPlayerGetters.TIMELINE],
  set: (value: ICallTimelineItem[]) => {
    store.commit(CallPlayerMutations.SET_TIMELINE, value);
  },
});

const words: WritableComputedRef<IWordsByPersona> = computed({
  get: () => store.getters[CallPlayerGetters.WORDS],
  set: (value: IWordsByPersona) => {
    store.commit(CallPlayerMutations.SET_WORDS, {
      value,
    });
  },
});

const getAudioFile = async () => {
  if (call.value.audioFilePath !== undefined) {
    const { data } = await VaultService.getCallAudio(call.value.id);
    const blob = new Blob([data], {
      type: getAudioFormat(call.value.audioFilePath),
    });

    audioData.value = URL.createObjectURL(blob);
  }
};

const audioData = ref();

onMounted(() => {
  getAudioFile().then(() => {
    store.dispatch(AudioPlayerActions.INIT_INSTANCE, {
      element: audioElement.value,
      fileUrl: audioData.value,
    });
  });
});

onUnmounted(() => {
  if (isPlaying.value) {
    playPause();
  }
  store.commit(AudioPlayerMutations.UNSET_INSTANCE);
});

const waveInstance = computed(() => store.getters[AudioPlayerGetters.INSTANCE]);
const playerReady = computed(
  () => store.getters[AudioPlayerGetters.PLAYER_READY],
);
const playerError = computed(
  () => store.getters[AudioPlayerGetters.PLAYER_ERROR],
);
const isPlaying = computed(() =>
  playerReady.value ? waveInstance.value?.isPlaying() : false,
);
const secondsDuration = computed(
  () => store.getters[AudioPlayerGetters.SECONDS_DURATION],
);
const secondsCurrent = computed(
  () => store.getters[AudioPlayerGetters.SECONDS_CURRENT],
);
const loadingPercentage = computed(
  () => store.getters[AudioPlayerGetters.LOADING_PERCENTAGE],
);

const playedPercentage = computed(
  () => ((secondsCurrent.value * 100) / secondsDuration.value).toFixed(2) + "%",
);

function playPause() {
  store.dispatch(AudioPlayerActions.PLAY);
}

function changeSpeed() {
  let rate = waveInstance.value?.getPlaybackRate() ?? 1;
  if (rate === 1) {
    rate = 1.1;
  } else if (rate === 1.1) {
    rate = 1.2;
  } else if (rate === 1.2) {
    rate = 1;
  }
  waveInstance.value?.setPlaybackRate(rate);
}
const getPlaybackRate = computed(() => waveInstance.value?.getPlaybackRate());

const searchInsights: Ref<ICallInsight[]> = ref([]);
function filterInsights(item: ICallTimelineItem, participantType: string) {
  const isAgentInsight =
    item.participant === participantType && item.insights.length > 0;

  const insightsAgent = searchInsights.value.filter(
    (insight) => insight.participant === participantType,
  );

  if (insightsAgent.length) {
    return (
      insightsAgent.find((insight) => {
        return (
          item.insights.findIndex(
            (timeLineInsight) => timeLineInsight.text === insight.text,
          ) > -1
        );
      }) !== undefined && isAgentInsight
    );
  }
  return isAgentInsight;
}

const agentWordsBullets = computed(() =>
  timeline.value?.filter(
    (item) =>
      item.participant === ParticipantType.Agent &&
      item.registeredWords !== undefined &&
      item.registeredWords.length > 0,
  ),
);
const agentInsightsBullets = computed(() =>
  timeline.value?.filter((item) => filterInsights(item, ParticipantType.Agent)),
);
const agentSearchWordsBullets = computed(() =>
  timeline.value?.filter(
    (item) =>
      item.participant === ParticipantType.Agent &&
      item.matchedWords !== undefined &&
      item.matchedWords.length,
  ),
);

const clientWordsBullets = computed(() =>
  timeline.value?.filter(
    (item) =>
      item.participant === ParticipantType.Client &&
      item.registeredWords !== undefined &&
      item.registeredWords.length,
  ),
);
const clientInsightsBullets = computed(() =>
  timeline.value?.filter((item) => {
    if (item.insights !== undefined) {
      return filterInsights(item, ParticipantType.Client);
    }
  }),
);
const clientSearchWordsBullets = computed(() =>
  timeline.value?.filter(
    (item) =>
      item.participant === ParticipantType.Client &&
      item.matchedWords !== undefined &&
      item.matchedWords.length,
  ),
);

watch(
  [words, searchWord],
  () => {
    const selectedWordsClient: ICallWord[] = words.value.client.filter(
      (item) => item.selected,
    );
    const selectedWordsAgent: ICallWord[] = words.value.agent.filter(
      (item) => item.selected,
    );

    if (searchWord.value !== "") {
      selectedWordsAgent.push({
        text: searchWord.value,
        counter: 0,
      });
      selectedWordsClient.push({
        text: searchWord.value,
        counter: 0,
      });
    }

    timeline.value?.forEach((item) => {
      item.matchedWords = [];
    });

    selectedWordsClient.forEach((word) => {
      timeline.value?.forEach((item) => {
        if (item.participant === ParticipantType.Client) {
          const itemWords = map(item.words, lowerCase);
          if (itemWords.includes(word.text.toLowerCase())) {
            item.matchedWords.push(word);
          }
        }
      });
    });

    selectedWordsAgent.forEach((word) => {
      timeline.value?.forEach((item) => {
        if (item.participant === ParticipantType.Agent) {
          const itemWords = map(item.words, lowerCase);
          if (itemWords.includes(word.text.toLowerCase())) {
            item.matchedWords.push(word);
          }
        }
      });
    });
  },
  { deep: true },
);

const __returned__ = { audioElement, call, searchWord, timeline, words, getAudioFile, audioData, waveInstance, playerReady, playerError, isPlaying, secondsDuration, secondsCurrent, loadingPercentage, playedPercentage, playPause, changeSpeed, getPlaybackRate, searchInsights, filterInsights, agentWordsBullets, agentInsightsBullets, agentSearchWordsBullets, clientWordsBullets, clientInsightsBullets, clientSearchWordsBullets, get formatSecToTime() { return formatSecToTime }, get PlayIcon() { return PlayIcon }, get PauseIcon() { return PauseIcon }, get ExclamationCircleIcon() { return ExclamationCircleIcon }, CallPlayerBullets, PlaySearchWord, get UiPanel() { return UiPanel }, get UiSpinner() { return UiSpinner }, get UiButton() { return UiButton } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})