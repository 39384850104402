import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import SpamNumberService from "@/services/SpamNumberService";
import { toastServiceError } from "@/utils/notification";
import {
  UiPagination,
  UiSortMenuBadge,
  UiPageHeader,
  UiListSearch,
  UiBadge,
  UiButton,
} from "@/components";
import SpamNumberListItem from "@/views/pages/spamNumber/components/SpamNumberListItem.vue";
import SpamNumberListSort from "@/views/pages/spamNumber/components/SpamNumberListSort.vue";
import {
  ISpamNumber,
  NumberGetters,
  NumberActions,
  ISpamNumberServiceGetAllParams,
  UiActions,
  ISortMenuItem,
  IFilterItem,
  ILevel,
  FilterGetters,
  Permission,
} from "@/definitions";
import { AxiosResponse } from "axios";
import { PhoneIcon } from "@heroicons/vue/solid";

const perPage = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'SpamNumberPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const numbers: Ref<ISpamNumber[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[NumberGetters.SEARCH],
  set: (search: string) => store.dispatch(NumberActions.SET_SEARCH, search),
});

const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[NumberGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(NumberActions.SET_SORT, sort),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const orderBy = computed<string>(() =>
  sortMenuSelected.value.map((s) => s.direction + s.key).join(","),
);

const currentPage = computed<number>({
  get: () => store.getters[NumberGetters.PAGE],
  set: (page: number) => store.dispatch(NumberActions.SET_PAGE, page),
});

const count = ref(0);
if (route.query.clearStateOnSetup) {
  search.value = "";
  sortMenuSelected.value = [];
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getSpamNumbers = () => {
  const params: ISpamNumberServiceGetAllParams = {
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
    search: search.value,
  };

  setLoading(true);
  SpamNumberService.getAll<Array<ISpamNumber>>({
    params,
  })
    .then((response: AxiosResponse<ISpamNumber[]>) => {
      numbers.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};

watch([level, search, orderBy, currentPage], () => {
  if (currentPage.value === 1) getSpamNumbers();
  else currentPage.value = 1;
});

watch([currentPage], () => {
  getSpamNumbers();
});

getSpamNumbers();
const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[NumberGetters.ACTIVE_FILTERS],
);
function handleRemoveFilter(filter: string) {
  store.dispatch(NumberActions.CLEAR_FILTER, filter);
}

const __returned__ = { route, router, setLoading, numbers, search, sortMenuSelected, level, orderBy, currentPage, count, perPage, getSpamNumbers, handleRemoveSortClick, activeFilters, handleRemoveFilter, get UiPagination() { return UiPagination }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get UiBadge() { return UiBadge }, get UiButton() { return UiButton }, SpamNumberListItem, SpamNumberListSort, get Permission() { return Permission }, get PhoneIcon() { return PhoneIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})