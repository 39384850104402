import { defineComponent as _defineComponent } from 'vue'
import { formatSecToTime } from "@/utils/datetimeCommon";
import { IUserDetails } from "@/definitions/chart-typings/Realtime";
import { computed, onUnmounted, ref, Ref, watch } from "vue";
import RealtimeReportService from "@/services/RealtimeReportService";
import { toastServiceError } from "@/utils/notification";
import { UiSpinner } from "@/components";
import { FilterGetters, ILevel, UserDeviceStatus } from "@/definitions";
import { applyPhoneMask } from "@/utils/number";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersExtensionsDetailsTable',
  setup(__props, { expose: __expose }) {
  __expose();

const loading = ref(true);

const items: Ref<IUserDetails[]> = ref([]);

const getData = () => {
  loading.value = true;
  RealtimeReportService.getAgentExtensions<IUserDetails[]>()
    .then((response) => {
      items.value = response.data;
    }, toastServiceError)
    .finally(() => (loading.value = false));
};

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
watch(level, () => {
  items.value = [];
  clearInterval(interval);
  interval = setInterval(getData, 5000);
});

let interval = setInterval(getData, 5000);
onUnmounted(() => {
  clearInterval(interval);
});

const __returned__ = { loading, items, getData, level, get interval() { return interval }, set interval(v) { interval = v }, get formatSecToTime() { return formatSecToTime }, get UiSpinner() { return UiSpinner }, get UserDeviceStatus() { return UserDeviceStatus }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})