import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, ref, Ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { UserLabelService, LevelService, UserService } from "@/services";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import UiPanel from "@/components/UiPanel.vue";
import {
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiMultiselect,
} from "@/components";
import {
  Permission,
  UiActions,
  FormAction,
  ILevel,
  IDefaultServiceResult,
  IUserLabelCreateResult,
  IUserLabelFormState,
  IUser,
  IUserLabel,
  FilterGetters,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLabelFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<IUserLabelFormState>({
  id: 0,
  levelId: 0,
  label: "",
  users: [],
  isActive: true,
});

const rules = computed<ValidationArgs<IUserLabelFormState>>(() => ({
  id: {
    required,
  },
  levelId: {
    required,
  },
  label: {
    required,
    maxLength: maxLength(50),
  },
  users: {
    required,
  },
  isActive: {
    required,
  },
}));

const v$: Ref<Validation<ValidationArgs<IUserLabelFormState>>> = useVuelidate(
  rules,
  formState,
);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getUserLabel = (id: number) => {
  setLoading(true);
  UserLabelService.get<IUserLabel>(id)
    .then((response: AxiosResponse<IUserLabel>) => {
      const { id, label, users, levelId, isActive } = response.data;
      formState.id = id;
      formState.label = label;
      formState.users = users.map((u) => u.id);
      formState.levelId = levelId;
      formState.isActive = isActive;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
if (props.action == FormAction.EDIT) {
  getUserLabel(parseInt(route.params.id as string));
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewUserLabelById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    UserLabelService.update<IDefaultServiceResult>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    UserLabelService.create<IUserLabelCreateResult>(formState)
      .then(
        (response: AxiosResponse<IUserLabelCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const levels: Ref<ILevel[]> = ref([]);
const getLevels = () => {
  setLoading(true);
  LevelService.getAll<ILevel[]>({
    params: {
      page: 1,
      per_page: 100,
    },
  })
    .then((response) => {
      levels.value = response.data;

      if (!levelFilter.value.root) {
        levels.value = [levelFilter.value];
        formState.levelId = levelFilter.value.id;
      }
    })
    .finally(() => setLoading(false));
};
getLevels();

watch([levelFilter], () => {
  getLevels();
});

const usersRaw: Ref<IUser[]> = ref([]);
const getUsers = () => {
  setLoading(true);
  UserService.getAll<IUser[]>({
    params: {
      page: 1,
      per_page: 100,
      level: formState.levelId ? formState.levelId : null,
    },
  }).then((response) => {
    setLoading(false);
    usersRaw.value = response.data;
  });
};
getUsers();

watch(
  () => formState.levelId,
  () => {
    getUsers();
  },
);

const users = computed(() => {
  return usersRaw.value.map((user) => {
    return {
      id: user.id,
      name: user.name,
    };
  });
});

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewUserLabelById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "ListUserLabel",
    });
};

const __returned__ = { route, props, formState, rules, v$, setLoading, getUserLabel, onSubmit, levelFilter, levels, getLevels, usersRaw, getUsers, users, handleBackClick, UiPanel, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiMultiselect() { return UiMultiselect }, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})