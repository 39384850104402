import { defineComponent as _defineComponent } from 'vue'
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { UiSortMenu } from "@/components";
import { ISortMenuItem } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserListSort',
  props: {
  modelValue: {
    type: Array<ISortMenuItem>,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();

const sortMenuItems = ref([
  {
    label: t("user.sortMenu.items.name"),
    key: "name",
  },
] as ISortMenuItem[]);

const props = __props;

const emit = __emit;

const updateValue = (value: string) => {
  emit("update:modelValue", value);
};

const __returned__ = { t, sortMenuItems, props, emit, updateValue, get UiSortMenu() { return UiSortMenu } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})