import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, ref, Ref } from "vue";
import store from "@/store";
import router from "@/router";
import {
  TemplateHeader,
  TemplateSidebar,
  TemplateNavigation,
  TemplateProfile,
} from "@/views/templates/components";
import { UiButton, UiLoadingDialog, UiMultiselect } from "@/components";
import { HomeIcon } from "@heroicons/vue/outline";
import {
  FilterActions,
  FilterGetters,
  ILevel,
  NavigationGetters,
  UiGetters,
} from "@/definitions";
import LevelService from "@/services/LevelService";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfigTemplate',
  setup(__props, { expose: __expose }) {
  __expose();

const loading = computed(() => store.getters[UiGetters.IS_LOADING]);
const configSideNavigationItems = computed(
  () => store.getters[NavigationGetters.CONFIG_SIDE_NAVIGATION_ITEMS],
);

const handleBackToAppClick = () => {
  router.push("/");
};

const levels: Ref<ILevel[]> = ref([]);

const level = computed<ILevel>({
  get: () => store.getters[FilterGetters.LEVEL],
  set: (level: ILevel) => store.dispatch(FilterActions.SET_LEVEL, level),
});

onMounted(() => {
  LevelService.getAllGlobalSelect<ILevel[]>().then((response) => {
    levels.value = response.data;
  });
});

const disabledIfRouterWithId = computed(() => {
  if (router.currentRoute.value.params !== undefined) {
    return router.currentRoute.value.params.id !== undefined;
  }
  return false;
});

const headerClass = computed(() => {
  if (process.env.NODE_ENV === "production") {
    return "bg-slate-100";
  }

  if (process.env.NODE_ENV === "development") {
    return "bg-teal-700";
  }

  return "bg-gray-700";
});

const __returned__ = { loading, configSideNavigationItems, handleBackToAppClick, levels, level, disabledIfRouterWithId, headerClass, get TemplateHeader() { return TemplateHeader }, get TemplateSidebar() { return TemplateSidebar }, get TemplateNavigation() { return TemplateNavigation }, get TemplateProfile() { return TemplateProfile }, get UiButton() { return UiButton }, get UiLoadingDialog() { return UiLoadingDialog }, get UiMultiselect() { return UiMultiselect }, get HomeIcon() { return HomeIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})