import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import { UiButton } from "@/components";
import {
  CalendarIcon,
  ClockIcon,
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  TagIcon,
  UsersIcon,
  CalculatorIcon,
  AnnotationIcon,
} from "@heroicons/vue/outline";
import { PlayIcon } from "@heroicons/vue/solid";
import {
  CallStatus,
  CallStatusClassColor,
  CallType,
  ICall,
  Permission,
} from "@/definitions";
import { formatDateAsString, formatSecToTime } from "@/utils/datetimeCommon";
import moment from "moment";
import { applyPhoneMask } from "@/utils/number";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallListItem',
  props: {
  modelValue: {
    type: Object as PropType<ICall>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const duration = computed(() => {
  return moment(props.modelValue?.endedAt).diff(
    moment(props.modelValue.answeredAt),
    "seconds",
  );
});

const waitingTime = computed(() => {
  if (
    props.modelValue?.status === CallStatus.ANSWERED &&
    props.modelValue.answeredAt
  ) {
    return moment(props.modelValue.answeredAt).diff(
      moment(props.modelValue?.startedAt),
      "seconds",
    );
  }
  return moment(props.modelValue.endedAt).diff(
    moment(props.modelValue?.startedAt),
    "seconds",
  );
});

const agents = computed(() => {
  const agentsNames: string[] = [];
  props.modelValue?.events.forEach((e) => {
    if (e.sourceUser) {
      if (!agentsNames.includes(e.sourceUser.name)) {
        agentsNames.push(e.sourceUser.name);
      }
    }
    if (e.destinationUser) {
      if (!agentsNames.includes(e.destinationUser.name)) {
        agentsNames.push(e.destinationUser.name);
      }
    }
  });
  return agentsNames;
});

const destinationEvent = computed(() => {
  if (
    props.modelValue?.type === CallType.INBOUND &&
    props.modelValue.status === CallStatus.ANSWERED
  ) {
    return props.modelValue?.events.find(
      (event) => event.status === props.modelValue.status,
    );
  }
  return props.modelValue?.events[props.modelValue.events.length - 1];
});

function formatDestinationNumber(number: string) {
  const pattern = /(\d+)_(\d+)_(\d+)/; // Pattern to match the "211_1_1" like strings
  const match = number.match(pattern);
  if (match) {
    return match[1];
  } else {
    return applyPhoneMask(number);
  }
}

const __returned__ = { props, duration, waitingTime, agents, destinationEvent, formatDestinationNumber, get UiButton() { return UiButton }, get CalendarIcon() { return CalendarIcon }, get ClockIcon() { return ClockIcon }, get PhoneIcon() { return PhoneIcon }, get PhoneIncomingIcon() { return PhoneIncomingIcon }, get PhoneOutgoingIcon() { return PhoneOutgoingIcon }, get TagIcon() { return TagIcon }, get UsersIcon() { return UsersIcon }, get CalculatorIcon() { return CalculatorIcon }, get AnnotationIcon() { return AnnotationIcon }, get PlayIcon() { return PlayIcon }, get CallStatus() { return CallStatus }, get CallStatusClassColor() { return CallStatusClassColor }, get CallType() { return CallType }, get Permission() { return Permission }, get formatDateAsString() { return formatDateAsString }, get formatSecToTime() { return formatSecToTime }, get applyPhoneMask() { return applyPhoneMask } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})