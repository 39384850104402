import { defineComponent as _defineComponent } from 'vue'
import { computed, reactive, Ref, ref, watch } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useVuelidate, ValidationArgs } from "@vuelidate/core";
import { can } from "@/utils/auth";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  containsUppercase,
  containsLowercase,
  containsNumber,
  containsSpecial,
} from "@/utils/validators";
import { toast, toastServiceError } from "@/utils/notification";
import {
  UserService,
  LevelService,
  RoleService,
  NumberService,
} from "@/services";
import {
  UiPageHeader,
  UiButton,
  UiTextInput,
  UiCheckboxInput,
  UiCheckDirtyBeforeRouteLeave,
  UiMultiselect,
  UiPanel,
} from "@/components";
import {
  IUser,
  ILevel,
  IRole,
  INumber,
  Permission,
  IDefaultServiceResult,
  IUserServiceCreateResult,
  UiActions,
  IUserFormState,
  FormAction,
  IUserNumberFormState,
  FilterGetters,
} from "@/definitions";

import { AxiosResponse } from "axios";
import { DesktopComputerIcon } from "@heroicons/vue/solid";
import { DeviceMobileIcon } from "@heroicons/vue/outline";
import { Validation } from "@vuelidate/core";
import router from "@/router";
import SVGDeviceExternal from "@/components/svg/SVGDeviceExternal.vue";
import SVGChromeLogo from "@/components/svg/SVGChromeLogo.vue";
import UserFormExtension from "@/views/pages/user/components/UserFormExtension.vue";
import UserFormDevice from "@/views/pages/user/components/UserFormDevice.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const { t } = useI18n();

const props = __props;

const formState = reactive<IUserFormState>({
  name: "",
  email: "",
  levels: [],
  roles: [],
  isActive: true,
  hasMeeting: false,
  standardLevelId: null,
  devices: {
    mobile: false,
    desktop: false,
    web: false,
    external: false,
  },
  number: null,
  extension: null,
});

const rules = computed<ValidationArgs<IUserFormState>>(() => {
  const r: ValidationArgs<IUserFormState> = {
    name: {
      required,
      maxLength: maxLength(500),
    },
    email: {
      email,
      required,
      maxLength: maxLength(250),
    },
    levels: {
      required,
    },
    number: {},
    roles: {
      required,
    },
    extension: {},
    devices: {
      mobile: false,
      desktop: false,
      web: false,
      external: false,
    },
    isActive: {
      required,
    },
    standardLevelId: {
      required,
    },
    hasMeeting: {},
  };

  if (props.action == FormAction.CREATE) {
    r.password = {
      required,
      minLength: minLength(8),
      maxLength: maxLength(250),
      containsUppercase,
      containsLowercase,
      containsNumber,
      containsSpecial,
    };
    r.confirmPassword = {
      required,
      sameAsPassword: sameAs(formState.password, t("user.labels.password")),
    };
  }

  return r;
});

const v$: Ref<Validation<ValidationArgs<IUserFormState>>> = useVuelidate(
  rules,
  formState,
);
const externalConfig = ref();

const levelFilter = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const levelHasMeeting = levelFilter.value.meeting;
console.log(levelFilter);
console.log(levelHasMeeting);

const levels: Ref<ILevel[]> = ref([]);

const roles: Ref<IRole[]> = ref([]);

const noDevice = computed(
  () => Object.values(formState.devices).filter((v) => v).length === 0,
);

const selectedLevels = computed(() =>
  levels.value.filter((lvl) => formState.levels.indexOf(lvl.id) > -1),
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getLevels = () => {
  LevelService.getAll<ILevel[]>({
    params: {
      is_active: true,
    },
  }).then((response) => {
    levels.value = response.data;

    if (!levelFilter.value.root) {
      levels.value = [levelFilter.value];

      formState.levels.push(levelFilter.value.id);
      formState.standardLevelId = levelFilter.value.id;
    }
  }, toastServiceError);
};

if (can(Permission.ASSIGN_LEVEL_TO_USER)) {
  getLevels();
}
watch([levelFilter], () => {
  getLevels();
});

if (can(Permission.ASSIGN_ROLE_TO_USER)) {
  RoleService.getAll<IRole[]>().then((response) => {
    roles.value = response.data;
  }, toastServiceError);
}

if (props.action == FormAction.EDIT) {
  const id = parseInt(route.params.id as string);

  setLoading(true);
  UserService.get<IUser>(id)
    .then((response: AxiosResponse<IUser>) => {
      const {
        name,
        email,
        levels,
        roles,
        isActive,
        standardLevelId,
        hasMeeting,
        number,
        extension,
        devices,
        deviceExternal,
      } = response.data;

      formState.id = id;
      formState.name = name;
      formState.email = email;
      formState.levels = levels?.map((l) => l.id) || [];
      formState.roles = roles?.map((l) => l.id) || [];
      formState.isActive = isActive;
      formState.standardLevelId = standardLevelId ?? null;
      formState.hasMeeting = hasMeeting;
      if (deviceExternal) {
        externalConfig.value = {
          hostname: deviceExternal.hostname,
          sipUser: deviceExternal.sipUser,
          sipPassword: deviceExternal.sipPassword,
        };
      }

      formState.number = number ?? {
        id: null,
        levelId: null,
        numberId: null,
      };
      formState.extension = extension ?? {
        id: null,
        levelId: formState.standardLevelId,
        label: null,
        number: null,
      };
      formState.devices = devices;
    }, toastServiceError)
    .finally(() => setLoading(false));
} else {
  formState.number = {
    id: null,
    levelId: null,
    numberId: null,
  };
  formState.extension = {
    id: null,
    levelId: formState.standardLevelId,
    label: null,
    number: null,
  };
}

const numbersRaw: Ref<INumber[]> = ref([]);
const getNumbers = () => {
  setLoading(true);
  const levels = formState.levels;
  NumberService.getAll<INumber[]>({
    params: {
      levels,
    },
  })
    .then((response: AxiosResponse<INumber[]>) => {
      numbersRaw.value = response.data;
    }, toastServiceError)
    .finally(() => {
      setLoading(false);
    });
};
getNumbers();

function setFormNumbers() {
  if (formState.number?.levelId !== formState.standardLevelId) {
    formState.number = {
      id: null,
      levelId: formState.standardLevelId,
      numberId: null,
    } as IUserNumberFormState;
  }
}

function getLevelNumbers(levelID: number | null) {
  return numbersRaw.value.filter((number) => number.level.id == levelID);
}

const onSubmit = async () => {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  await setLoading(true);

  const handleServiceSuccess = (message: string) => {
    toast.success(message);
    v$.value.$reset();
    router.push({
      name: "ListUser",
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = parseInt(route.params.id as string);
    UserService.update<IDefaultServiceResult, IUserFormState>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    UserService.create<IUserServiceCreateResult, IUserFormState>(formState)
      .then(
        (response: AxiosResponse<IUserServiceCreateResult>) =>
          handleServiceSuccess(response.data.message),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
};

watch(
  () => formState.levels,
  () => {
    getNumbers();
    setFormNumbers();
  },
);

watch(
  () => formState.standardLevelId,
  (newValue) => {
    if (formState.number) {
      formState.number.levelId = newValue;
    }
    if (formState.extension) {
      formState.extension.levelId = newValue;
    }
  },
);

const __returned__ = { route, t, props, formState, rules, v$, externalConfig, levelFilter, levelHasMeeting, levels, roles, noDevice, selectedLevels, setLoading, getLevels, numbersRaw, getNumbers, setFormNumbers, getLevelNumbers, onSubmit, get UiPageHeader() { return UiPageHeader }, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckboxInput() { return UiCheckboxInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiMultiselect() { return UiMultiselect }, get UiPanel() { return UiPanel }, get Permission() { return Permission }, get FormAction() { return FormAction }, get DesktopComputerIcon() { return DesktopComputerIcon }, get DeviceMobileIcon() { return DeviceMobileIcon }, SVGDeviceExternal, SVGChromeLogo, UserFormExtension, UserFormDevice }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})