import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-3 gap-6 mb-6" }
const _hoisted_5 = {
  for: "level",
  class: "block text-sm font-medium leading-6 mb-2 text-gray-900"
}
const _hoisted_6 = {
  key: 0,
  class: "grid grid-cols-3 gap-6 mb-10"
}
const _hoisted_7 = {
  key: 0,
  class: "col-span-2"
}
const _hoisted_8 = {
  controls: "",
  class: "w-full"
}
const _hoisted_9 = ["src", "type"]
const _hoisted_10 = { class: "mb-5" }
const _hoisted_11 = { class: "mt-2 sm:mt-0 grid grid-cols-1 md:grid-cols-3 gap-6" }
const _hoisted_12 = { class: "flex col-span-2 justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10" }
const _hoisted_13 = { class: "text-center" }
const _hoisted_14 = { class: "mt-4 flex justify-center text-sm leading-6 text-gray-600" }
const _hoisted_15 = {
  key: 0,
  for: "audio",
  class: "relative cursor-pointer rounded-md bg-white font-semibold text-cyan-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-600 focus-within:ring-offset-2 hover:text-cyan-500"
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "text-xs leading-5 text-gray-600" }
const _hoisted_18 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("connectFlowAudio." + $setup.props.action + ".title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("connectFlowAudio." + $setup.props.action + ".description")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.$t("userLabel.labels.level")) + " ", 1 /* TEXT */),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
              ]),
              _createVNode($setup["UiMultiselect"], {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.v$.levelId.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.v$.levelId.$model) = $event)),
                primitive: true,
                options: $setup.levels,
                errors: $setup.v$.levelId.$errors,
                required: ""
              }, null, 8 /* PROPS */, ["modelValue", "options", "errors"])
            ]),
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.v$.name.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('connectFlowAudio.labels.name'),
              errors: $setup.v$.name.$errors,
              onBlur: $setup.v$.name.$touch,
              required: ""
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"])
          ]),
          ($setup.props.action === $setup.FormAction.EDIT && $setup.formState.file === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                ($setup.audioData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("audio", _hoisted_8, [
                        _createElementVNode("source", {
                          src: $setup.audioData,
                          type: $setup.getAudioFormat($setup.filePath)
                        }, null, 8 /* PROPS */, _hoisted_9),
                        _cache[4] || (_cache[4] = _createTextVNode(" Your browser does not support the audio element. "))
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[6] || (_cache[6] = _createElementVNode("svg", {
                    class: "mx-auto h-12 w-12 text-gray-300",
                    viewBox: "0 0 24 24",
                    fill: "currentColor",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("path", {
                      "fill-rule": "evenodd",
                      d: "M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z",
                      "clip-rule": "evenodd"
                    })
                  ], -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_14, [
                    ($setup.formState.file === null)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_15, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("connectFlowAudio.create.fileLabel")), 1 /* TEXT */),
                          _createElementVNode("input", {
                            onChange: $setup.handleAudioUpload,
                            id: "audio",
                            name: "audio",
                            type: "file",
                            class: "sr-only"
                          }, null, 32 /* NEED_HYDRATION */),
                          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-xs ml-0.5" }, "*", -1 /* HOISTED */))
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString($setup.formState.file?.name), 1 /* TEXT */))
                  ]),
                  _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("connectFlowAudio.create.uploadRules")), 1 /* TEXT */)
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.v$.file.$errors, (error, idx) => {
                return (_openBlock(), _createElementBlock("p", {
                  class: "text-sm text-danger bg-red-50 px-2 py-2 max-w-2xl shadow z-10",
                  key: `error${error.$propertyPath}${idx}`
                }, _toDisplayString(error.$message), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode($setup["UiCheckboxInput"], {
              modelValue: $setup.v$.isActive.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
              name: "isActive",
              label: _ctx.$t('connectFlowAudio.labels.isActive')
            }, null, 8 /* PROPS */, ["modelValue", "label"])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_18, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          to: { name: 'ListConnectFlowAudio' }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_CONNECT_FLOW_AUDIO)) ||
            ($setup.props.action == $setup.FormAction.EDIT &&
              _ctx.$can($setup.Permission.EDIT_CONNECT_FLOW_AUDIO))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}