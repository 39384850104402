import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import {
  UiPageHeader,
  UiListSearch,
  UiPagination,
  UiDatePicker,
  UiSortMenuBadge,
  UiBadge,
} from "@/components";
import MeetingService from "@/services/MeetingService";
import {
  IFilterItem,
  ILevel,
  IMeeting,
  ISortMenuItem,
  MeetingActions,
  MeetingGetters,
  FilterGetters,
  FilterActions,
  UiActions,
} from "@/definitions";
import {
  MeetingListItem,
  MeetingListSort,
  MeetingListFilters,
} from "@/views/pages/meeting/components";

const perPage = 20;

export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();

const count = ref(0);
const currentPage = computed<number>({
  get: () => store.getters[MeetingGetters.PAGE],
  set: (page: number) => store.dispatch(MeetingActions.SET_PAGE, page),
});

const search = computed<string>({
  get: () => store.getters[MeetingGetters.SEARCH],
  set: (search: string) => store.dispatch(MeetingActions.SET_SEARCH, search),
});
const sortMenuSelected = computed<ISortMenuItem[]>({
  get: () => store.getters[MeetingGetters.SORT],
  set: (sort: ISortMenuItem[]) => store.dispatch(MeetingActions.SET_SORT, sort),
});

const orderBy = computed<string>(() => {
  return sortMenuSelected.value
    ? sortMenuSelected.value.map((s) => s.direction + s.key).join(",")
    : "";
});
const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});

const platform = computed<string | null>(() => {
  const platformFilter = store.getters[MeetingGetters.FILTER].platform;

  if (platformFilter && platformFilter.value) {
    return platformFilter.value.toString();
  } else {
    return null;
  }
});

const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[MeetingGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

if (route.query.clearStateOnSetup) {
  currentPage.value = 1;
  router.replace({ query: {} });
}

const handleRemoveSortClick = (item: ISortMenuItem) => {
  sortMenuSelected.value.splice(sortMenuSelected.value.indexOf(item), 1);
};
function handleRemoveFilter(field: string) {
  store.dispatch(MeetingActions.CLEAR_FILTER, field);
}

const meetings = ref<IMeeting[]>([]);
const prepareMeetingParams = () => {
  if (!level.value || dateRange.value.length < 2) return null;

  return {
    search: search.value,
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    platform: platform?.value,
    page: currentPage.value,
    per_page: perPage,
    order_by: orderBy.value,
  };
};

const getMeetings = () => {
  const params = prepareMeetingParams();
  if (!params) return;

  params.page = currentPage.value;
  params.per_page = perPage;

  setLoading(true);

  MeetingService.getAll({ params })
    .then((response) => {
      meetings.value = response.data;
      count.value = parseInt(response.headers["x-pagination-count"]);
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

getMeetings();

watch(
  [search, level, dateRange, orderBy, currentPage, activeFilters],
  () => {
    getMeetings();
  },
  { immediate: true },
);

const __returned__ = { route, router, count, perPage, currentPage, search, sortMenuSelected, orderBy, level, dateRange, platform, activeFilters, setLoading, handleRemoveSortClick, handleRemoveFilter, meetings, prepareMeetingParams, getMeetings, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get UiPagination() { return UiPagination }, get UiDatePicker() { return UiDatePicker }, get UiSortMenuBadge() { return UiSortMenuBadge }, get UiBadge() { return UiBadge }, get MeetingListItem() { return MeetingListItem }, get MeetingListSort() { return MeetingListSort }, get MeetingListFilters() { return MeetingListFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})