import { defineComponent as _defineComponent } from 'vue'
import { computed, Ref, ref, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { UiButton, UiRightPanel, UiMultiselect } from "@/components";
import { FilterIcon } from "@heroicons/vue/solid";
import {
  IBillingReportFilter,
  BillingReportGetters,
  BillingReportActions,
  IUser,
  CallType,
  CallStatus,
} from "@/definitions";
import { UserService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingReportFilters',
  props: {
    sources: { type: Array, required: true },
    destinations: { type: Array, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const filterOpened = ref(false);

const defaultFilter = computed(
  () => store.getters[BillingReportGetters.DEFAULT_FILTER],
);

const props = __props;

const filter: Ref<IBillingReportFilter> = ref({
  user: defaultFilter.value.user,
  sourceNumber: defaultFilter.value.sourceNumber,
  destinationNumber: defaultFilter.value.destinationNumber,
  status: defaultFilter.value.status,
  type: defaultFilter.value.type,
});
const storeFilter = computed(() => store.getters[BillingReportGetters.FILTER]);

const userOptions: Ref<IUser[]> = ref([]);
const getUsers = () => {
  UserService.getAll<IUser[]>({
    params: {
      isAgent: true,
      page: 1,
      per_page: 100,
    },
  }).then((response) => (userOptions.value = response.data));
};

function applyFilters() {
  store.dispatch(BillingReportActions.SET_FILTER, filter.value);
}

function clearFilters() {
  store.dispatch(BillingReportActions.CLEAR_FILTER);
  refreshLocalFilter();
}

function refreshLocalFilter() {
  filter.value.user = storeFilter.value.user;
  filter.value.sourceNumber = storeFilter.value.sourceNumber;
  filter.value.destinationNumber = storeFilter.value.destinationNumber;
  filter.value.status = storeFilter.value.status;
  filter.value.type = storeFilter.value.type;
}

watch(filterOpened, () => {
  refreshLocalFilter();
});

onMounted(() => {
  getUsers();
});

const __returned__ = { t, filterOpened, defaultFilter, props, filter, storeFilter, userOptions, getUsers, applyFilters, clearFilters, refreshLocalFilter, get UiButton() { return UiButton }, get UiRightPanel() { return UiRightPanel }, get UiMultiselect() { return UiMultiselect }, get FilterIcon() { return FilterIcon }, get CallType() { return CallType }, get CallStatus() { return CallStatus } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})