import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import store from "@/store";
import { AuthGetters, Permission, IUser, ILevel, IRole } from "@/definitions";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilePage',
  setup(__props, { expose: __expose }) {
  __expose();

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);
const userLevels = computed<ILevel[]>(() =>
  store.getters[AuthGetters.USER_LEVELS].map(
    (level: ILevel) => level.id + ":" + level.name,
  ),
);
const userRoles = computed<IRole[]>(() =>
  store.getters[AuthGetters.USER_ROLES].map(
    (role: IRole) => role.id + ":" + role.name,
  ),
);
const userPermissions = computed<Permission[]>(
  () => store.getters[AuthGetters.USER_PERMISSIONS],
);

const __returned__ = { user, userLevels, userRoles, userPermissions }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})