import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, ComputedRef } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { toastServiceError } from "@/utils/notification";
import {
  AgentProductivityPerDayReportActions,
  AgentProductivityPerDayReportGetters,
  CallType,
  FilterActions,
  FilterGetters,
  IAgentProductivityPerDayReportFilter,
  IAgentProductivityPerDayReportServiceGetInfoParams,
  IAgentProductivityPerDayReportServiceGetInfoResult,
  IBarChart,
  ICallsStats,
  ICallsStatsPerDay,
  IDataItem,
  IFilterItem,
  ILevel,
  UiActions,
} from "@/definitions";
import { AgentProductivityPerDayReportFilters } from "@/views/pages/reports/agentProductivityPerDay/components";
import {
  BarChart,
  CallStatusPerDayColumnChart,
  CallTypePerDayColumnChart,
  UiBadge,
  UiDatePicker,
  UiPageHeader,
} from "@/components";
import moment from "moment";
import { AgentProductivityPerDayReportService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentProductivityPerDayReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const reportInfo: Ref<IAgentProductivityPerDayReportServiceGetInfoResult | null> =
  ref(null);

const headerStats = computed<IDataItem<string>[]>(() => {
  const callsStats: ICallsStats | undefined = reportInfo.value?.stats;

  if (callsStats == undefined) return [];

  const result: IDataItem<string>[] = [
    {
      name: "totalCalls",
      value: callsStats.total.toString(),
    },
    {
      name: "inboundCalls",
      value: callsStats.type[CallType.INBOUND].toString(),
    },
    {
      name: "outboundCalls",
      value: callsStats.type[CallType.OUTBOUND].toString(),
    },
    {
      name: "internalCalls",
      value: callsStats.type[CallType.INTERNAL].toString(),
    },
    {
      name: "uniqueNumbers",
      value: callsStats.uniqueNumbers.toString(),
    },
    // {
    //   name: "averageSilencePercentage",
    //   value: callsStats.averageSilencePercentage + "%",
    // },
    {
      name: "averageServiceTime",
      value: moment
        .utc((callsStats.averageServiceTime || 0) * 1000)
        .format("HH:mm:ss"),
    },
  ];

  return result;
});

const getChart = (
  title: string,
  prop: keyof ICallsStatsPerDay,
  color = "#BFDBFE",
  yAxisLabelFormatter = (value: number | string) => String(value),
) => {
  const callsStatsPerDay = reportInfo.value?.callsStatsPerDay || [];
  const category: string[] = [];
  const serie: IDataItem<number[]> = {
    name: title,
    value: [],
    color,
  };

  for (let i = callsStatsPerDay.length - 1; i >= 0; i--) {
    const stats = callsStatsPerDay[i];
    category.push(stats.date);
    serie.value.push(stats[prop] as number);
  }

  serie.value = serie.value.reverse();
  const barChart: IBarChart = {
    title,
    category: category.reverse(),
    series: [serie],
    yAxisLabelFormatter,
  };

  return barChart;
};

const yAxisLabelFormatterAsTime = (value: number | string) => {
  value = value || 0;
  return moment.utc((value as number) * 1000).format("HH:mm:ss");
};

const xAxisLabelFormatterAsDate = (value: number | string) => {
  return moment.utc(value).format("DD/MM");
};

const charts = computed<IBarChart[]>(() => {
  return [
    getChart(
      t("report.agentProductivityPerDay.serviceTimePerDayColumnChart.title"),
      "averageServiceTime",
      "#BFDBFE",
      yAxisLabelFormatterAsTime,
    ),
    // getChart(
    //   t("report.agentProductivityPerDay.silencePerDayColumnChart.title"),
    //   "averageSilencePercentage",
    //   "#E9D5FF",
    //   (value: number | string) => {
    //     return value + "%";
    //   }
    // ),
    getChart(
      t("report.agentProductivityPerDay.timeAvailablePerDayColumnChart.title"),
      "averageTimeAvailable",
      "#FBCFE8",
      yAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerDay.durationPerDayColumnChart.title"),
      "averageDuration",
      "#A5F3FC",
      yAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerDay.totalIdleTimePerDayColumnChart.title"),
      "averageTotalIdleTime",
      "#BAE6FD",
      yAxisLabelFormatterAsTime,
    ),
    getChart(
      t("report.agentProductivityPerDay.idleTimePerDayColumnChart.title"),
      "averageIdleTime",
      "#F5D0FE",
      yAxisLabelFormatterAsTime,
    ),
  ];
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);
const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});
const filter = computed<IAgentProductivityPerDayReportFilter>(
  () => store.getters[AgentProductivityPerDayReportGetters.FILTER],
);
const activeFilters: ComputedRef<IFilterItem[]> = computed(
  () => store.getters[AgentProductivityPerDayReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: IAgentProductivityPerDayReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
    user: filter.value.user?.id || null,
    userLabel: filter.value.userLabel?.id || null,
  };

  setLoading(true);

  AgentProductivityPerDayReportService.getInfo({
    params,
  })
    .then((response) => {
      reportInfo.value = response.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(
    AgentProductivityPerDayReportActions.CLEAR_FILTER,
    filter.field,
  );
}

watch([level, dateRange, activeFilters], () => {
  getReportData();
});

getReportData();

const __returned__ = { t, reportInfo, headerStats, getChart, yAxisLabelFormatterAsTime, xAxisLabelFormatterAsDate, charts, level, dateRange, filter, activeFilters, setLoading, getReportData, handleRemoveFilter, get AgentProductivityPerDayReportFilters() { return AgentProductivityPerDayReportFilters }, get BarChart() { return BarChart }, get CallStatusPerDayColumnChart() { return CallStatusPerDayColumnChart }, get CallTypePerDayColumnChart() { return CallTypePerDayColumnChart }, get UiBadge() { return UiBadge }, get UiDatePicker() { return UiDatePicker }, get UiPageHeader() { return UiPageHeader } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})