import { defineComponent as _defineComponent } from 'vue'
import { UiSpinner } from "@/components";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UiLoadingDialog',
  props: {
  show: {
    type: Boolean,
    required: true,
  },
  text: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();



const __returned__ = { get UiSpinner() { return UiSpinner }, get Dialog() { return Dialog }, get DialogOverlay() { return DialogOverlay }, get DialogTitle() { return DialogTitle }, get TransitionChild() { return TransitionChild }, get TransitionRoot() { return TransitionRoot } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})