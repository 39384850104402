import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch, onMounted } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { toastServiceError } from "@/utils/notification";
import { UiPageHeader, BarChart, UiBadge, UiDatePicker } from "@/components";
import { BillingReportFilters } from "./components"; // Importação dos filtros
import { IBilling, IFilterItem, IBarChart, UiActions } from "@/definitions";
import {
  BillingReportActions,
  BillingReportGetters,
  FilterActions,
  FilterGetters,
} from "@/definitions";
import { BillingReportService } from "@/services";


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const reportInfo: Ref<IBilling | null> = ref(null);

const getChart = (
  title: string,
  dataKey: keyof IBilling["dailyResults"][0],
  color = "#BFDBFE",
) => {
  const category =
    reportInfo.value?.dailyResults?.map((item) =>
      moment(item.date).format("D [de] MMM"),
    ) || [];
  const value =
    reportInfo.value?.dailyResults?.map((item) => item[dataKey] || 0) || [];

  return {
    title,
    category,
    series: [
      {
        name: title,
        value,
        color,
      },
    ],
  } as IBarChart;
};

const dailyConsumptionChart = computed(() =>
  getChart(t("report.billing.dailyConsumptionValue"), "cost", "#00C3AE"),
);

console.log(dailyConsumptionChart);

const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (value) => store.dispatch(FilterActions.SET_DATE_RANGE, value),
});

const filter = computed(() => store.getters[BillingReportGetters.FILTER]);
const activeFilters = computed(
  () => store.getters[BillingReportGetters.ACTIVE_FILTERS],
);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const getReportData = () => {
  const params = {
    start_date: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    end_date: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    user_id: filter.value.user?.id || null,
    source_number: filter.value.sourceNumber?.value || null,
    destination_number: filter.value.destinationNumber?.value || null,
    status: filter.value.status?.value || null,
    type: filter.value.type?.value || null,
  };

  setLoading(true);

  BillingReportService.getInfo({ params })
    .then((response) => {
      reportInfo.value = response.data;
    })
    .catch(toastServiceError)
    .finally(() => setLoading(false));
};

function handleRemoveFilter(filter: IFilterItem) {
  store.dispatch(BillingReportActions.CLEAR_FILTER, filter.field);
}

watch([dateRange, filter], getReportData, { deep: true });

onMounted(() => {
  getReportData();
});

const __returned__ = { t, reportInfo, getChart, dailyConsumptionChart, dateRange, filter, activeFilters, setLoading, getReportData, handleRemoveFilter, get UiPageHeader() { return UiPageHeader }, get BarChart() { return BarChart }, get UiBadge() { return UiBadge }, get UiDatePicker() { return UiDatePicker }, get BillingReportFilters() { return BillingReportFilters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})