import { defineComponent as _defineComponent } from 'vue'
import { PropType, computed } from "vue";
import { EChartsOption } from "echarts";
import orderBy from "lodash.orderby";
import { colord } from "colord";
import { useI18n } from "vue-i18n";
import { UiChart } from "@/components";
import { ICallServiceTimeRangeCount, IDataItem } from "@/definitions";
import { CallbackDataParams } from "echarts/types/dist/shared";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallServiceTimeRangesDoughnutChart',
  props: {
  title: {
    type: String,
    required: true,
  },
  data: {
    type: Object as PropType<ICallServiceTimeRangeCount>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const props = __props;

const callServiceTimeRangesOptions = computed<EChartsOption>(() => {
  const serviceTimeRanges: IDataItem<number>[] = [];

  for (const name in props.data) {
    serviceTimeRanges.push({
      name,
      value: props.data[name as keyof typeof props.data],
    });
  }

  const orderedServiceTimeRanges = orderBy(serviceTimeRanges, "value", "desc");

  const baseColor = colord("#0FA5E8");

  const data = orderedServiceTimeRanges.map((m, index) => {
    return {
      value: m.value,
      name: t("core.options.call.serviceTimeRange." + m.name),
      itemStyle: {
        color: baseColor
          .lighten(index / orderedServiceTimeRanges.length / 2.5)
          .toHex(),
      },
    };
  });

  const eChartsOption: EChartsOption = {
    title: {
      text: props.title,
      left: "14px",
      top: "14px",
      textStyle: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#555",
      },
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      type: "scroll",
      width: "90%",
      height: "100%",
      bottom: "10px",
      left: "center",
      itemHeight: 10,
      itemWidth: 10,
      icon: "circle",
      textStyle: {
        fontSize: "11px",
        lineHeight: 14,
        rich: {
          a: {
            align: "center", // Alinha o texto da legenda horizontalmente ao meio
            verticalAlign: "bottom", // Alinha o texto da legenda verticalmente ao meio
          },
          b: {
            align: "center", // Alinha o valor numérico horizontalmente ao meio
            verticalAlign: "bottom", // Alinha o valor numérico verticalmente ao meio
          },
        },
      },
      selectedMode: true,
      formatter: (name: string) => {
        const value = data.find((d) => d.name == name)?.value || 0;
        return "{a|" + name + "} {b|(" + value + ")}";
      },
    },
    series: [
      {
        type: "pie",
        radius: ["20%", "55%"],
        data,
        label: {
          show: true,
          // textStyle: {
          //   fontSize: "11px",
          // },
          formatter(param: CallbackDataParams) {
            return param.name + " (" + (param.percent || 0) + "%)";
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return eChartsOption;
});

const __returned__ = { t, props, callServiceTimeRangesOptions, get UiChart() { return UiChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})