import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType } from "vue";
import { IIntegrationLog } from "@/definitions";
import { formatDateAsString } from "@/utils/datetimeCommon";


export default /*@__PURE__*/_defineComponent({
  __name: 'IntegrationLogListItem',
  props: {
  modelValue: {
    type: Object as PropType<IIntegrationLog>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const callSource = computed<string>(() => {
  return (
    props.modelValue.call.events
      .filter((ce) => ce.sourceUser?.name)
      .map((ce) => ce.sourceUser?.name)
      .filter((ce, pos, self) => self.indexOf(ce) == pos)
      .join(", ") || props.modelValue.call.sourceNumber
  );
});

const callDestination = computed<string>(() => {
  return (
    props.modelValue.call.events
      .filter((ce) => ce.destinationUser?.name)
      .map((ce) => ce.destinationUser?.name)
      .filter((ce, pos, self) => self.indexOf(ce) == pos)
      .join(", ") || props.modelValue.call.destinationNumber
  );
});

const __returned__ = { props, callSource, callDestination, get formatDateAsString() { return formatDateAsString } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})