import { defineComponent as _defineComponent } from 'vue'
import { ref, Ref, computed, watch } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { RouterLink } from "vue-router";
import { toastServiceError } from "@/utils/notification";
import RoleService from "@/services/RoleService";
import PermissionService from "@/services/PermissionService";
import {
  UiPageHeader,
  UiListSearch,
  UiButton,
  UiPagination,
} from "@/components";
import { PencilIcon } from "@heroicons/vue/solid";
import {
  IRole,
  IPermission,
  Permission,
  PermissionGetters,
  PermissionActions,
  UiActions,
} from "@/definitions";
import { LockClosedIcon } from "@heroicons/vue/solid";

const perPage = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'PermissionPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

const roles: Ref<IRole[]> = ref([]);
const permissions: Ref<IPermission[]> = ref([]);

const search = computed<string>({
  get: () => store.getters[PermissionGetters.SEARCH],
  set: (search: string) => store.dispatch(PermissionActions.SET_SEARCH, search),
});

const orderBy: Ref<string> = ref("permission.description");

const currentPage = computed<number>({
  get: () => store.getters[PermissionGetters.PAGE],
  set: (page: number) => store.dispatch(PermissionActions.SET_PAGE, page),
});

const descriptionalizedPermissions = computed<IPermission[]>(() => {
  return permissions.value.map((p) => {
    p.description = t("permission.permissions." + p.id);
    return p;
  });
});

const filteredPermissions = computed<IPermission[]>(() => {
  return descriptionalizedPermissions.value.filter((p) => {
    return (
      !search.value ||
      p.description?.toLowerCase().includes(search.value.toLowerCase())
    );
  });
});

const paginatedPermissions = computed<IPermission[]>(() => {
  return filteredPermissions.value.slice(
    (currentPage.value - 1) * perPage,
    currentPage.value * perPage,
  );
});

if (route.query.clearStateOnSetup) {
  search.value = "";
  currentPage.value = 1;
  router.replace({ query: {} });
}

const getRolesAndPermissions = () => {
  setLoading(true);
  Promise.all([
    RoleService.getAll<IRole[]>(),
    PermissionService.getAll<IPermission[]>(),
  ])
    .then(([roleResponse, permissionResponse]) => {
      roles.value = roleResponse.data;
      permissions.value = permissionResponse.data;
    }, toastServiceError)
    .finally(() => setLoading(false));
};

getRolesAndPermissions();

watch([search, orderBy], () => {
  currentPage.value = 1;
});

const handleAddRemovePermissionClick = (
  role: IRole,
  permission: IPermission,
) => {
  setLoading(true);
  const permissionRole = permission.roles.find((r: IRole) => r.id == role.id);
  if (permissionRole) {
    RoleService.removePermission(role.id, permission.id)
      .then(
        () =>
          permission.roles.splice(permission.roles.indexOf(permissionRole), 1),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    RoleService.addPermission(role.id, permission.id)
      .then(() => permission.roles.push(role), toastServiceError)
      .finally(() => setLoading(false));
  }
};

const __returned__ = { route, router, t, setLoading, roles, permissions, search, orderBy, currentPage, perPage, descriptionalizedPermissions, filteredPermissions, paginatedPermissions, getRolesAndPermissions, handleAddRemovePermissionClick, get RouterLink() { return RouterLink }, get UiPageHeader() { return UiPageHeader }, get UiListSearch() { return UiListSearch }, get UiButton() { return UiButton }, get UiPagination() { return UiPagination }, get PencilIcon() { return PencilIcon }, get Permission() { return Permission }, get LockClosedIcon() { return LockClosedIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})