import { defineComponent as _defineComponent } from 'vue'
import { Ref, ref, computed, reactive } from "vue";
import store from "@/store";
import { useRoute } from "vue-router";
import RoleService from "@/services/RoleService";
import UserService from "@/services/UserService";
import { toast, toastServiceError } from "@/utils/notification";
import { goBack } from "@/utils/navigation";
import {
  UiButton,
  UiTextInput,
  UiCheckDirtyBeforeRouteLeave,
  UiPageHeader,
  UiContainerSection,
  UiMultiselect,
} from "@/components";
import {
  IRole,
  IUser,
  Permission,
  UiActions,
  IDefaultServiceResult,
  IRoleServiceCreateResult,
  FormAction,
  IRoleFormState,
} from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { required, maxLength } from "@/utils/validators";
import { AxiosResponse } from "axios";


export default /*@__PURE__*/_defineComponent({
  __name: 'RoleFormPage',
  props: {
  action: {
    type: String,
    default: "view",
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();

const props = __props;

const formState = reactive<IRoleFormState>({
  name: "",
  users: [],
});

const rules = computed<ValidationArgs<IRoleFormState>>(() => ({
  name: {
    required,
    maxLength: maxLength(500),
  },
  users: {},
}));

const v$: Ref<Validation<ValidationArgs<IRoleFormState>>> = useVuelidate(
  rules,
  formState,
);

const users: Ref<IUser[]> = ref([]);

const setLoading = (loading: boolean) => {
  store.dispatch(UiActions.SET_IS_LOADING, loading);
};

const getRole = (id: number) => {
  return RoleService.get<IRole>(id).then((response: AxiosResponse<IRole>) => {
    const { name, users = [] } = response.data;
    formState.id = id;
    formState.name = name;
    formState.users = users?.map((u) => u.id) || [];
  }, toastServiceError);
};

const loadFormData = () => {
  setLoading(true);
  UserService.getAll<IUser[]>({
    params: {
      page: 1,
      per_page: 100,
      is_active: true,
    },
  })
    .then((response) => {
      users.value = response.data;
      if (props.action == FormAction.EDIT)
        return getRole(parseInt(route.params.id as string));
    }, toastServiceError)
    .finally(() => setLoading(false));
};

loadFormData();

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (!isFormCorrect) return;

  setLoading(true);

  const handleServiceSuccess = (message: string, id: number) => {
    toast.success(message);
    v$.value.$reset();
    goBack({
      name: "ViewRoleById",
      params: {
        id,
      },
    });
  };

  if (props.action == FormAction.EDIT) {
    const id = formState.id || 0;
    RoleService.update<IDefaultServiceResult, IRoleFormState>(id, formState)
      .then(
        (response: AxiosResponse<IDefaultServiceResult>) =>
          handleServiceSuccess(response.data.message, id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  } else {
    RoleService.create<IRoleServiceCreateResult, IRoleFormState>(formState)
      .then(
        (response: AxiosResponse<IRoleServiceCreateResult>) =>
          handleServiceSuccess(response.data.message, response.data.result.id),
        toastServiceError,
      )
      .finally(() => setLoading(false));
  }
}

const handleBackClick = () => {
  if (props.action == FormAction.EDIT)
    goBack({
      name: "ViewRoleById",
      params: {
        id: formState.id,
      },
    });
  else
    goBack({
      name: "Permissions",
    });
};

const __returned__ = { route, props, formState, rules, v$, users, setLoading, getRole, loadFormData, onSubmit, handleBackClick, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get UiCheckDirtyBeforeRouteLeave() { return UiCheckDirtyBeforeRouteLeave }, get UiPageHeader() { return UiPageHeader }, get UiContainerSection() { return UiContainerSection }, get UiMultiselect() { return UiMultiselect }, get Permission() { return Permission }, get FormAction() { return FormAction } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})