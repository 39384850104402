import { defineComponent as _defineComponent } from 'vue'
import { ref } from "vue";
import { ICallWord } from "@/definitions";
import { UiBadge } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'WordsBlock',
  props: {
  persona: {
    type: String,
    required: true,
  },
  words: {
    type: Array as () => Array<ICallWord>,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();



function searchWord(word: ICallWord): void {
  word.selected = !word.selected;
}

const hiddenItems = ref(true);
function toggleItems() {
  hiddenItems.value = !hiddenItems.value;
}

const __returned__ = { searchWord, hiddenItems, toggleItems, get UiBadge() { return UiBadge } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})