import { defineComponent as _defineComponent } from 'vue'
import { UiDatePicker, UiPageHeader } from "@/components";
import { computed, Ref, ref, watch } from "vue";
import moment from "moment/moment";
import store from "@/store";
import {
  FilterActions,
  FilterGetters,
  ILevel,
  UiActions,
  UserDevice,
  UserDeviceStatus,
  UserExtensionQueueStatus,
} from "@/definitions";

import { DesktopComputerIcon } from "@heroicons/vue/solid";
import { DeviceMobileIcon } from "@heroicons/vue/outline";
import SVGChromeLogo from "@/components/svg/SVGChromeLogo.vue";
import SVGDeviceExternal from "@/components/svg/SVGDeviceExternal.vue";
import { formatSecToTime } from "@/utils/datetimeCommon";
import { toastServiceError } from "@/utils/notification";
import AgentDeviceStatusHistoryReportService from "@/services/AgentDeviceStatusHistoryReportService";
import {
  IAgentDeviceStatus,
  IAgentDeviceStatusHistoryReportServiceGetInfoParams,
} from "@/definitions/service-typings/AgentDeviceStatusHistoryReportService";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentDeviceReportPage',
  setup(__props, { expose: __expose }) {
  __expose();

const dateRange = computed<Array<Date>>({
  get: () => store.getters[FilterGetters.DATE_RANGE],
  set: (dateRange: Array<Date>) =>
    store.dispatch(FilterActions.SET_DATE_RANGE, dateRange),
});

const level = computed<ILevel>(() => store.getters[FilterGetters.LEVEL]);

const totalTimePaused = ref(0);

const totalTimeCall = ref(0);

const agentsDevices: Ref<IAgentDeviceStatus[]> = ref([]);

const setLoading = (loading: boolean) =>
  store.dispatch(UiActions.SET_IS_LOADING, loading);

function getDeviceIcon(device: IAgentDeviceStatus) {
  switch (device.deviceType) {
    case UserDevice.MOBILE:
      return DeviceMobileIcon;
    case UserDevice.DESKTOP:
      return DesktopComputerIcon;
    case UserDevice.WEB:
      return SVGChromeLogo;
    case UserDevice.EXTERNAL:
      return SVGDeviceExternal;
  }
}

function getStatusColor(status: string) {
  switch (status) {
    case UserDeviceStatus.CONNECTED:
      return "text-blue-600";
    case UserDeviceStatus.UNAVAILABLE:
      return "text-red-500";
    case UserDeviceStatus.IN_CALL:
      return "text-green-600";
  }
}

function getQueueColorStatus(status: string) {
  switch (status) {
    case UserExtensionQueueStatus.PAUSED:
      return "text-yellow-600";
    case UserExtensionQueueStatus.AVAILABLE:
      return "text-green-500";
  }
}

const getReportData = () => {
  if (!level.value || dateRange.value.length < 2) return;

  const params: IAgentDeviceStatusHistoryReportServiceGetInfoParams = {
    startDate: moment.utc(dateRange.value[0]).format("YYYY-MM-DD"),
    endDate: moment.utc(dateRange.value[1]).format("YYYY-MM-DD"),
    level: level.value.id,
  };

  setLoading(true);

  AgentDeviceStatusHistoryReportService.getInfo({
    params,
  })
    .then((response) => {
      totalTimePaused.value = response.data.totalTimePaused;
      totalTimeCall.value = response.data.totalTimeCall;
      agentsDevices.value = response.data.devices;
    }, toastServiceError)
    .finally(() => setLoading(false));
};
getReportData();

watch([level, dateRange], () => {
  getReportData();
});

const __returned__ = { dateRange, level, totalTimePaused, totalTimeCall, agentsDevices, setLoading, getDeviceIcon, getStatusColor, getQueueColorStatus, getReportData, get UiDatePicker() { return UiDatePicker }, get UiPageHeader() { return UiPageHeader }, get moment() { return moment }, get formatSecToTime() { return formatSecToTime } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})