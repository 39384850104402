import { defineComponent as _defineComponent } from 'vue'
import { PropType } from "vue";
import { formatDateAsString } from "@/utils/datetimeCommon";
import { CalendarIcon } from "@heroicons/vue/solid";
import { BookmarkIcon } from "@heroicons/vue/outline";
import { IConnectFlowFlow, Permission } from "@/definitions";
import { UiButton } from "@/components";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectFlowHistoryItem',
  props: {
  modelValue: {
    type: Object as PropType<IConnectFlowFlow>,
    required: true,
  },
  published: {
    type: Boolean,
    required: true,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props, get formatDateAsString() { return formatDateAsString }, get CalendarIcon() { return CalendarIcon }, get BookmarkIcon() { return BookmarkIcon }, get Permission() { return Permission }, get UiButton() { return UiButton }, get ConnectFlowIcon() { return ConnectFlowIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})