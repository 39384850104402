import { defineComponent as _defineComponent } from 'vue'
import { computed, PropType, reactive, Ref, watch } from "vue";
import { requiredIf } from "@/utils/validators";
import { UiTextInput } from "@/components";
import { UserService } from "@/services";
import { IExtensionFormState, IUserNumberFormState } from "@/definitions";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserFormExtension',
  props: {
  modelValue: {
    type: Object as PropType<IExtensionFormState>,
    required: true,
  },
  devices: {
    type: Object as PropType<IUserNumberFormState>,
    required: true,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const { t } = useI18n();

const props = __props;

const noDevice = computed(
  () => Object.values(props.devices).filter((v) => v).length === 0,
);

const formState = reactive<IExtensionFormState>(props.modelValue);

const isTaken = helpers.withAsync(
  async (value: number, siblings: IExtensionFormState) => {
    if (value === null || value.toString() === "") {
      return true;
    }

    const r = await UserService.checkExtensionNumber(siblings);
    if (r) {
      return true;
    } else {
      throw t("user.error.extensionUsed");
    }
  },
);

const rules = computed<ValidationArgs<IExtensionFormState>>(() => {
  const r: ValidationArgs<IExtensionFormState> = {
    id: {},
    levelId: {},
    label: {},
    number: {
      required: requiredIf(() => {
        return (
          formState.levelId !== null &&
          Object.values(props.devices).filter((v) => v).length > 0
        );
      }),
      isUnique: isTaken,
    },
  };

  return r;
});

const v$: Ref<Validation<ValidationArgs<IExtensionFormState>>> = useVuelidate(
  rules,
  formState,
);

const emit = __emit;
watch(
  formState,
  () => {
    emit("update:modelValue", formState);
  },
  {
    deep: true,
  },
);

const __returned__ = { t, props, noDevice, formState, isTaken, rules, v$, emit, get UiTextInput() { return UiTextInput } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})