import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 sm:p-6 lg:p-8" }
const _hoisted_2 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_3 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-4 gap-6" }
const _hoisted_5 = {
  for: "level",
  class: "block text-sm font-medium leading-6 text-gray-900 mb-2"
}
const _hoisted_6 = { class: "flex flex-col justify-end pb-2.5" }
const _hoisted_7 = { class: "flex justify-end px-4 sm:px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["UiPageHeader"], null, {
      info: _withCtx(() => [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("connectFlow." + $setup.props.action + ".title")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("connectFlow." + $setup.props.action + ".description")), 1 /* TEXT */)
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("form", {
      class: "space-y-6",
      autocomplete: "off",
      onSubmit: _withModifiers($setup.onSubmit, ["prevent"])
    }, [
      _createVNode($setup["UiPanel"], null, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("label", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.$t("connectFlow.labels.level")) + " ", 1 /* TEXT */),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-xs" }, "*", -1 /* HOISTED */))
              ]),
              _createVNode($setup["UiMultiselect"], {
                name: "level",
                label: "name",
                "value-prop": "id",
                modelValue: $setup.formState.levelId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.levelId) = $event)),
                primitive: true,
                options: $setup.levels,
                errors: $setup.v$.levelId.$errors,
                required: $setup.v$.levelId.required !== undefined,
                disabled: $setup.props.action == $setup.FormAction.EDIT
              }, null, 8 /* PROPS */, ["modelValue", "options", "errors", "required", "disabled"])
            ]),
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.v$.name.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.v$.name.$model) = $event)),
              name: "name",
              type: "text",
              label: _ctx.$t('connectFlow.labels.name'),
              errors: $setup.v$.name.$errors,
              required: $setup.v$.name.required !== undefined,
              onBlur: $setup.v$.name.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "required", "onBlur"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode($setup["UiCheckboxInput"], {
                modelValue: $setup.v$.isActive.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.v$.isActive.$model) = $event)),
                name: "isActive",
                label: _ctx.$t('connectFlow.labels.isActive')
              }, null, 8 /* PROPS */, ["modelValue", "label"])
            ])
          ])
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("div", _hoisted_7, [
        _createVNode($setup["UiButton"], {
          variant: "white",
          "text-variant": "gray-500",
          to: {
            name: 'ListConnectFlow',
          }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Cancel")), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }),
        (
            ($setup.props.action == $setup.FormAction.CREATE &&
              _ctx.$can($setup.Permission.CREATE_CONNECT_FLOW)) ||
            ($setup.props.action == $setup.FormAction.EDIT &&
              _ctx.$can($setup.Permission.EDIT_CONNECT_FLOW))
          )
          ? (_openBlock(), _createBlock($setup["UiButton"], {
              key: 0,
              type: "submit",
              variant: "primary",
              "text-variant": "white",
              themed: "",
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("core.actions.Save")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 32 /* NEED_HYDRATION */),
    _createVNode($setup["UiCheckDirtyBeforeRouteLeave"], {
      dirty: $setup.v$.$anyDirty
    }, null, 8 /* PROPS */, ["dirty"])
  ]))
}