import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "ProfilePage", -1 /* HOISTED */)),
    _createElementVNode("p", null, "id: " + _toDisplayString($setup.user.id), 1 /* TEXT */),
    _createElementVNode("p", null, "name: " + _toDisplayString($setup.user.name), 1 /* TEXT */),
    _createElementVNode("p", null, "email: " + _toDisplayString($setup.user.email), 1 /* TEXT */),
    _createElementVNode("p", null, "levels: " + _toDisplayString($setup.userLevels), 1 /* TEXT */),
    _createElementVNode("p", null, "roles: " + _toDisplayString($setup.userRoles), 1 /* TEXT */),
    _createElementVNode("p", null, "permissions: " + _toDisplayString($setup.userPermissions), 1 /* TEXT */)
  ]))
}