import { defineComponent as _defineComponent } from 'vue'
import { Ref, ref, computed, watch } from "vue";
import { UiButton, UiTextInput } from "@/components";
import { useVuelidate, Validation, ValidationArgs } from "@vuelidate/core";
import { minLength, required } from "@/utils/validators";
import { TrashIcon } from "@heroicons/vue/outline";

interface IExternalFormState {
  formState: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TransferToExternalNumberField',
  props: {
  modelValue: {
    type: String,
    required: true,
  },
  removable: {
    type: Boolean,
    default: false,
  },
},
  emits: ["update:modelValue", "remove"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const number: Ref<string> = ref<string>(props.modelValue);

const rules = computed<ValidationArgs<IExternalFormState>>(() => {
  const r: ValidationArgs<IExternalFormState> = {
    formState: {
      required,
      minLength: minLength(13),
    },
  };
  return r;
});
const v$: Ref<Validation<ValidationArgs<IExternalFormState>>> = useVuelidate(
  rules,
  {
    formState: number,
  },
);
const emit = __emit;

watch(number, () => {
  emit("update:modelValue", number.value);
});

const __returned__ = { props, number, rules, v$, emit, get UiButton() { return UiButton }, get UiTextInput() { return UiTextInput }, get TrashIcon() { return TrashIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})