import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import store from "@/store";
import {
  ArrowRightIcon,
  ChartBarIcon,
  UsersIcon,
  UserGroupIcon,
  LockClosedIcon,
  ReplyIcon,
  CurrencyDollarIcon,
} from "@heroicons/vue/solid";
import ConnectFlowIcon from "@/components/svg/ConnectFlowIcon";
import { Permission, IDashboardItem, AuthGetters, IUser } from "@/definitions";
import { UiPageHeader } from "@/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props, { expose: __expose }) {
  __expose();

const user = computed<IUser>(() => store.getters[AuthGetters.USER]);

const items: IDashboardItem[] = [
  {
    title: "dashboard.calls",
    description: "call.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_CALLS,
    routeName: "CallList",
    icon: ChartBarIcon,
  },
  {
    title: "report.call.title",
    description: "report.call.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_CALL_REPORT,
    routeName: "CallReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.billing.title",
    description: "report.billing.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_BILLING_REPORT,
    routeName: "BillingReportPage",
    icon: CurrencyDollarIcon,
  },
  {
    title: "report.contactCenterPerDay.title",
    description: "report.contactCenterPerDay.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_CONTACT_CENTER_PER_DAY_REPORT,
    routeName: "ContactCenterPerDayReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.contactCenterPerAgent.title",
    description: "report.contactCenterPerAgent.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_CONTACT_CENTER_PER_AGENT_REPORT,
    routeName: "ContactCenterPerAgentReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.agentProductivityPerDay.title",
    description: "report.agentProductivityPerDay.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_DAY_REPORT,
    routeName: "AgentProductivityPerDayReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.agentProductivityPerAgent.title",
    description: "report.agentProductivityPerAgent.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_AGENT_PRODUCTIVITY_PER_AGENT_REPORT,
    routeName: "AgentProductivityPerAgentReport",
    icon: ChartBarIcon,
  },
  // {
  //   title: "report.queueTime.title",
  //   description: "report.queueTime.description",
  //   callToAction: "core.actions.SeeReport",
  //   permission: Permission.VIEW_QUEUE_TIME_REPORT,
  //   routeName: "QueueTimeReport",
  //   icon: ChartBarIcon,
  // },
  {
    title: "report.realtime.title",
    description: "report.realtime.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_REALTIME_REPORT,
    routeName: "RealtimeReport",
    icon: ChartBarIcon,
  },
  {
    title: "report.agentExtension.title",
    description: "report.agentExtension.description",
    callToAction: "core.actions.SeeReport",
    permission: Permission.VIEW_AGENT_EXTENSION_REPORT,
    routeName: "RealtimeAgentExtensionReport",
    icon: ChartBarIcon,
  },
  {
    title: "dashboard.configuration.users",
    description: "user.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_USERS,
    routeName: "ListUser",
    icon: UsersIcon,
  },
  {
    title: "dashboard.configuration.userLabel",
    description: "userLabel.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_USER_LABELS,
    routeName: "ListUserLabel",
    icon: UserGroupIcon,
  },
  {
    title: "dashboard.configuration.roles",
    description: "role.description",
    callToAction: "core.actions.goPage",
    permission: Permission.ASSIGN_ROLE_TO_USER,
    routeName: "Permissions",
    icon: LockClosedIcon,
  },
  {
    title: "dashboard.configuration.connectFlow",
    description: "connectFlow.description",
    callToAction: "core.actions.goPage",
    permission: Permission.VIEW_CONNECT_FLOWS,
    routeName: "ListConnectFlow",
    icon: ConnectFlowIcon,
  },
];

const __returned__ = { user, items, get ArrowRightIcon() { return ArrowRightIcon }, get ReplyIcon() { return ReplyIcon }, get UiPageHeader() { return UiPageHeader } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})