import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import {
  AudioPlayerActions,
  AudioPlayerGetters,
  ICallTimelineItem,
} from "@/definitions";
import store from "@/store";
import { PlayIcon } from "@heroicons/vue/solid";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallPlayerBullets',
  props: {
  type: {
    type: String,
    required: true,
  },
  timeline: {
    type: Array as () => Array<ICallTimelineItem>,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();



const playerHasError = computed(
  () => store.getters[AudioPlayerGetters.PLAYER_ERROR] !== null,
);
const secondsDuration = computed(
  () => store.getters[AudioPlayerGetters.SECONDS_DURATION],
);

function playSnippet(start: number, end: number) {
  if (!playerHasError.value) {
    store.dispatch(AudioPlayerActions.PLAY_SNIPPET, [start, end]);
  }
}

function getPositionBullet(item: ICallTimelineItem) {
  return (item.startedAt / secondsDuration.value) * 100 + "%";
}

const insideColors = {
  words: {
    bullet: "bg-blue-500/50",
    play: "text-blue-500",
  },
  insights: {
    bullet: "bg-yellow-500/50",
    play: "text-yellow-500",
  },
  search: {
    bullet: "bg-orange-500/50",
    play: "text-orange-500",
  },
};

const __returned__ = { playerHasError, secondsDuration, playSnippet, getPositionBullet, insideColors, get PlayIcon() { return PlayIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})